import React, { useState } from "react";
import { Modal, Button, Radio, Form, Typography, Alert, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./DocumentClosingModal.module.scss";
import { catchError } from "../../services/DaoService";
import Toast from "../../helpers/Toast";
import { DocumentService } from "../../services/DocumentService";

const { Paragraph, Text } = Typography;

export default function DocumentClosingModal({ visible, onClose, document }) {
    const [closingStatus, setClosingStatus] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = React.useRef(null);
    const [uploadProgressPercent, setUploadProgressPercent] = useState(0);

    const handleStatusChange = (e) => {
        setClosingStatus(e.target.value);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleFinish = () => {
        if (!closingStatus) {
            Toast.error("Veuillez sélectionner un statut de clôture.");
            return;
        }

        const formData = new FormData();
        formData.append("status", closingStatus);
        if (closingStatus === "approved") {
            if (!file) {
                Toast.error("Veuillez joindre la lettre d'approbation.");
                return;
            }
            formData.append("approval_letter", file);
        }
        if (closingStatus === "accepted") {
            if (!file) {
                Toast.error("Veuillez joindre la lettre d'acceptation.");
                return;
            }
            formData.append("approval_letter", file);
        }
        if (closingStatus === "rejected") {
            if (!file) {
                Toast.error("Veuillez joindre la lettre de rejet.");
                return;
            }
            formData.append("rejection_letter", file);
        }

        setLoading(true);
        DocumentService.getInstance().closeForApproval(document.uid, formData, setUploadProgressPercent)
            .then((response) => {
                Toast.success(response.message);
                // Réinitialiser les éléments du formulaire
                setClosingStatus(null); 
                setFile(null);  
                setUploadProgressPercent(0);  
                onClose();
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const openFilePicker = () => {
        fileInputRef.current.click();
    }

    return (
        <Modal
            open={visible}
            title="Clôturer le Document"
            onCancel={onClose}
            footer={null}
            className={styles.modal}
        >
            <Form layout="vertical" onFinish={handleFinish}>
                {/* Instructions générales pour l'utilisateur */}
                <Alert
                    message="Instructions"
                    description="Veuillez sélectionner le statut de clôture approprié pour ce document. Si le document est approuvé, vous devrez joindre une lettre d'approbation avant de finaliser la clôture. 
                    Par ailleurs, si le document est accepté, merci de fournir également une lettre d'acceptation."
                    type="info"
                    showIcon
                    style={{ marginBottom: 16 }}
                />

                <Form.Item label="Statut de clôture">
                    <Radio.Group onChange={handleStatusChange}  value={closingStatus}>
                        <Radio value="approved">Clôturé Approuvé</Radio>
                        <Radio value="accepted">Clôturé Accepté</Radio>
                        <Radio value="rejected">Clôturé Rejeté</Radio>
                    </Radio.Group>
                </Form.Item>

                {closingStatus === "approved" && (
                    <>
                        <Paragraph type="secondary">
                            Vous avez sélectionné <Text strong>"Clôturé Approuvé"</Text>.
                            Veuillez joindre la lettre d'approbation au format PDF.
                        </Paragraph>
                        <Form.Item label="Lettre d'approbation">
                            <div className={styles.uploadContainer}>
                                <input
                                    type="file" ref={fileInputRef}
                                    id="approvalLetter"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    accept=".pdf"
                                />
                                <label htmlFor="approvalLetter" className={styles.uploadPlaceholder}>
                                    <Button icon={<UploadOutlined />} onClick={openFilePicker}>
                                        {file ? file.name : "Cliquez pour sélectionner la lettre d'approbation"}
                                    </Button>
                                </label>
                            </div>
                        </Form.Item>
                    </>
                )}
                {closingStatus === "accepted" && (
                    <>
                        <Paragraph type="secondary">
                            Vous avez sélectionné <Text strong>"Clôturé Accepté"</Text>.
                            Veuillez joindre la lettre d'acceptation au format PDF.
                        </Paragraph>
                        <Form.Item label="Lettre d'acceptation">
                            <div className={styles.uploadContainer}>
                                <input
                                    type="file" ref={fileInputRef}
                                    id="acceptanceLetter"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    accept=".pdf"
                                />
                                <label htmlFor="acceptanceLetter" className={styles.uploadPlaceholder}>
                                    <Button icon={<UploadOutlined />} onClick={openFilePicker}>
                                        {file ? file.name : "Cliquez pour sélectionner la lettre d'acceptation"}
                                    </Button>
                                </label>
                            </div>
                        </Form.Item>
                    </>
                )}

                {closingStatus === "rejected" && (
                    <>
                        <Paragraph type="secondary">
                            Vous avez sélectionné <Text strong>"Clôturé Rejeté"</Text>.
                            Veuillez joindre la lettre de rejet au format PDF.
                        </Paragraph>
                        <Form.Item label="Lettre de rejet">
                            <div className={styles.uploadContainer}>
                                <input
                                    type="file" ref={fileInputRef}
                                    id="rejectionLetter"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    accept=".pdf"
                                />
                                <label htmlFor="rejectionLetter" className={styles.uploadPlaceholder}>
                                    <Button icon={<UploadOutlined />} onClick={openFilePicker}>
                                        {file ? file.name : "Cliquez pour sélectionner la lettre de rejet"}
                                    </Button>
                                </label>
                            </div>
                        </Form.Item>
                    </>
                )}

                {uploadProgressPercent > 0 && (
                    <Form.Item>
                        <Progress percent={uploadProgressPercent} />
                    </Form.Item>
                )}

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Terminer
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}
