import React, { useState, useEffect } from 'react';
import { Table, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';
import styles from './MyDocuments.module.scss';
import { useUserContext } from '../../providers/UserProvider';

const { Title } = Typography;

const MyDocuments = () => {
  const { currentUser } = useUserContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    DashboardService.getInstance().getMyDocuments()
      .then(response => {
        if (response.error === 0) {
          setData(response.data);
        } else {
          catchError(response.message);
        }
      })
      .catch(err => catchError(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'N°',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => index + 1,
    },
    {
      title: "Numéro de Document",
      dataIndex: "document_number",
      key: "document_number",
      render: (text, record) => <Link to={`/document/${record.uid}`}>{text}</Link>,
    },
    {
      title: 'Nom du Document',
      dataIndex: 'nom',
      key: 'nom',
      render: (text, record) => <Link to={`/document/${record.uid}`}>{text}</Link>,
    },
    {
      title: 'Date de Création',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: currentUser.is_contact ? 'Statut Client' : 'Statut Document',
      dataIndex: currentUser.is_contact ? 'statut_client' : 'statut_document',
      key: currentUser.is_contact ? 'statut_client' : 'statut_document',
      render: (text) => text.nom,
    },
    {
      title: "Nombre de corrections demandées",
      dataIndex: "demande_correction_documents_count",
      key: "demande_correction_documents_count",
      render: (count) => (<><span className="text-danger">{count} correction(s) demandée(s)</span></>),
    },
    {
      title: "En attente de correction",
      dataIndex: "pending_correction",
      key: "pending_correction",
      render: (isPending) => isPending ? <span className="text-warning">En attente de correction</span> : <span className="text-success">Aucune correction en attente</span>,
    }
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className={styles.latestDocuments}>
      <Title level={4} className={styles.title}>Mes Documents</Title>
      <Table
        scroll={{x: true}}
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ pageSize: 5 }}
        bordered
        footer={() => <Link to="/document">Voir plus</Link>}
      />
    </div>
  );
};

export default MyDocuments;
