import React, { useState, useCallback } from "react";
import { List, Avatar, Tooltip, Popconfirm } from "antd";
import {
  PhoneOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  BellOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import styles from "./ActivityItem.module.scss";
import ActiviteService from "../../services/ActiviteService";
import { catchError } from "../../services/DaoService";
import Toast from "../../helpers/Toast";
import { InlineEditableInput } from "../utils/InlineEditableInput";

const ActivityItem = ({ item, onDelete, onToggleDone, onUpdate, users }) => {
  const [activity, setActivity] = useState(item);
  const [removing, setRemoving] = useState(false);
  const [isDone, setIsDone] = useState(item.done);
  const getIcon = (type) => {
    switch (type) {
      case "Appel":
        return <PhoneOutlined />;
      case "Réunion":
        return <CalendarOutlined />;
      case "Tache":
        return <CheckCircleOutlined />;
      case "Rappel":
        return <BellOutlined />;
      default:
        return <CheckCircleOutlined />;
    }
  };

  const remove = (uid) => {
    setRemoving(true);
    ActiviteService.getInstance()
      .remove(uid)
      .then((response) => {
        onDelete(activity);
        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setRemoving(false);
      });
  };

  const markAsDone = (uid) => {
    setRemoving(true);
    setIsDone(!isDone);
    ActiviteService.getInstance()
      .switchDone(uid)
      .then((response) => {
        onToggleDone(activity);
        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setRemoving(false);
      });
  };

  const handeFieldChange = useCallback(
    (value, fieldName, record) => {
      const updatedActivity = { ...record, [fieldName]: value };
      onUpdate(updatedActivity);
      ActiviteService.getInstance()
        .update(updatedActivity.uid, updatedActivity)
        .then((response) => {
          Toast.success(response.message);
          setActivity(response.data);
        })
        .catch((error) => {
          catchError(error);
        });
    },
    [onUpdate]
  );

  return (
    <>
      <List.Item
        className={`${styles.activityItem} ${isDone ? styles.done : ""}`}
        actions={[
          <Tooltip
            title={
              activity.done
                ? "Marquer comme non terminée"
                : "Marquer comme terminée"
            }
          >
            <Popconfirm
              title={
                activity.done
                  ? "Voulez-vous vraiment rouvrir cette activité ?"
                  : "Cette activité est-elle vraiment terminée ?"
              }
              onConfirm={() => markAsDone(activity.uid)}
              okText="Oui"
              cancelText="Non"
            >
              {activity.done ? <UndoOutlined /> : <CheckCircleOutlined />}
            </Popconfirm>
          </Tooltip>,
          <Tooltip title="Supprimer">
            <Popconfirm
              title="Voulez-vous vraiment supprimer cette activité ?"
              onConfirm={() => remove(activity.uid)}
              okText="Oui"
              cancelText="Non"
            >
              <DeleteOutlined spin={removing} style={{ color: "red" }} />
            </Popconfirm>
          </Tooltip>,
        ]}
      >
        <List.Item.Meta
          avatar={<Avatar icon={getIcon(activity.type)} />}
          title={
            <InlineEditableInput
              canEdit={true}
              value={activity.nom}
              record={activity}
              onChange={handeFieldChange}
              fieldName="nom"
            >
              {isDone ? <del>{activity.nom}</del> : activity.nom}
            </InlineEditableInput>
          }
          description={
            <>
              <span className={styles.activityTime}>
                {activity.start_at && (
                  <InlineEditableInput
                    canEdit={true}
                    value={activity.start_at}
                    record={activity}
                    onChange={handeFieldChange}
                    fieldName="start_at"
                    type="datetime-local"
                  >
                    {new Date(activity.start_at).toLocaleString()}
                  </InlineEditableInput>
                )}
                <InlineEditableInput
                  canEdit={true}
                  value={activity.end_at}
                  record={activity}
                  onChange={handeFieldChange}
                  fieldName="end_at"
                  type="datetime-local"
                >
                  {new Date(activity.end_at).toLocaleString()}
                </InlineEditableInput>
              </span>
              <span className={styles.activityWho}>
                <InlineEditableInput
                  canEdit={true}
                  value={activity.whoid}
                  record={activity}
                  onChange={handeFieldChange}
                  fieldName="whoid"
                  options={users.map((user) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  type="select"
                >
                  Affecté à <strong>{activity.who.name}</strong>
                </InlineEditableInput>
              </span>
            </>
          }
        />
      </List.Item>
    </>
  );
};
export default ActivityItem;
