import { DaoService } from "./DaoService";

export class DocumentService extends DaoService {
    static getInstance() {
        return super.getInstance('document');
    }

    search(data) {
        return DocumentService.post(`${this.prefix}/search`, data);
    }

    updateStatus(uid, data) {
        return DocumentService.post(`${this.prefix}/${uid}/update-status`, data);
    }

    submitForApproval(uid) {
        return DocumentService.post(`${this.prefix}/${uid}/submit-for-approval`);
    }

    takeOwnership(uid) {
        return DocumentService.post(`${this.prefix}/${uid}/take-ownership`);
    }

    findByOrganisation(uid) {
        return DocumentService.get(`${this.prefix}/${uid}/organisation`);
    }

    closeForApproval(uid, data, setUploadProgressPercent) {
        return DocumentService.upload(`${this.prefix}/${uid}/close-for-approval`, data, setUploadProgressPercent);
    }
}