import React, { useState, useCallback } from "react";
import { Button, List, Spin, Popconfirm, Col, Row } from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  FileOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import styles from "./PieceJointeList.module.scss";
import Toast from "../../helpers/Toast";
import PieceJointeService from "../../services/PieceJointeService";
import { catchError } from "../../services/DaoService";
import Moment from "react-moment";
import { InlineEditableInput } from "../utils/InlineEditableInput";
import PieceJointeSingleForm from "./PieceJointeSingleForm"; // Import du nouveau composant
import PieceJointeMultipleForm from "./PieceJointeMultipleForm";

const PieceJointeList = ({
  parentType,
  parentId,
  canAdd = false,
  candRemove = false,
  canSee = false,
}) => {
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploadSingleModalVisible, setIsUploadSingleModalVisible] =
    useState(false);
  const [isUploadMultipleModalVisible, setIsUploadMultipleModalVisible] =
    useState(false);

  const init = useCallback(() => {
    if (!canSee) return;
    const fetchAttachments = async () => {
      setLoading(true);
      try {
        const data = await PieceJointeService.getInstance().findByParent(
          parentType,
          parentId
        );
        setAttachments(data.data);
      } catch (error) {
        Toast.error("Erreur lors de la récupération des pièces jointes.");
      } finally {
        setLoading(false);
      }
    };

    fetchAttachments();
  }, [parentType, parentId, canSee]);

  React.useEffect(() => {
    init();
  }, [init]);

  const handleDelete = async (uid) => {
       PieceJointeService.getInstance()
        .remove(uid)
        .then((response) => {
          console.log(response);
          setAttachments(attachments.filter((item) => item.uid !== uid));
          Toast.success(response?.message);
        }).catch((err) =>{
            catchError(err)
        } )
  };

  const handleFieldChange = (value, fieldName, record) => {
    const updatedRecord = { ...record, [fieldName]: value };
    const updatedAttachments = attachments.map((item) =>
      item.uid === record.uid ? updatedRecord : item
    );
    setAttachments(updatedAttachments);
    PieceJointeService.getInstance()
      .update(updatedRecord.uid, updatedRecord)
      .then((response) => {
        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
        Toast.error("Erreur lors de la mise à jour de la pièce jointe.");
      });
  };

  return (
    <div className={styles.pieceJointeList}>
      <PieceJointeSingleForm
        visible={isUploadSingleModalVisible}
        onClose={() => setIsUploadSingleModalVisible(false)}
        parentType={parentType}
        parentId={parentId}
        onUploadFinish={init} // Appel de init() pour rafraîchir la liste
      />
      <PieceJointeMultipleForm
        visible={isUploadMultipleModalVisible}
        onClose={() => setIsUploadMultipleModalVisible(false)}
        parentType={parentType}
        parentId={parentId}
        onUploadFinish={init} // Appel de init() pour rafraîchir la liste
      />
      <Spin spinning={loading}>
        <List
          header={
            <>
            <Row gutter={[8, 8]} wrap justify="start">
              {canAdd ? (
                <>
                  <Col xs={24} sm={12} md={8}>
                    <Button
                      icon={<UploadOutlined />}
                      onClick={() => setIsUploadSingleModalVisible(true)}
                      block className={styles.responsiveButton}
                    >
                      Ajouter une pièce
                    </Button>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Button className={styles.responsiveButton}
                      icon={<UploadOutlined />}
                      onClick={() => setIsUploadMultipleModalVisible(true)}
                      block
                    >
                      Ajouter plusieurs
                    </Button>
                  </Col>
                </>
              ) : null}
              <Col xs={24} sm={12} md={8}>
                <Button
                  loading={loading}
                  onClick={init}
                  type="default"
                  icon={<ReloadOutlined />}
                  block className={styles.responsiveButton}
                >
                  Rafraîchir
                </Button>
              </Col>
            </Row>
          </>
          }
          itemLayout="horizontal"
          dataSource={attachments}
          renderItem={(item) => (
            <List.Item
              className={styles.listItem}
              actions={[
                candRemove ? (
                  <Popconfirm
                    title="Supprimer"
                    okType="danger"
                    onConfirm={() => handleDelete(item.uid)}
                  >
                    <Button
                      type="text"
                      icon={<DeleteOutlined style={{ color: "red" }} />}
                    />
                  </Popconfirm>
                ) : null,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <FileOutlined style={{ fontSize: "24px", color: "#08c" }} />
                }
                title={
                  <InlineEditableInput
                    canEdit={canAdd && item.editable}
                    record={item}
                    value={item.nom}
                    fieldName="nom"
                    onChange={handleFieldChange}
                  >
                    <a href={item.full_path} target="_blank" rel="noreferrer">
                      {item.nom}
                    </a>
                  </InlineEditableInput>
                }
                description={
                  <>
                    <InlineEditableInput
                      canEdit={canAdd && item.editable}
                      type="textarea"
                      record={item}
                      value={item.description}
                      fieldName="description"
                      onChange={handleFieldChange}
                    >
                      <div>{item.description ?? "Pas de description"}</div>
                    </InlineEditableInput>
                    Ajouté {<Moment fromNow>{item.created_at}</Moment>}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Spin>
    </div>
  );
};

export default PieceJointeList;
