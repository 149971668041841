import React, { useCallback } from "react";
import { useUserContext } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import OrganisationService from "../../services/OrganisationService";
import { catchError } from "../../services/DaoService";
import { Button, Col, Divider, Input, Popconfirm, Row, Select, Space, Spin, Tag } from "antd";
import { BuildOutlined, DeleteFilled, EditFilled, EyeOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import OrganisationNew from "./OrganisationNew";
import OrganisationEdit from "./OrganisationEdit";
import InfiniteTable from "../utils/InfiniteTable";
import UnauthorizedMessage from "../utils/UnauthorizedMessage";
import PageTitle from "../utils/PageTitle";


export default function OrganisationList({ paginationData, setPaginationData, loading, setLoading, parentId }) {
    const [organisations, setOrganisations] = React.useState([]);
    const [selectedUid, setSelectedUid] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const [editModalVisible, setEditModalVisible] = React.useState(false);

    const [hasMore, setHasMore] = React.useState(true);
    const { check } = useUserContext();

    const [searchText, setSearchText] = React.useState(null);
    const [statut, setStatut] = React.useState('tout');



    const navigate = useNavigate();

    const setItemToEdit = (record) => {
        setSelectedUid(record.uid);
        setEditModalVisible(true);
    }
    const setItemToView = (record) => {
        setSelectedUid(record.uid);
        navigate(`/parametrage/exploitant/${record.uid}`);
    }

    const handleNewOrganisation = (organisation) => {
        setOrganisations([...organisations, organisation]);
    }

    const handleOrganisationUpdate = (organisation) => {
        setOrganisations(organisations.map(o => o.uid === organisation.uid ? organisation : o));
        setEditModalVisible(false);
    }

    const init = useCallback(() => {
        setLoading(true);
        OrganisationService.getInstance()
            .search({ searchText, statut, parent_id: parentId })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setOrganisations(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, statut, setLoading, setPaginationData, parentId]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (paginationData) {
            // merge the new data with the old data without duplicates (if any)
            setOrganisations(organisations => [...new Map([...organisations, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const removeOrganisation = (organisation) => {
        setIsDeleting(true);
        OrganisationService.getInstance()
            .remove(organisation.uid)
            .then(() => {
                setOrganisations(organisations.filter(o => o.id !== organisation.id));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            width: 300,
            // retour à la ligne si le texte est trop long
            render: (text) => <div style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                maxWidth: 300
            }}>{text}</div>,
        },
        {
            title: "Sigle",
            dataIndex: 'sigle',
            key: 'sigle',
            sorter: (a, b) => a.sigle?.localeCompare(b.sigle),
            width: 100,
            ellipsis: true,
            render: text => text ? text : <span className="badge bg-secondary">Non renseigné</span>
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 200,
            render: (text) => (
                <div style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    maxWidth: 300
                }}>
                    {text ? text : <span className="badge bg-secondary">Non renseigné</span>}
                </div>
            ),
            ellipsis: true
        },
        {
            title: 'Statut',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (enabled) => (
                <span className={`badge ${enabled ? 'bg-success' : 'bg-danger'}`}>{enabled ? 'Actif' : 'Inactif'}</span>
            ),
            width: 80
        },
        {
            title: "Etat",
            dataIndex: 'pending',
            key: 'pending',
            render: (pending, record) => (
                <Tag color={pending ? 'orange' : record.accepted ? 'green' : 'red'}>{pending ? "En attente d'approbation" : record.accepted ? 'Approuvé par Anacim': 'Rejeté par anacim'}</Tag>
            ),
            width: 120,
            sorter: (a, b) => a.pending - b.pending
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (record) => (
                <Space>
                    {check('VIEW-ORGANIZATION') ? <Button onClick={() => setItemToView(record)} icon={<EyeOutlined />} className="btn btn-primary"></Button> : null}
                    {check('EDIT-ORGANIZATION') ? <Button disabled={record.pending || !record.accepted} onClick={() => setItemToEdit(record)} icon={<EditFilled />} className="btn btn-warning"></Button> : null}
                    {check('DELETE-ORGANIZATION') ?
                        <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer cet exploitant?" onConfirm={() => removeOrganisation(record)} okText="Confirmer" cancelText="Annuler">
                            <Button disabled={record.pending} loading={isDeleting} icon={<DeleteFilled />} className="btn btn-danger"></Button>
                        </Popconfirm>
                        : null}
                </Space>
            )
        }
    ];

    const labelStyle = {
        marginRight: '10px', // espace entre le label et le select
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    const loadMore = () => {
        if (hasMore && !loading) {
            setLoading(true);
            OrganisationService.post(paginationData.next_page_url, { searchText, statut })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }


    return (
        <>
            {check('VIEW-ORGANIZATIONS') ? (<>
                <OrganisationNew parent_id={parentId} onCreate={handleNewOrganisation} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} />
                <OrganisationEdit onUpdate={handleOrganisationUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
                <PageTitle title="Liste des exploitants" icon={<BuildOutlined />} subTitle="Accéder et configurer la liste des exploitants...">
                    {
                        check('ADD-ORGANIZATION') ? <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Nouvel Exploitant</Button> : null
                    }
                    <Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>
                </PageTitle>
                <Spin spinning={loading}>
                    <InfiniteTable title={
                        <Row gutter={[24, 16]}>
                            <Col span={24}>
                                <Row gutter={[16, 16]} justify={'center'}>
                                    <Col span={24}>
                                        <Divider>Filtres rapides</Divider>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                        <Input.Search value={searchText} onInput={
                                            (e) => {
                                                setSearchText(e.target.value);
                                            }} placeholder="Rechercher un exploitant" />
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                        <div style={containerStyle}>
                                            <div style={labelStyle}>Statut:</div>
                                            <Select value={statut} onChange={
                                                (value) => {
                                                    setStatut(value);
                                                }
                                            } placeholder="Filtrer par statut" style={{ width: '100%' }}>
                                                <Select.Option value="tout">Tous</Select.Option>
                                                <Select.Option value="actif">Activé</Select.Option>
                                                <Select.Option value="inactif">Désactivé</Select.Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                        dataSource={organisations} loading={loading} columns={columns} scroll={{ x: 1300, y: 500 }}
                        paginationData={paginationData} loadMore={loadMore}
                    />
                </Spin>
            </>) : <UnauthorizedMessage />}
        </>
    )
}