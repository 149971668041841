import React from "react";
import { catchError } from "../../services/DaoService";
import { useUserContext } from "../../providers/UserProvider";
import { Table, Button, Card, Space, Spin, Popconfirm } from 'antd';
import { DeleteOutlined, LoadingOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import styles from './QueueMemberList.module.scss';
import QueueMemberService from "../../services/QueueMemberService";
import Moment from "react-moment";
import QueueMemberNew from "./QueueMemberNew";

export default function QueueMemberList({ queueUid }) {
    const [loading, setLoading] = React.useState(true);
    const [queueMembers, setQueueMembers] = React.useState([]);
    const [queueMember, setQueueMember] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [newModalVisible, setNewModalVisible] = React.useState(false);

    const { check } = useUserContext();

    const init = React.useCallback(() => {
        if (!queueUid) {
            return;
        }
        setLoading(true);
        QueueMemberService.getInstance()
            .findByQueue(queueUid)
            .then((response) => {
                setPaginationData(response.data);
                setQueueMembers(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [queueUid]);

    React.useEffect(() => {
        if (!check('VIEW-QUEUE-USERS')) {
            return;
        }
        init();
    }, [init, check, queueMember]);

    const loadMore = () => {
        if (!paginationData.next_page_url) {
            return;
        }
        setLoading(true);
        QueueMemberService
            .get(paginationData.next_page_url)
            .then((response) => {
                setPaginationData(response.data);
                setQueueMembers([...queueMembers, ...response.data.data]);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleDelete = (queueMember) => {
        setIsDeleting(true);
        QueueMemberService.getInstance()
            .remove(queueMember.id)
            .then(() => {
                setQueueMembers(queueMembers.filter(qm => qm.id !== queueMember.id));
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    const columns = [
        {
            title: 'Nom',
            dataIndex: ['user', 'name'],
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: ['user', 'email'],
            key: 'email',
        },
        {
            title: 'Date de création',
            dataIndex: ['user', 'created_at'],
            key: 'created_at',
            render: (text) => <Moment format="DD/MM/YYYY">{text}</Moment>
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space>
                    {check('REMOVE-USER-FROM-QUEUE') ? <Popconfirm okType="danger" okText="Supprimer" cancelText="Annuler" placement="topRight"
                        title="Voulez-vous vraiment supprimer ce membre de la file d'attente ?" onConfirm={() => handleDelete(record)}>
                        <Button loading={isDeleting}
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm> : null}
                </Space>
            ),
        },
    ];
    const handleAssignUserGroup = (queueMember) => {
        setQueueMember(queueMember);
        setNewModalVisible(false);
    }

    return (
        <div>
            <QueueMemberNew onSave={handleAssignUserGroup} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} uid={queueUid} />
            <Card className={styles.queueMemberListCard}
                extra={
                    <Space>
                        {
                            check('ADD-USER-TO-QUEUE') ?
                                <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Ajouter membres</Button>
                                : null
                        }
                        <Button onClick={init} icon={<ReloadOutlined />} loading={loading} type="default">Actualiser</Button>
                    </Space>
                }
            >
                <Spin spinning={loading}>
                    {check('VIEW-QUEUE-USERS') ? (
                        <>
                            <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                                <Table
                                    columns={columns}
                                    dataSource={queueMembers}
                                    rowKey="uid"
                                    pagination={false}
                                />
                            </Spin>
                            {paginationData?.next_page_url && (
                                <div className={styles.loadMore}>
                                    <Button onClick={loadMore} disabled={!paginationData?.next_page_url}>
                                        Charger plus
                                    </Button>
                                </div>
                            )}
                        </>
                    ) : null}
                </Spin>
            </Card>
        </div>
    );
}
