/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Route, Routes } from "react-router-dom";
import { ConfigProvider, Spin } from 'antd';

// import "antd/dist/antd.css";     <ActivityCard/>
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/responsive.css";
import { useUserContext } from "./providers/UserProvider";
import React from "react";
import GroupeListPage from "./pages/groupe/GroupeListPage";
import HomePage from "./pages/HomePage";
import OrganisationListPage from "./pages/organisation/OrganisationListPage";
import OrganisationDetailsPage from "./pages/organisation/OrganisationDetailsPage";
import LoginPage from "./pages/LoginPage";
import UserProfilePage from "./pages/user/UserProfilePage";
import UserListPage from "./pages/user/UserListPage";
import UserDetailsPage from "./pages/user/UserDetailsPage";
import Moment from 'react-moment';
import 'moment/locale/fr';
import moment from 'moment-timezone';
import frFR from 'antd/locale/fr_FR';
import ResetPasswordViaTokenPage from "./pages/user/ResetPasswordViaTokenPage";
import ForgotPasswordPage from "./pages/user/ForgotPasswordPage";
import GroupeDetailsPage from "./pages/groupe/GroupeDetailsPage";
import { usePusher } from "./providers/PusherProvider";
import NotificationListPage from "./pages/notification/NotificationListPage";
import { useNotificationContext } from "./providers/NotificationProvider";
import DocumentListPage from "./pages/document/DocumentListPage";
import ContactDetailsPage from "./pages/contact/ContactDetailsPage";
import DocumentDetailsPage from "./pages/document/DocumentDetailsPage";
import ContactListPage from "./pages/contact/ContactListPage";
import Toast from "./helpers/Toast.js";
import DomaineConfigPage from "./pages/domaine/DomaineConfigPage.js";
import DomaineTypesConfigPage from "./pages/domaine/DomaineTypesConfigPage.js";
import ChecklistModeleConfigPage from "./pages/checklistmodele/ChecklistModeleConfigPage.js";
import DocumentEvaluationPage from "./pages/documentEvaluation/DocumentEvaluationPage.js";
import BecomePartnerPage from "./pages/BecomePartnerPage.js";
import QueueListPage from "./pages/queue/QueueListPage.js";
import QueueDetailsPage from "./pages/queue/QueueDetailsPage.js";
import 'react-phone-number-input/style.css'
import DocumentEvaluationListPage from "./pages/documentEvaluation/DocumentEvaluationListPage.js";
import ChecklistModeleListPage from "./pages/checklistmodele/ChecklistModeleListPage.js";


// Sets the moment instance to use.
Moment.globalMoment = moment;
// Set the locale for every react-moment instance to French.
Moment.globalLocale = 'fr';
// Set the output format for every react-moment instance.
//Moment.globalFormat = 'D MMM YYYY';
// Set time zone for every react-moment instance : Africa/Dakar
Moment.globalTimezone = 'Africa/Dakar';
// Set the output timezone for local for every instance.
Moment.globalLocal = true;
// Use a <span> tag for every react-moment instance.
Moment.globalElement = 'span';
// set antd locale to fr

function App() {

  const { getCurrentUser, currentUser, loadingCurrentUser } = useUserContext();
  const { loadUnreadNotificationsCount, loadUnreadNotifications } = useNotificationContext();

  const { pusher, userPrivateChannelName } = usePusher();

  React.useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  React.useEffect(() => {
    if (currentUser) {
      loadUnreadNotificationsCount();
      loadUnreadNotifications();
    }
  }, [currentUser, loadUnreadNotificationsCount, loadUnreadNotifications]);

  const handleNotificationEvent = React.useCallback((data) => {
    Toast.success(data.title);
    loadUnreadNotificationsCount();
    loadUnreadNotifications();
  }, [loadUnreadNotificationsCount, loadUnreadNotifications]);

  React.useEffect(() => {
    if (currentUser && pusher) {
      const channelName = userPrivateChannelName;
      // const channelName = `test-channel`;
      console.log(`Subscribing to channel: ${channelName}`);
      const channel = pusher.subscribe(channelName);

      const events = [
        'document-approval-request',
        'new-correction-document-request',
        'new-demande-correction-document-transmission',
        'correction-document-transmitted',
        'new-exploitant-registration',
        'task-assigned',
        'document-responsabilite-transfert',
        'new-evaluation-member-add',
        'new-document-member',
        'document-evaluation-validation',
        'members-document-closed-notification',
        'exploitant-document-closed-notification',
        'document-contact-changed'
      ];

      events.forEach((event) => {
        channel.bind(event, handleNotificationEvent);
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [pusher, currentUser, userPrivateChannelName, handleNotificationEvent]);

  if (loadingCurrentUser) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spin size="large" />
      </div>
    );
  }


  return (
    <ConfigProvider locale={frFR}
      theme={{
        // algorithm: theme.darkAlgorithm,
        token: {
          // Seed Token
          colorPrimary: '#0C51A0',
          borderRadius: 5,

          // Alias Token
          // colorBgContainer: '#f2f1e6',
          // person
        },
        components: {
          Table: {
            headerBg: '#0C51A0',
            headerColor: '#fff',
            borderColor: '#fadb14',
            footerBg: '#ffffb8',
            headerSortHoverBg: '#fadb14',
            headerSortActiveBg: '#fadb14',
            cellFontSize: 14
          },
          Card: {
            // headerBg: '#1d7c55',
          },
          Menu: {
            iconSize: 15,
            // itemActiveBg: '#D9363E',
            // itemActiveColor: 'red',
            // itemActiveBorderWidth: 0,
            // itemActiveBorderColor: 'red',
            // itemActiveBorderRadius: 0,
            // itemHoverBorderWidth: 0,
            // itemHoverBorderColor: 'red',
            // itemHoverBorderRadius: 0,
            // itemGroupTitleColor: 'red',
            // itemGroupTitleFontSize: 14,
          }
        }

      }}
    >
      <div className="App">

        <Routes>
          <Route path="/login" exact element={<LoginPage />} />
          <Route exact path="/dashboard" element={<HomePage />} />
          <Route exact path="/parametrage/groupe" element={<GroupeListPage />} />
          <Route exact path="/parametrage/groupe/:uid" element={<GroupeDetailsPage />} />
          <Route exact path="/parametrage/exploitant" element={<OrganisationListPage />} />
          <Route exact path="/parametrage/exploitant/:uid" element={<OrganisationDetailsPage />} />
          <Route exact path="/parametrage/domains-config" element={<DomaineConfigPage />} />
          <Route exact path="/parametrage/domains-types-dossier" element={<DomaineTypesConfigPage />} />
          <Route exact path="/parametrage/user" element={<UserListPage />} />
          <Route exact path="/parametrage/user/:uid" element={<UserDetailsPage />} />
          <Route exact path="/parametrage/checklistmodel-config" element={<ChecklistModeleConfigPage />} />
          <Route exact path="/parametrage/queue-config" element={<QueueListPage />} />
          <Route exact path="/parametrage/queue-config/:uid" element={<QueueDetailsPage />} />
          <Route exact path="/parametrage/checklist-modele" element={<ChecklistModeleListPage />} />
          <Route exact path="/document" element={<DocumentListPage />} />
          <Route exact path="/document/:uid" element={<DocumentDetailsPage />} />
          <Route exact path="/contact" element={<ContactListPage />} />
          <Route exact path="/contact/:id" element={<ContactDetailsPage />} />
          <Route exact path="/document-evaluation/:uid" element={<DocumentEvaluationPage />} />
          <Route exact path="/gestion-document/documentevaluation" element={<DocumentEvaluationListPage />} />
          <Route exact path="/profile" element={<UserProfilePage />} />
          <Route exact path="/verify-email/:token" element={<ResetPasswordViaTokenPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/notifications" element={<NotificationListPage />} />
          <Route path="/become-partner" element={<BecomePartnerPage />} />
          {
            currentUser ?
              <Route path="*" element={<HomePage />} /> :
              <Route path="*" element={<LoginPage />} />
          }
        </Routes>

      </div>
    </ConfigProvider>
  );
}

export default App;
