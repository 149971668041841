import { DaoService } from "./DaoService";

export default class DashboardService extends DaoService {
  static getInstance() {
    return super.getInstance("dashboard");
  }

  async countExploitants() {
    return DashboardService.get(`${this.prefix}/count-exploitants`);
  }

  async countUsers() {
    return DashboardService.get(`${this.prefix}/count-users`);
  }

  async countDocumentsByStatutClient() {
    return DashboardService.get(`${this.prefix}/count-documents-by-statut-client`);
  }

  async countDocumentsByStatutDocumentGlobal() {
    return DashboardService.get(`${this.prefix}/count-documents-by-statut-document-global`);
  }

  async countUsersAndContactsByExploitant() {
    return DashboardService.get(`${this.prefix}/count-users-and-contacts-by-exploitant`);
  }


  /**
   * Exploitant Dashboard
   */

  async countUsersAndContactsAndDocumentsForExploitant() {
    return DashboardService.get(`${this.prefix}/count-users-and-contacts-and-documents-for-exploitant`);
  }

  async countDocumentsByStatutClientForExploitant() {
    return DashboardService.get(`${this.prefix}/count-documents-by-statut-client-for-exploitant`);
  }

  async latestDocumentsForExploitant() {
    return DashboardService.get(`${this.prefix}/latest-documents-for-exploitant`);
  }

  async getMyDocuments() {
    return DashboardService.get(`${this.prefix}/my-document`);
  }

}
