import React, { useState } from "react";
import { Card, Typography, Button, Popconfirm } from "antd";
import { CaretRightOutlined, UnlockOutlined } from "@ant-design/icons";
import styles from "./EvaluationFormGroup.module.scss"; // SCSS Module
import EvaluationFormItem from "./EvaluationFormItem";
import DocumentEvaluationGroupService from "../../services/DocumentEvaluationGroupService";
import { catchError } from "../../services/DaoService";
import Toast from "../../helpers/Toast";
import { usePusher } from "../../providers/PusherProvider";

const { Title, Text } = Typography;

function EvaluationFormGroup({ group, documentEvaluation, isAdmin, currentUserId }) {
  const [documentEvaluationGroup, setDocumentEvaluationGroup] = useState(group.documentEvaluationGroup);
  const [loading, setLoading] = useState(false);
  const { pusher } = usePusher();

  React.useEffect(() => {
    if (!pusher) {
      return;
    }
    pusher.subscribe(`documentEvaluationGroup.${group.documentEvaluationGroup.uid}`).bind("updated", function (data) {
      setDocumentEvaluationGroup((documentEvaluationGroup) => ({ ...documentEvaluationGroup, ...data.updatedData }));
    });
    return function () {
      pusher.unsubscribe(`documentEvaluationGroup.${group.documentEvaluationGroup.uid}`);
    };
  }, [pusher, group?.documentEvaluationGroup?.uid]);

  const handleTakeOwnership = () => {
    setLoading(true);
    DocumentEvaluationGroupService.getInstance()
      .takeOwnership(documentEvaluationGroup.uid)
      .then((response) => {
        setDocumentEvaluationGroup(response.data);
        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReleaseOwnership = () => {
    setLoading(true);
    DocumentEvaluationGroupService.getInstance()
      .releaseOwnership(documentEvaluationGroup.uid)
      .then((response) => {
        setDocumentEvaluationGroup(response.data);
        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card bordered={true} className={styles.evaluationGroupCard}>
      <div className={styles.header}>
        <Title level={4} className={styles.title}>
          {documentEvaluationGroup?.nom}
        </Title>
        {documentEvaluationGroup?.evaluateur_id ? (
          <Text type="secondary">Évaluateur: {documentEvaluationGroup.evaluateur.name}</Text>
        ) : (

          <Popconfirm
            onConfirm={handleTakeOwnership}
            okText="Confirmer"
            cancelText="Annuler"
            okType="danger"
            description="Vous serez le seul habilité à évaluer cette section si vous confirmez."
            title="Êtes-vous sûr de vouloir prendre en charge cette partie ?"
          >
            <Button loading={loading} type="link" icon={<CaretRightOutlined />} className={styles.takeActionButton}>
              Prendre en charge cette partie
            </Button>
          </Popconfirm>
        )}
       {documentEvaluationGroup.evaluateur_id && (isAdmin || documentEvaluationGroup.evaluateur_id === currentUserId) && (
          <Popconfirm
            onConfirm={handleReleaseOwnership}
            okText="Confirmer"
            cancelText="Annuler"
            okType="danger"
            title="Êtes-vous sûr de vouloir libérer cette partie ?"
          >
            <Button loading={loading} type="link" icon={<UnlockOutlined />} className={styles.releaseActionButton}>
              Libérer cette partie
            </Button>
          </Popconfirm>
        )}
      </div>
      <div className={styles.items}>
        {group.items.map((item) => (
          <EvaluationFormItem
            documentEvaluationGroup={documentEvaluationGroup}
            documentEvaluation={documentEvaluation}
            key={item.uid}
            item={item}
            className={styles.evaluationItem}
          />
        ))}
      </div>
    </Card>
  );
}

export default EvaluationFormGroup;
