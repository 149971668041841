import React from "react";
import Main from "../../components/layout/Main";
import OrganisationService from "../../services/OrganisationService";
import { useParams } from "react-router-dom";
import OrganisationDetails from "../../components/organisation/OrganisationDetails";
import { catchError } from "../../services/DaoService";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";
import { Card, Col, Row, Tabs } from "antd";
import { useUserContext } from "../../providers/UserProvider";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import PieceJointeList from "../../components/piecejointe/PieceJointeList";
import PieceJointeService from "../../services/PieceJointeService";
import ContactList from "../../components/contact/ContactList";
import ActivityFeed from "../../components/Activity/ActiviteFeed";
import LogTraces from "../../components/logtrace/LogTraces";
import OrganisationDocumentList from "../../components/document/OrganisationDocumentList";
import OrganisationUserList from "../../components/user/OrganisationUserList";

export default function OrganisationDetailsPage() {
    const [organisation, setOrganisation] = React.useState(null);
    const { uid } = useParams();
    const [loading, setLoading] = React.useState(true);
    const { check } = useUserContext();

    React.useEffect(() => {
        setLoading(true);
        OrganisationService.getInstance()
            .find(uid)
            .then(response => {
                setOrganisation(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [uid]);

    const tabItems = [
        organisation && {
            key: "contacts",
            label: "Contacts",
            children: <ContactList organisation={organisation} />,
        },
        {
            key: "attachments",
            label: "Fichiers & Notes",
            children:
                <PieceJointeList canSee={check('VIEW-ORGANIZATION-ATTACHMENTS')}
                    canAdd={check('ADD-ORGANIZATION-ATTACHMENTS')} candRemove={check('DELETE-ORGANIZATION-ATTACHMENTS')}
                    parentType={PieceJointeService.TYPES.ORGANISATION} parentId={organisation?.id} />
        },
        organisation && {
            key: 'documents',
            label: "Documents à évaluer",
            children: <OrganisationDocumentList organisation={organisation} />
        },
        check('VIEW-USERS') && organisation && {
            key: "users",
            label: "Utilisateurs",
            children: <OrganisationUserList organisation={organisation} />
        },
        check('MANAGE-DATA-OPERATIONS-HISTORY') && organisation && {
            key: "trace-logs",
            label: "Logs",
            children: <LogTraces target={'organisation'} parentId={organisation?.id} />
        },
    ]

    return (
        <Main>
            {
                check('VIEW-ORGANIZATION') ?
                    <>
                        {loading ? <LoadingPlaceholder /> : (
                            <Row gutter={[5, 5]}>
                                <Col xs={24}>
                                    <OrganisationDetails organisation={organisation} />
                                </Col>
                                <Col xs={24} sm={24} md={16}>
                                    <Card>
                                        <Tabs type="card" animated items={tabItems} />
                                    </Card>
                                </Col>
                                {!organisation?.pending &&
                                    (<Col xs={24} sm={24} md={8}>
                                        <ActivityFeed target={'exploitant'} parentId={organisation.id} />
                                    </Col>)
                                }
                            </Row>
                        )}
                    </>
                    : <UnauthorizedMessage />
            }
        </Main>
    );
} 