import { Card, Descriptions } from "antd";
import React from "react"
import { NavLink } from "react-router-dom";
export default function DocumentContactInfos({ contact }) {

    return (
        contact ?
            <Card >
                <Descriptions
                    size="middle"
                    column={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Descriptions.Item label="Prénom">
                        {contact.prenoms || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nom">
                        {contact.nom}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fonction">
                        {contact.fonction || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                        <NavLink to={`mailto:${contact.email}`} style={{ textDecoration: 'none' }}>
                            {contact.email}
                        </NavLink>
                    </Descriptions.Item>
                    <Descriptions.Item label="Téléphone">
                        <NavLink to={`tel:${contact.telephone}`} style={{ textDecoration: 'none' }}>
                            {contact.telephone}
                        </NavLink>
                    </Descriptions.Item>
                </Descriptions>

            </Card>
            : null
    );
}