import React, { useState } from "react";
import DocumentEvaluationGroupItemService from "../../services/DocumentEvaluationGroupItemService";
import { catchError } from "../../services/DaoService";
import { Card, Typography, Radio, Row, Col } from "antd";
import { useUserContext } from "../../providers/UserProvider";
import { usePusher } from "../../providers/PusherProvider";
import styles from "./EvaluationFormItem.module.scss"; 
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Title, Text } = Typography;

export default function EvaluationFormItem({ item, documentEvaluation, documentEvaluationGroup }) {
    const [groupItem, setGroupItem] = useState(item);
    const { currentUser } = useUserContext();
    const { pusher } = usePusher();

    React.useEffect(() => {
        if (!pusher) {
            return;
        }
        pusher.subscribe(`documentEvaluationGroupItem.${item.uid}`).bind('updated', function (data) {
            setGroupItem(documentEvaluationGroupItem => ({ ...documentEvaluationGroupItem, ...data.updatedData }));
        });
        return function () {
            pusher.unsubscribe(`documentEvaluationGroupItem.${item.uid}`);
        }
    }, [pusher, item?.uid]);

    const isEditable = () => {
        const { evaluateur_id } = documentEvaluationGroup;
        const { has_active_demande_correction, closed, validated } = documentEvaluation;
        return evaluateur_id === currentUser?.id && !has_active_demande_correction && !closed && !validated;
    };

    const saveEvaluationItem = async (updatedGroupItem) => {
        try {
            await DocumentEvaluationGroupItemService.getInstance().update(item.uid, updatedGroupItem);
            setGroupItem(updatedGroupItem);
        } catch (err) {
            catchError(err);
        }
    };

    const handleRadioChange = async (e) => {
        const value = e.target.value;
        const updatedGroupItem = { ...groupItem, resultat: value };

        if (value === "NS" && !groupItem.observation) {
            setGroupItem(updatedGroupItem); 
        } else {
            await saveEvaluationItem(updatedGroupItem);
        }
    };

    const handleObservationChange = (value) => {
        setGroupItem({ ...groupItem, observation: value });
    };

  /*   const handleObservationBlur = async () => {
        await saveEvaluationItem(groupItem);
    }; */

    return (
        <Card
            bordered={true}
            className={groupItem.resultat ==='S' ? styles.satisfaisant : groupItem.resultat ==='NS' ?  styles.nonSatisfaisant : groupItem.resultat ==='NA' ?  styles.nonApplicable : ''} 
            style={{
                marginBottom: "16px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                margin: "0 16px",
            }}
        >
            <Row align="middle" gutter={16}>
                <Col span={12}>
                    <Title level={5} style={{ marginBottom: 0 }}>
                        {item.code} - {item.nom}
                    </Title>
                </Col>
                <Col span={6}>
                    <Radio.Group
                        disabled={!isEditable()}
                        onChange={handleRadioChange}
                        value={groupItem.resultat}
                        style={{ width: "100%" }}
                    >
                        <Radio value="S">S</Radio>
                        <Radio value="NS">N/S</Radio>
                        <Radio value="NA">N/A</Radio>
                    </Radio.Group>
                </Col>
                <Col span={6}>
                    <ReactQuill
                        theme="snow"
                        value={groupItem.observation}
                        onChange={handleObservationChange}
                        readOnly={!isEditable() || !groupItem.resultat}
                        placeholder="Observation requise"
                        style={{ marginTop: "8px", width: "100%", borderRadius: "4px" }}
                        modules={{
                            toolbar: [
                                [{ 'color': [] }, { 'background': [] }], 
                                ['bold', 'italic', 'underline', 'strike'], 
                                [{ 'align': [] }]
                            ]
                        }}
                    />
                    {groupItem.resultat === "NS" && !groupItem.observation && (
                        <Text type="danger">Observation requise</Text>
                    )}
                </Col>
            </Row>
        </Card>
    );
}
