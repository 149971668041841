import React from "react";
import { Button, Card, Col, Popconfirm, Row, Tabs, Tag, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditFilled, TeamOutlined } from "@ant-design/icons";
import styles from './QueueDetails.module.scss';
import { useUserContext } from "../../providers/UserProvider";
import QueueService from "../../services/QueueService";
import { catchError } from "../../services/DaoService";
import PageTitle from "../utils/PageTitle";
import QueueEdit from "./QueueEdit";
import { useNavigate } from "react-router-dom";
import QueueMemberList from "./QueueMemberList";
import LogTraces from "../logtrace/LogTraces";
import UnauthorizedMessage from "../utils/UnauthorizedMessage";


const { Title, Text } = Typography;

export default function QueueDetails({ queueUid }) {
    const [isDeleting, setIsDeleting] = React.useState(false);
    const { check } = useUserContext();
    const navigate = useNavigate();
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [selectedUid, setSelectedUid] = React.useState(null);
    const [queue, setContact] = React.useState(null);
    const [loading, setLoading] = React.useState(false);


    React.useEffect(() => {
        setLoading(true);
        if (!queueUid) return;
        QueueService.getInstance()
            .find(queueUid)
            .then(response => {
                setContact(response.data);
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [queueUid]);

    const removeQueue = (queue) => {
        setIsDeleting(true);
        QueueService.getInstance()
            .remove(queue.uid)
            .then(() => {
                navigate(`/parametrage/queue-config`);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    const setItemToEdit = (queue) => {
        setSelectedUid(queue.uid);
        setEditModalVisible(true);
    }
    const handleQueueUpdate = (queue) => {
        setContact(queue);
        setEditModalVisible(false);
    }

    const tabItems = [
        {
            key: "users",
            label: "Membres de la file",
            children: check('VIEW-QUEUE-USERS') ? <QueueMemberList queueUid={queueUid} />: <UnauthorizedMessage />,
        },
        check('MANAGE-DATA-OPERATIONS-HISTORY') && queue && {
            key: "trace-logs",
            label: "Logs",
            children: <LogTraces target={'queue'} parentId={queue?.id} />
        },
    ];


    return (
        <>
            <QueueEdit onUpdate={handleQueueUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
            <PageTitle icon={<TeamOutlined />} title={queue?.nom} subTitle={queue?.email}
                backPageTitle="Liste des files d'attente" backPageLink="/parametrage/queue-config" canSeeBackPageLink={check('VIEW-QUEUES')}
            >
                {check('EDIT-QUEUE') ? <Button onClick={() => setItemToEdit(queue)} icon={<EditFilled />} className="btn btn-warning">Modifier</Button> : null}
                {check('DELETE-QUEUE') ?
                    <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer cette file d'attente ?" onConfirm={() => removeQueue(queue)} okText="Confirmer" cancelText="Annuler">
                        <Button loading={isDeleting}
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            className="btn btn-danger"
                        >Supprimer</Button>
                    </Popconfirm>
                    : null}
            </PageTitle>
            <Card loading={loading} className={`mb-2 ${styles.card}`} bordered>
                <Row gutter={[24, 16]}>
                    <Col xs={24} lg={16}>
                        <div className={styles.info}>
                            {queue ? (
                                <>
                                    <div className={styles.item}>
                                        <Title level={5} className={styles.label}>Nom</Title>
                                        <Text>{queue.nom}</Text>
                                    </div>
                                    <div className={styles.item}>
                                        <Title level={5} className={styles.label}>Code</Title>
                                        <Text>{queue.code}</Text>
                                    </div>
                                    <div className={styles.item}>
                                        <Title level={5} className={styles.label}>Description</Title>
                                        <Text>{queue.description}</Text>
                                    </div>
                                    <div className={styles.item}>
                                        <Title level={5} className={styles.label}>Statut</Title>
                                        {queue.enabled ? (
                                            <Tag className={`${styles.tag} ${styles.active}`} icon={<CheckCircleOutlined />} color="success">Actif</Tag>
                                        ) : (
                                            <Tag className={`${styles.tag} ${styles.inactive}`} icon={<CloseCircleOutlined />} color="error">Inactif</Tag>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <Text>File d'attente non disponible</Text>
                            )}
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card bordered>
                <Tabs items={tabItems} />
            </Card>
        </>
    );
}
