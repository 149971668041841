import React, { useState, useEffect } from 'react';
import { Table, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';
import styles from './LatestDocumentsForExploitant.module.scss';

const { Title } = Typography;

const LatestDocumentsForExploitant = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DashboardService.getInstance()
            .latestDocumentsForExploitant()
            .then(response => {
                if (response.error === 0) {
                    setData(response.data);
                } else {
                    catchError(response.message);
                }
            })
            .catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = [
        // row number
        {
            title: 'N°',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => index + 1,
        },
        {
            title: "Numéro de Document",
            dataIndex: "document_number",
            key: "document_number",
            render: (text, record) => <Link to={`/document/${record.uid}`}>{text}</Link>,
        },
        {
            title: 'Nom du Document',
            dataIndex: 'nom',
            key: 'nom',
            render: (text, record) => <Link to={`/document/${record.uid}`}>{text}</Link>,
        },
        {
            title: 'Date de Création',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => new Date(text).toLocaleDateString(),
        },
        {
            title: 'Statut Client',
            dataIndex: 'statut_client',
            key: 'statut_client',
            render: (text) => text.nom,
        },
        {
            title: "Nombre de corrections demandées",
            dataIndex: "demande_correction_documents_count",
            key: "demande_correction_documents_count",
            render: (count) => (<><span class="text-danger">{count} correction(s) demandée(s)</span></>),
        },
        {
            title: "En attente de correction",
            dataIndex: "pending_correction",
            key: "pending_correction",
            render: (isPending) => isPending ? <span class="text-warning">En attente de correction</span> : <span class="text-success">Aucune correction en attente</span>,
        }
    ];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className={styles.latestDocuments}>
            <Title level={4} className={styles.title}>Derniers Documents</Title>
            <Table scroll={{x: true}}
                columns={columns}
                dataSource={data}
                rowKey="id"
                pagination={{ pageSize: 5 }}
                bordered footer={() => <Link to="/document">Voir plus</Link>}
            />
        </div>
    );
};

export default LatestDocumentsForExploitant;
