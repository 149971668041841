/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { ContactsOutlined, DashboardOutlined, FolderOutlined, SettingOutlined } from "@ant-design/icons";
import { useUserContext } from "../../providers/UserProvider";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [sidenavColor,] = useState("#d9363e");
  const [sidenavType,] = useState("transparent");
  const [fixed,] = useState(true);
  const [menuItems, setMenuItems] = React.useState([]);
  const { check } = useUserContext();
  const { pathname } = useLocation();
  const page = pathname.replace("/", "").split("/")[0];
  const { currentUser } = useUserContext();

  React.useEffect(() => {
    let menuItemData = [
      {
        key: "dashboard",
        label: (
          <NavLink to="/dashboard">
            <span className={``}>
              <DashboardOutlined />
            </span>
            <span className={``}>Tableau de bord</span>
          </NavLink>
        ),
        path: "/dashboard",
        visible: check("VIEW-DASHBOARD"),
      },
      {
        key: "parametrage",
        icon: (
          <span className={``}>
            <SettingOutlined />
          </span>
        ),
        label: "Paramétrage",
        visible: false,
        children: [
          {
            key: 'groupes',
            label: <NavLink to="/parametrage/groupe">Groupes d'utilisateurs & Roles</NavLink>,
            path: '/parametrage/groupe',
            visible: check("VIEW-GROUPS"),
          },
          {
            key: 'users',
            label: <NavLink to="/parametrage/user">Utilisateurs & Accès</NavLink>,
            path: '/parametrage/user',
            visible: check("VIEW-USERS"),
          },
          {
            key: 'exploitants',
            label: <NavLink to="/parametrage/exploitant">Exploitants, Structures & Organismes</NavLink>,
            path: '/parametrage/exploitant',
            visible: check("VIEW-ORGANIZATIONS"),
          },
          {
            key: 'domains-config',
            label: <NavLink to="/parametrage/domains-config">Domaines, Catégories & Structures</NavLink>,
            path: '/parametrage/domains-config',
            visible: check("CONFIGURE-DOMAINS"),
          },
          {
            key: 'domains-types-config',
            label: <NavLink to="/parametrage/domains-types-dossier">Domaines, Types Documents & Dossiers</NavLink>,
            path: '/parametrage/domains-types-dossier',
            visible: check("CONFIGURE-DOMAINS-TYPES"),
          },
          {
            key: 'checklist-modele',
            label: <NavLink to="/parametrage/checklist-modele">Modèles de checklists</NavLink>,
            path: '/parametrage/checklist-modele',
            visible: check("VIEW-CHECKLIST-MODELES"),
          },
          {
            key: 'checklistmodel-config',
            label: <NavLink to="/parametrage/checklistmodel-config">Modèles de checklists pré-configurés</NavLink>,
            path: '/parametrage/checklistmodel-config',
            visible: check("CONFIGURE-CHECKLIST-MODELS"),
          },
          {
            key: 'queue-config',
            label: <NavLink to="/parametrage/queue-config">Paramètrage des files d’attentes</NavLink>,
            path: '/parametrage/queue-config',
            visible: check("VIEW-QUEUES"),
          }
        ],
      },
      currentUser?.is_contact ?
      {
        key: "document",
        icon: (
          <span className={``}>
            <FolderOutlined />
          </span>
        ),
        label: (
          <NavLink to="/document">
            <span className={``}>Documents</span>
          </NavLink>
        ),
        path: "/document",
        visible: check("VIEW-DOCUMENTS"),
      } : 
      {
        key: "gestion-document",
        icon: (
          <span className={``}>
            <FolderOutlined />
          </span>
        ),
        label: "Gestion des documents",
        visible: false,
        children: [
          {
            key: 'document',
            label: <NavLink to="/document">Documents des exploitants</NavLink>,
            path: '/document',
            visible: check("VIEW-DOCUMENTS"),
          },
          {
            key: 'document-evaluation',
            label: <NavLink to="/gestion-document/documentevaluation">Evaluation des documents</NavLink>,
            path: '/gestion-document/documentevaluation',
            visible: check("SHOW-DOCUMENT-EVALUATION"),
          }
        ],
      },
      {
        key: "contact",
        icon: (
          <span className={``}>
            <ContactsOutlined />
          </span>
        ),
        label: (
          <NavLink to="/contact">
            <span className={``}>Contacts</span>
          </NavLink>
        ),
        path: "/contact",
        visible: check("VIEW-CONTACTS"),
      },  
    ];
    // filtrer les éléments visibles
    menuItemData.forEach((item) => {
      if (item.children) {
        item.children = item.children.filter((child) => child.visible);
        item.visible = item.children.length > 0;
      }
    });
    // filtrer les items visibles
    menuItemData = menuItemData.filter((item) => item.visible);

    setMenuItems(menuItemData);
  }, [check, page, currentUser]);


  const openDrawer = () => setVisible(!visible);

  // pathname = pathname.replace("/", "");

  // useEffect(() => {
  //   if (pathname === "rtl") {
  //     setPlacement("left");
  //   } else {
  //     setPlacement("right");
  //   }
  // }, [pathname]);

  return (
    <Layout
      className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      <Drawer
        title={false}
        placement="left"
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        key={"left"}
        width={300}
        className={`drawer-sidebar ${pathname === "rtl" ? "drawer-sidebar-rtl" : ""
          } `}
      >
        <Layout
          className={`layout-dashboard ${pathname === "rtl" ? "layout-dashboard-rtl" : ""
            }`}
        >
          <Sider
            trigger={null}
            width={300}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
              }`}
            style={{ background: sidenavType }}
          >
            <Sidenav color={sidenavColor} menuItems={menuItems} page={page} />
          </Sider>
        </Layout>
      </Drawer>
      {/* <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {}}
        trigger={null}
        width={300}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${
          sidenavType === "#fff" ? "active-route" : ""
        }`}
        style={{ background: sidenavType }}
      >
        <Sidenav color={sidenavColor} />
      </Sider> */}
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header menuItems={menuItems} page={page}
                onPress={openDrawer}
              />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
            />
          </AntHeader>
        )}
        <Content className="content-ant">{children}</Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default Main;
