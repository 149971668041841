import React from "react";
import { Button, Card, Col, Popconfirm, Row, Tag, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, EditFilled } from "@ant-design/icons";
import styles from '../organisation/OrganisationDetails.module.scss';
import OrganisationService from "../../services/OrganisationService";
import { catchError } from "../../services/DaoService";
import { useUserContext } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import OrganisationEdit from "./OrganisationEdit";
import PageTitle from "../utils/PageTitle";
import CounterCard from "../utils/CounterCard";

const { Title, Text } = Typography;

export default function OrganisationDetails({ organisation }) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { check } = useUserContext();
  const navigate = useNavigate();
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [selectedUid, setSelectedUid] = React.useState(null);
  const [organisationData, setOrganisationData] = React.useState(organisation);
  const [approving, setApproving] = React.useState(false);


  const removeOrganisation = (organisation) => {
    setIsDeleting(true);
    OrganisationService.getInstance()
      .remove(organisation.uid)
      .then(() => {
        navigate(`/exploitant`);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setIsDeleting(false);
      });
  }

  const setItemToEdit = (organisation) => {
    setSelectedUid(organisation.uid);
    setEditModalVisible(true);
  }

  const handleOrganisationUpdate = (organisation) => {
    setOrganisationData(organisation);
    setEditModalVisible(false);
  }

  const approve = () => {
    setApproving(true);
    OrganisationService.getInstance().approve(organisationData.uid).then(() => {
      setOrganisationData({ ...organisationData, pending: false });
    }).catch(error => {
      catchError(error);
    }).finally(() => {
      setApproving(false);
    });
  }

  const reject = () => {
    setApproving(true);
    OrganisationService.getInstance().reject(organisationData.uid).then(() => {
      setOrganisationData({ ...organisationData, pending: false });
    }).catch(error => {
      catchError(error);
    }).finally(() => {
      setApproving(false);
    });
  }

  return (
    <>
      <OrganisationEdit onUpdate={handleOrganisationUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
      <PageTitle title={organisationData?.nom} icon={<EditFilled />} subTitle={organisationData?.description ?? organisationData?.sigle}
        backPageTitle="Liste des exploitants" backPageLink="/parametrage/exploitant" canSeeBackPageLink={check('VIEW-ORGANIZATIONS')}>
        {check('EDIT-ORGANIZATION') ? <Button
          onClick={() => setItemToEdit(organisationData)} icon={<EditFilled />}
          className="btn btn-warning" disabled={organisationData.pending || !organisationData.accepted}>Modifier</Button> : null}
        {check('DELETE-ORGANIZATION') ?
          <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer cet exploitant?" onConfirm={() => removeOrganisation(organisationData)} okText="Confirmer" cancelText="Annuler">
            <Button loading={isDeleting}
              type="primary"
              danger disabled={organisationData.pending}
              icon={<DeleteOutlined />}
              className="btn btn-danger"
            >Supprimer</Button>
          </Popconfirm>
          : null}
        {!!(check('APPROVE-REJECT-ORGANIZATION') && organisationData.pending) && (
          <div className={styles.approbationButtonsSection}>
            <Popconfirm okType='primary' title="Voulez-vous vraiment approuver cet exploitant?" onConfirm={approve} okText="Approuver" cancelText="Annuler">
              <Button loading={approving}
                type="primary" iconPosition="end"
                icon={<CheckCircleOutlined />}
                className="btn btn-sm btn-success" style={{ marginLeft: '10px' }}
              >Accepter</Button>
            </Popconfirm> ou
            <Popconfirm okType='danger' title="Voulez-vous vraiment rejeter cet exploitant?" onConfirm={reject} okText="Rejeter" cancelText="Annuler">
              <Button loading={approving}
                type="primary" iconPosition="end"
                danger
                icon={<CloseCircleOutlined />}
                className="btn btn-sm btn-danger" style={{ marginLeft: '10px' }}
              >Refuser</Button>
            </Popconfirm>

          </div>
        )}
      </PageTitle>
      <Row gutter={[5, 5]}>
        <Col xs={24} md={16}>
          <Card className={styles.card} bordered>
            <div className={styles.info}>
              <div className={styles.item}>
                <Title level={5} className={styles.label}>Nom</Title>
                <Text>{organisationData.nom}</Text>
              </div>
              <div className={styles.item}>
                <Title level={5} className={styles.label}>Sigle</Title>
                <Text>{organisationData.sigle}</Text>
              </div>
              <div className={styles.item}>
                <Title level={5} className={styles.label}>Description</Title>
                <Text>{organisationData.description}</Text>
              </div>
              <div className={styles.item}>
                <Title level={5} className={styles.label}>Type d'exploitant</Title>
                <Text>{organisationData.type_organisation ? organisationData.type_organisation.nom : <span className="badge bg-secondary">Non renseigné</span>}</Text>
              </div>
              <div className={styles.item}>
                <Title level={5} className={styles.label}>Responsable</Title>
                <Text>{organisationData.responsable ? organisationData.responsable : <span className="badge bg-secondary">Non renseigné</span>}</Text>
              </div>
              <div className={styles.item}>
                <Title level={5} className={styles.label}>Statut</Title>
                {organisationData.enabled ? (
                  <Tag className={`${styles.tag} ${styles.active}`} icon={<CheckCircleOutlined />} color="success">Actif</Tag>
                ) : (
                  <Tag className={`${styles.tag} ${styles.inactive}`} icon={<CloseCircleOutlined />} color="error">Inactif</Tag>
                )}
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <CounterCard title="Documents" value={organisationData.documents_count} />
        </Col>
      </Row>
    </>
  );
}
