import React from 'react';
import ActivityItem from './ActivityItem';
import styles from './ActivityFeed.module.scss';
import { Button, Card, List } from 'antd';
import { ArrowDownOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import ActivityForm from './ActivityForm';
import ActiviteService from '../../services/ActiviteService';
import { catchError } from '../../services/DaoService';
import LoadingPlaceholder from '../utils/LoadingPlaceholder';
import UserService from '../../services/UserService';

const ActivityFeed = ({ parentId, target }) => {
  const [openAddForm, setOpenAddForm] = React.useState(false);
  const [activities, setActivities] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [paginationData, setPaginationData] = React.useState({});
  const [hasMore, setHasMore] = React.useState(false);
  const [users, setUsers] = React.useState([]);


  const init = React.useCallback(() => {
    setActivities([]);
    setLoading(true);
    ActiviteService.getInstance().findAll(parentId, target)
      .then(response => {
        setPaginationData(response.data);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setLoading(false);
      });
  }, [parentId, target]);


  React.useEffect(() => {
    UserService.getInstance().all()
        .then(response => {
            setUsers(response.data);
        }).catch(error => {
            catchError(error);
        });
}, []);

  React.useEffect(() => {
    init();
  }, [init]);

  React.useEffect(() => {
    if (paginationData.data) {
      setActivities(activities => {
        // unique activities
        const newActivities = paginationData.data.filter(activity => !activities.find(a => a.id === activity.id));
        return [...activities, ...newActivities];
      });
      setHasMore(paginationData.next_page_url !== null);
    } else {
      setActivities([]);
      setHasMore(false);
    }
  }, [paginationData]);

  const handleActivityCreation = (activity) => {
    setActivities(activities => [...activities, activity]);
  }

  const closeAddForm = () => {
    setOpenAddForm(false);
  }

  const loadMore = () => {
    setLoading(true);
    ActiviteService.get(paginationData.next_page_url)
      .then(response => {
        setPaginationData(response.data);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleDelete = (activity) => {
    setActivities(activities => activities.filter(a => a.id !== activity.id));
  }
  const handleUpdate = (updatedActivity) => {
    setActivities(activities => activities.map(activity => 
      activity.id === updatedActivity.id ? updatedActivity : activity
    ));
  }

  const handleDone = (activity) => {
    setActivities(activities => activities.map(a => {
      if (a.id === activity.id) {
        a.done = !a.done;
      }
      return a;
    }));
  }

  return (
    <Card actions={[
      <Button icon={<ArrowDownOutlined />} disabled={!hasMore} onClick={loadMore}>Charger plus</Button>
    ]} extra={[
      <Button loading={loading} className='me-1' type="default" onClick={init} icon={<ReloadOutlined />} >
        Raffraichir
      </Button>,
      <Button type='primary' onClick={() => setOpenAddForm(true)} icon={<PlusCircleOutlined />}>Ajouter</Button>
    ]} classNames={{ body: "p-0" }} className={styles.activityFeedCard} title="Activités">
      <ActivityForm users={users} parentId={parentId} target={target} visible={openAddForm} onClose={closeAddForm} onAddActivity={handleActivityCreation} />
      <List footer={[
        loading && <LoadingPlaceholder />
      ]}
        className={styles.activityFeed}
        itemLayout="horizontal" style={{ overflowY: 'auto', height: '500px' }}
        dataSource={activities}
        renderItem={activity => (
          <ActivityItem users={users} onToggleDone={handleDone} onDelete={handleDelete}  onUpdate={handleUpdate} key={activity.id} item={activity} />
        )}
      />
    </Card>
  );
};

export default ActivityFeed;
