import React, { useCallback } from "react";
import { catchError } from "../../services/DaoService";
import { Avatar, Button, Card, List, Popconfirm, Skeleton } from "antd";
import NotificationService from "../../services/NotificationService";
import Main from "../../components/layout/Main";
import Moment from "react-moment";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";
import { Link, useNavigate } from "react-router-dom";
import { FolderOpenOutlined } from "@ant-design/icons";
import { useNotificationContext } from "../../providers/NotificationProvider";
import { useUserContext } from "../../providers/UserProvider";

const clockicon = [
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        key={0}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
            fill="#111827"
        ></path>
    </svg>,
];

export default function NotificationListPage() {
    const [notifications, setNotifications] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    const [loadingMore, setLoadingMore] = React.useState(false);
    const [isMarkAllAsRead, setIsMarkAllAsRead] = React.useState(false);
    const { loadUnreadNotifications, loadUnreadNotificationsCount } = useNotificationContext();
    const navigate = useNavigate();
    const { currentUser } = useUserContext();
    const [hasMore, setHasMore] = React.useState(false);

    const init = useCallback(() => {
        if (!currentUser) {
            return;
        }
        setNotifications([]);
        setLoading(true);
        NotificationService.getInstance()
            .findAllNotifications(currentUser.uid)
            .then((response) => {
                setPaginationData(response.data);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [currentUser]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (paginationData) {
            setHasMore(paginationData.next_page_url!==null);
            setNotifications(notifications=>{
                const newNotifications = paginationData.data.filter(notification => !notifications.find(n => n.id === notification.id));
                return [...notifications, ...newNotifications];
            });
        } else {
            setNotifications([]);
        }
    }, [paginationData]);


    const dataSources = notifications.map(notification => ({
        title: notification.data.title,
        avatar: notification.data.avatar,
        url: notification.data.url,
        id: notification.id,
        read_at: notification.read_at,
        description: <>
            <div>{notification.data.body}</div>
            <div>{clockicon} <Moment fromNow>{notification.created_at}</Moment></div>
        </>,
    }));

    const loadMore = () => {
        if (paginationData.next_page_url) {
            setLoadingMore(true);
            NotificationService
                .get(paginationData.next_page_url)
                .then((response) => {
                    if (!response) {
                        return;
                    }
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoadingMore(false);
                });
        }
    }

    const handleRoute = (notification) => {
        if (notification.read_at) {
            navigate(notification.url);
        }
        NotificationService.getInstance()
            .markAsRead(currentUser.uid, notification.id)
            .then(() => {
                // reload unread notifications & count
                loadUnreadNotifications();
                loadUnreadNotificationsCount();
                // navigate to the url
                navigate(notification.url);
            }).catch((error) => {
                catchError(error);
            });
    }

    const handleMarkAllAsRead = () => {
        setIsMarkAllAsRead(true);
        NotificationService.getInstance()
            .markAllAsRead(currentUser.uid)
            .then((response) => {
                init();
                loadUnreadNotifications();
                loadUnreadNotificationsCount();
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsMarkAllAsRead(false);
            });
    };

    return (
        <Main>
            {loading ? (
                <LoadingPlaceholder />
            ) : (
                <Card classNames={{ title: 'text-center' }} title="Liste des notifications" extra={
                    <Popconfirm title="Voulez-vous vraiment marquer toutes les notifications comme lues?" onConfirm={() => handleMarkAllAsRead()} okText="Oui" cancelText="Non">
                        <Button loading={isMarkAllAsRead} type="link" icon={<FolderOpenOutlined />}>Tout marquer comme lu</Button>
                    </Popconfirm>
                } actions={[
                    <Button key="1" disabled={!hasMore} type="primary" onClick={loadMore}>Charger plus</Button>
                ]}>
                    <List
                        loading={loadingMore}
                        itemLayout="horizontal"
                        loadMore={loadMore}
                        dataSource={dataSources}
                        renderItem={(item) => (
                            <List.Item className="p-2"
                                style={{ backgroundColor: item.read_at ? 'transparent' : '#f0f0f0' }}
                            >
                                <Skeleton avatar title={false} loading={item.loading}>
                                    <List.Item.Meta
                                        avatar={
                                            <Link onClick={() => handleRoute(item)} >
                                                <Avatar size={"large"} src={item.avatar} />
                                            </Link>
                                        }
                                        title={<Link onClick={() => handleRoute(item)}>{item.title}</Link>}
                                        description={item.description}
                                    />
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </Card>
            )}

        </Main>
    )
}