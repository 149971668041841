import { DaoService } from "./DaoService";

export default class ActiviteService extends DaoService {
  static getInstance() {
    return super.getInstance("activite");
  }

  async findAll(parentId, target) {
    return ActiviteService.get(`${this.prefix}/${parentId}/${target}`);
  }

  async switchDone(uid) {
    return ActiviteService.post(`${this.prefix}/${uid}/switch-done`);
  }

  async update(uid, data) {
    return ActiviteService.put(`${this.prefix}/${uid}`, data);
  } 
  
}
