import React from "react";
import Main from "../../components/layout/Main";
import DocumentList from "../../components/document/DocumentList";

export default function DocumentListPage() {
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    
    return (
        <Main>
            <DocumentList loading={loading} paginationData={paginationData} setLoading={setLoading}
             setPaginationData={setPaginationData} />
        </Main>
    );

} 