import { DaoService } from "./DaoService";

export default class LogTraceService extends DaoService {
    static getInstance() {
        return super.getInstance('logtrace');
    }

    async findAll(target, parentId) {
        return LogTraceService.get(`/${this.prefix}/${target}/${parentId}`);
    }

}