import { Card, Spin } from "antd";
import React from "react";
import { catchError } from "../../services/DaoService";
import TypeDossierService from "../../services/TypeDossierService";
import ChecklistModeleService from "../../services/ChecklistModeleService";
import ChecklistModeleConfigItemForm from "./ChecklistModeleConfigItemForm";

export default function ChecklistModeleConfigItem({ domaineCategorie }) {
  const [loading, setLoading] = React.useState(false);
  const [typeDossiers, setTypeDossiers] = React.useState([]);
  const [checklistModeles, setChecklistModeles] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    ChecklistModeleService.getInstance()
      .all()
      .then((response) => {
        setChecklistModeles(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    TypeDossierService.getInstance()
      .getTypeDossiersWithChecklistModeles(domaineCategorie.id)
      .then((response) => {
        setTypeDossiers(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [domaineCategorie]);

  return (
    <Card
      classNames={{ body: "p-0" }} loading={loading} >
      {loading ? (
        <Spin />
      ) : (
        <>
          {typeDossiers.map((typeDossier, index) => (
            <Card
              key={index}
              title={typeDossier.nom}
            >
              <ChecklistModeleConfigItemForm
                checklistIds={typeDossier.checklistModeleIds}
                checklists={checklistModeles}
                domaineCategorieId={typeDossier.pivot.domaine_categorie_id}
                typeDossierId={typeDossier.pivot.type_dossier_id}
              />
            </Card>
          ))}
        </>
      )}
    </Card>
  );
}
