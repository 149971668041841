import { DaoService } from "./DaoService";
export default class TypeDocumentService extends DaoService {

  static getInstance() {
    return super.getInstance("typedocument");
  }

  all() {
    return TypeDocumentService.get(`${this.prefix}/all`);
  }
  
  findByDomaineCategorie(domaineCategorieUid) {
    return TypeDocumentService.get(`${this.prefix}/${domaineCategorieUid}/domainecategorie`);
  }
}