import React, { useState, useEffect } from 'react';
import { Row, Col, Spin, Typography } from 'antd';
import styles from './ExploitantCountStats.module.scss';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';

const { Title } = Typography;

const ExploitantCountStats = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DashboardService.getInstance()
            .countExploitants()
            .then(response => {
                if (response.error === 0) {
                    setData(response.data);
                } else {
                    catchError(response.message);
                }
            })
            .catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className={styles.exploitantCountStats}>
            <Title level={4} className={styles.title}>Statistiques des Exploitants</Title>
            <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Col>
                    <div className={styles.statCard}>
                        <div className={styles.statValue}>{data.exploitants}</div>
                        <div className={styles.statLabel}>Exploitants</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.statCard}>
                        <div className={styles.statValue}>{data.exploitantActifs}</div>
                        <div className={styles.statLabel}>Actifs</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.statCard}>
                        <div className={styles.statValue}>{data.exploitantApprouves}</div>
                        <div className={styles.statLabel}>Approuvés</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.statCard}>
                        <div className={styles.statValue}>{data.exploitantRejetes}</div>
                        <div className={styles.statLabel}>Rejetés</div>
                    </div>
                </Col>
                <Col>
                    <div className={styles.statCard}>
                        <div className={styles.statValue}>{data.exploitantEnAttente}</div>
                        <div className={styles.statLabel}>En Attente</div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ExploitantCountStats;
