import { DaoService } from "./DaoService";

class DocumentPrioriteService extends DaoService {
    static getInstance() {
        return super.getInstance('documentpriorite');
    }

    all() {
        return DocumentPrioriteService.get(`${this.prefix}/all`);
    }  
}

export default DocumentPrioriteService;