import { DaoService } from "./DaoService";

export default class DocumentMemberService extends DaoService {
    static  getInstance() {
        return super.getInstance('documentmember');
    }

    findByDocument(documentUid) {
        return DocumentMemberService.get(`${this.prefix}/${documentUid}/document`);
    }

    linkUsersToDocument(uid, data) {
        return DocumentMemberService.post(`${this.prefix}/${uid}/users`, data);
    }
    
}