import React, { useCallback } from "react";
import { useUserContext } from "../../providers/UserProvider";
import { NavLink, useNavigate } from "react-router-dom";
import { catchError } from "../../services/DaoService";
import { Button, Col, Divider, Input, Popconfirm, Row, Select, Space, Spin, } from "antd";
import { DeleteFilled, EditFilled, EyeOutlined, FileOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InfiniteTable from "../utils/InfiniteTable";
import UnauthorizedMessage from "../utils/UnauthorizedMessage";
import { DocumentService } from "../../services/DocumentService";
import DocumentNew from "./DocumentNew";
import DocumentEdit from "./DocumentEdit";
import PageTitle from "../utils/PageTitle";
import OrganisationService from "../../services/OrganisationService";
import StatutDocumentService from "../../services/StatutDocumentService";
import CategorieDocumentService from "../../services/CategorieDocumentService";
import TypeDossierService from "../../services/TypeDossierService";
import TypeDocumentService from "../../services/TypeDocumentService";
import StatutClientService from "../../services/StatutClientService";


export default function DocumentList({ paginationData, setPaginationData, loading, setLoading }) {
    const { currentUser } = useUserContext();

    const [documents, setDocuments] = React.useState([]);
    const [selectedUid, setSelectedUid] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(true);
    const { check } = useUserContext();
    const [searchText, setSearchText] = React.useState(null);
    const [statutDocuments, setStatutDocuments] = React.useState([]);
    const [statutDocumentIds, setStatutDocumentIds] = React.useState([]);
    const [organisations, setOrganisations] = React.useState([]);
    const [organisationIds, setOrganisationIds] = React.useState([]);
    const [categorieDocuments, setCategorieDocuments] = React.useState([]);
    const [categorieDocumentIds, setCategorieDocumentIds] = React.useState([]);
    const [typeDossiers, setTypeDossiers] = React.useState([]);
    const [typeDossierIds, setTypeDossierIds] = React.useState([]);
    const [typeDocuments, setTypeDocuments] = React.useState([]);
    const [typeDocumentIds, setTypeDocumentIds] = React.useState([]);
    const [statutClients, setStatutClients] = React.useState([]);
    const [statutClientIds, setStatutClientIds] = React.useState([]);

    const navigate = useNavigate();

    const setItemToEdit = (record) => {
        setSelectedUid(record.uid);
        setEditModalVisible(true);
    }
    const setItemToView = (record) => {
        setSelectedUid(record.uid);
        navigate(`/document/${record.uid}`);
    }

    const handleNewDocument = (document) => {
        setDocuments([...documents, document]);
        navigate(`/document/${document.uid}`);
    }

    const handleDocumentUpdate = (document) => {
        setDocuments(documents.map(d => d.uid === document.uid ? document : d));
        setEditModalVisible(false);
    }

    const init = useCallback(() => {
        setLoading(true);
        DocumentService.getInstance()
            .search({ searchText, statutDocumentIds, organisationIds, categorieDocumentIds, typeDossierIds, typeDocumentIds, statutClientIds })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setDocuments(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, statutDocumentIds, organisationIds, categorieDocumentIds, typeDossierIds, typeDocumentIds, statutClientIds, setLoading, setPaginationData]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (paginationData) {
            // merge the new data with the old data without duplicates (if any)
            setDocuments(documents => [...new Map([...documents, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const removeDocument = (document) => {
        setIsDeleting(true);
        DocumentService.getInstance()
            .remove(document.uid)
            .then(() => {
                setDocuments(documents.filter(d => d.id !== document.id));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    const defaultColumns = [
        {
            title: 'Nom',
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            width: 400,
            // retour à la ligne si le texte est trop long
            render: (text, record) => <div style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-all',
            }}>
                {check('VIEW-DOCUMENT') ? <NavLink to={`/document/${record.uid}`}>{text}</NavLink> : text}
            </div>,
        },
        {
            title: "Numéro Doc.",
            dataIndex: 'document_number',
            key: 'document_number',
            sorter: (a, b) => a.document_number.localeCompare(b.document_number),
            width: 150,
            render: (text) => <strong>{text}</strong>
        },
        {
            title: "Categorie Document",
            dataIndex: 'categorie_document',
            key: 'categorie_document',
            render: (categorie) => categorie?.nom,
            sorter: (a, b) => a.categorie_document?.nom.localeCompare(b.categorie_document?.nom),
            width: 200,
            ellipsis: true
        },
        {
            title: currentUser.is_contact ? "Statut" : "Statut Client",
            dataIndex: 'statut_client',
            key: 'statut_client',
            render: (statut) => statut.nom,
            sorter: (a, b) => a.statut_client.nom.localeCompare(b.statut_client?.nom),
            width: 200,
            ellipsis: true
        },
        {
            title: "Contact",
            dataIndex: 'contact',
            key: 'contact',
            render: (contact) => (
                check('VIEW-CONTACT') ? (
                    <NavLink to={`/contact/${contact?.uid}`}>
                        {contact?.name}
                    </NavLink>
                ) : (
                    <span>{contact?.name}</span>
                )
            ),
            sorter: (a, b) => a.contact.nom.localeCompare(b.contact?.nom),
            width: 200,
            ellipsis: true
        },
        {
            title: 'Clôturé',
            dataIndex: 'closed',
            key: 'closed',
            render: (closed) => (
                <span className={`badge ${closed ? 'bg-success' : 'bg-danger'}`}>{closed ? 'Clôturé' : 'Pas encore'}</span>
            ),
            width: 100
        },
        {
            title: 'Approuvé',
            dataIndex: 'accepted',
            key: 'accepted',
            render: (accepted, record) => (
                <span className={`badge ${record.closed && (accepted || record.approved) ? 'bg-success' : record.closed ? 'bg-danger' : 'bg-warning text-dark'}`}>{record.closed && (accepted || record.approved) ? 'Approuvé' : record.closed ? "Rejeté" : 'En attente'}</span>
            ),
            width: 100
        },
        {
            title: "Date retour souhaitée",
            dataIndex: 'date_retour_souhaitee',
            key: 'date_retour_souhaitee',
            width: 150,
            render: (date) => date ? new Date(date).toLocaleDateString() : <span className="badge bg-secondary">Non renseigné</span>,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (record) => (
                <Space>
                    {check('VIEW-DOCUMENT') ? <Button onClick={() => setItemToView(record)} icon={<EyeOutlined />} className="btn btn-primary"></Button> : null}
                    {check('EDIT-DOCUMENT') ? <Button onClick={() => setItemToEdit(record)} icon={<EditFilled />} className="btn btn-warning"></Button> : null}
                    {((currentUser?.is_contact && record.statut_client?.code === StatutClientService.STATUS.DRAFT) || record?.responsable_id === currentUser?.id || currentUser?.is_admin) ?
                        <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer ce document?" onConfirm={() => removeDocument(record)} okText="Confirmer" cancelText="Annuler">
                            <Button loading={isDeleting} icon={<DeleteFilled />} className="btn btn-danger"></Button>
                        </Popconfirm>
                        : null}
                </Space>
            )
        }
    ];

    const labelStyle = {
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    const anacimColumns = [
        {
            title: "Exploitant",
            dataIndex: 'organisation',
            key: 'organisation',
            render: (organisation) => (
                check('VIEW-ORGANISATION') ? (
                    <NavLink to={`/parametrage/exploitant/${organisation.uid}`}>
                        {organisation?.nom}
                    </NavLink>
                ) : (
                    <span>{organisation?.nom}</span>
                )
            ),
            sorter: (a, b) => a.organisation?.nom.localeCompare(b.organisation?.nom),
            width: 200,
            ellipsis: true
        },
        {
            title: "Statut Document",
            dataIndex: 'statut_document',
            key: 'statut_document',
            render: (statut) => statut?.nom,
            sorter: (a, b) => a.statut_document?.nom.localeCompare(b.statut_document?.nom),
            width: 200,
            ellipsis: true
        }
    ];

    const filterColumns = (records) => {
        return columns.filter(column => {
            const key = column.dataIndex;
            if (['version', 'revision', 'edition', 'codification', 'code_codification', 'date_edition', 'date_revision'].includes(key)) {
                return records.some(record => record[key] !== null && record[key] !== undefined && record[key] !== '');
            }
            return true;
        });
    };

    React.useEffect(() => {
        if (currentUser.is_contact) return;
        StatutDocumentService.getInstance()
            .all()
            .then((response) => {
                if (!response?.data) return;
                setStatutDocuments(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, [currentUser]);

    React.useEffect(() => {
        if (currentUser.is_contact) return;
        OrganisationService.getInstance()
            .all()
            .then((response) => {
                if (!response?.data) return;
                setOrganisations(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, [currentUser]);

    React.useEffect(() => {
        CategorieDocumentService.getInstance()
            .all()
            .then((response) => {
                if (!response?.data) return;
                setCategorieDocuments(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, []);

    React.useEffect(() => {
        TypeDossierService.getInstance()
            .all()
            .then((response) => {
                if (!response?.data) return;
                setTypeDossiers(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, []);

    React.useEffect(() => {
        TypeDocumentService.getInstance()
            .all()
            .then((response) => {
                if (!response?.data) return;
                setTypeDocuments(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, []);

    React.useEffect(() => {
        if (!currentUser.is_contact) return;
        StatutClientService.getInstance()
            .all()
            .then((response) => {
                if (!response?.data) return;
                setStatutClients(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, [currentUser]);


    const columns = [
        ...defaultColumns.slice(0, 5), //insérez avant la colonne 'Statut Client'
        ...(!currentUser.is_contact ? anacimColumns : []),
        ...defaultColumns.slice(5) // Le reste des colonnes après les colonnes conditionnelles

    ];

    const loadMore = () => {
        if (hasMore && !loading) {
            setLoading(true);
            DocumentService.post(paginationData.next_page_url, { searchText, statutDocumentIds, organisationIds, categorieDocumentIds, typeDossierIds, typeDocumentIds, statutClientIds })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const filteredColumns = filterColumns(documents);
    return (
        <>
            <DocumentNew onCreate={handleNewDocument} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} />
            <DocumentEdit onUpdate={handleDocumentUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
            <PageTitle icon={<FileOutlined />} title="Liste des documents" subTitle="Les documents soumis par les exploitants pour approbation">
                {
                    currentUser.is_contact ?
                        <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Nouveau Document</Button> : null
                }
                <Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>
            </PageTitle>
            {check('VIEW-DOCUMENTS') ? (<>
                <Spin spinning={loading}>
                    <InfiniteTable title={
                        <Row gutter={[24, 16]}>
                            <Col span={24}>
                                <Row gutter={[16, 16]} justify={'center'}>
                                    <Col span={24}>
                                        <Divider>Filtres rapides</Divider>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8}>
                                        <Input.Search
                                            size="large"
                                            value={searchText}
                                            onInput={(e) => setSearchText(e.target.value)}
                                            placeholder="Rechercher par nom, numéro ou version"
                                        />
                                    </Col>
                                    {currentUser.is_contact ? (
                                        <Col xs={24} sm={12} md={8} lg={8}>
                                            <div style={containerStyle}>
                                                <div style={labelStyle}>Statut client: </div>
                                                <Select
                                                    size="large" optionFilterProp="children"
                                                    value={statutClientIds}
                                                    onChange={(value) => setStatutClientIds(value)}
                                                    allowClear
                                                    mode='multiple'
                                                    placeholder="Filtrer par statut client"
                                                    style={{ width: '100%' }}
                                                >
                                                    {statutClients.map((statutClient) => (
                                                        <Select.Option key={statutClient.id} value={statutClient.id}>
                                                            {statutClient.nom}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Col>
                                    ) : null}
                                    {!currentUser.is_contact ? (
                                        <>
                                            <Col xs={24} sm={12} md={8} lg={8}>
                                                <div style={containerStyle}>
                                                    <div style={labelStyle}>Statut document: </div>
                                                    <Select
                                                        size="large"
                                                        value={statutDocumentIds}
                                                        onChange={(value) => setStatutDocumentIds(value)}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        mode='multiple'
                                                        placeholder="Filtrer par statut document"
                                                        style={{ width: '100%' }}
                                                    >
                                                        {statutDocuments.map((statutDocument) => (
                                                            <Select.Option key={statutDocument.id} value={statutDocument.id}>
                                                                {statutDocument.nom}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={12} md={8} lg={8}>
                                                <div style={containerStyle}>
                                                    <div style={labelStyle}>Exploitant: </div>
                                                    <Select
                                                        size="large"
                                                        value={organisationIds}
                                                        onChange={(value) => setOrganisationIds(value)}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        mode='multiple'
                                                        placeholder="Filtrer par exploitant"
                                                        style={{ width: '100%' }}
                                                    >
                                                        {organisations.map((organisation) => (
                                                            <Select.Option key={organisation.id} value={organisation.id}>
                                                                {organisation.nom}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </Col>
                                        </>
                                    ) : null}
                                    <Col xs={24} sm={12} md={8} lg={8}>
                                        <div style={containerStyle}>
                                            <div style={labelStyle}>Catégorie document: </div>
                                            <Select
                                                size="large"
                                                value={categorieDocumentIds}
                                                onChange={(value) => setCategorieDocumentIds(value)}
                                                allowClear optionFilterProp="children"
                                                mode='multiple'
                                                placeholder="Filtrer par catégorie document"
                                                style={{ width: '100%' }}
                                            >
                                                {categorieDocuments.map((categorieDocument) => (
                                                    <Select.Option key={categorieDocument.id} value={categorieDocument.id}>
                                                        {categorieDocument.nom}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8}>
                                        <div style={containerStyle}>
                                            <div style={labelStyle}>Type dossier: </div>
                                            <Select
                                                size="large"
                                                value={typeDossierIds}
                                                onChange={(value) => setTypeDossierIds(value)}
                                                allowClear optionFilterProp="children"
                                                mode='multiple'
                                                placeholder="Filtrer par type dossier"
                                                style={{ width: '100%' }}
                                            >
                                                {typeDossiers.map((typeDossier) => (
                                                    <Select.Option key={typeDossier.id} value={typeDossier.id}>
                                                        {typeDossier.nom}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8}>
                                        <div style={containerStyle}>
                                            <div style={labelStyle}>Type document: </div>
                                            <Select
                                                size="large"
                                                value={typeDocumentIds}
                                                onChange={(value) => setTypeDocumentIds(value)}
                                                allowClear
                                                mode='multiple' optionFilterProp="children"
                                                placeholder="Filtrer par type document"
                                                style={{ width: '100%' }}
                                            >
                                                {typeDocuments.map((typeDocument) => (
                                                    <Select.Option key={typeDocument.id} value={typeDocument.id}>
                                                        {typeDocument.nom}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    }
                        dataSource={documents} loading={loading} columns={filteredColumns} scroll={{ x: 1300, y: 500 }}
                        paginationData={paginationData} loadMore={loadMore}
                    />
                </Spin>
            </>) : <UnauthorizedMessage />}
        </>
    )
}