import { DaoService } from "./DaoService";

export default class DemandeCorrectionDocumentService extends DaoService {

    static getInstance() {
        return super.getInstance('demandecorrectiondocument');
    }

    findByDocument(documentUid) {
        return DemandeCorrectionDocumentService.get(`${this.prefix}/${documentUid}/document`);
    }

    transmettreDocumentCorrige(demandeCorrectionDocumentUid) {
        return DemandeCorrectionDocumentService.post(`${this.prefix}/${demandeCorrectionDocumentUid}/transmettre-correction`);
    }

    findActiveByDocument(documentUid) {
        return DemandeCorrectionDocumentService.get(`${this.prefix}/${documentUid}/active`);
    }

}