import React from "react";
import { catchError } from "../../services/DaoService";
import Main from "../../components/layout/Main";
import PageTitle from "../../components/utils/PageTitle";
import { SettingFilled } from "@ant-design/icons";
import {
  Card,
  Col,
  Collapse,
  Result,
  Row,
  Select,
  Spin,
} from "antd";
import { useUserContext } from "../../providers/UserProvider";
import DomaineService from "./../../services/DomaineService";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";
import ChecklistModeleConfigItem from "../../components/checklistmodel/ChecklistModeleConfigItem";
import DomaineCategorieService from "../../services/DomaineCategorieService";

export default function ChecklistModeleConfigPage() {
  const [loading, setLoading] = React.useState(true);
  const [domaines, setDomaines] = React.useState([]);
  const [domaineCategories, setDomaineCategories] = React.useState([]);
  const { check } = useUserContext();
  const [items, setItems] = React.useState([]);
  const [activeKey, setActiveKey] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    DomaineService.getInstance()
      .all()
      .then((response) => {
        setDomaines(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDomaineChange = (domaine) => {
    setLoading(true);
    DomaineCategorieService.getInstance()
      .findByDomaine(domaine.key)
      .then((response) => {           
        setDomaineCategories(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (key) => {
    setActiveKey(key);
}

  React.useEffect(() => {
    if(!domaineCategories){
      return
    }
    setItems(
      domaineCategories.map((domaineCategorie) => {
        return {
          key: domaineCategorie.categorie_exploitant.code,
          label: domaineCategorie.categorie_exploitant.nom,
          children:
            activeKey[0] === domaineCategorie.categorie_exploitant.code? (
              <ChecklistModeleConfigItem domaineCategorie={domaineCategorie} />
            ) : (
              <LoadingPlaceholder />
            ),
        };
      })
    );
  }, [domaineCategories, activeKey]);

  return (
    <Main>
      <PageTitle
        title="Configuration des modèles de checklist"
        subTitle={
          "Paramétrer des modèles de checklist pour faciliter l'évaluation des documents"
        }
        icon={<SettingFilled />}
      >
      </PageTitle>
      {check("CONFIGURE-CHECKLIST-MODELS") ? (
        <Spin spinning={loading}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card>
                <Card.Meta title="Sélectionner un domaine" />
                <hr className="py-1 bg-primary" />
                <Select
                  onChange={(value, option) => handleDomaineChange(option)}
                  className="w-100"
                  placeholder="Veuillez selectionner un domaine"
                  showSearch
                  optionFilterProp="children"
                >
                  {domaines.map((domaine) => (
                    <Select.Option key={domaine.uid} value={domaine.id}>
                      {domaine.nom}
                    </Select.Option>
                  ))}
                </Select>
                {domaineCategories ? (
                  <>
                    <hr className="py-1 bg-primary" />
                    <Collapse
                      accordion
                      items={items}
                      defaultActiveKey={activeKey}
                      onChange={onChange} 
                    />{" "}
                  </>
                ) : null}
              </Card>
            </Col>
          </Row>
        </Spin>
      ) : (
        <Result
          status="403"
          title="403"
          subTitle="Vous n'avez pas l'autorisation d'accéder à cette section"
        />
      )}
    </Main>
  );
}
