import React, { useState } from 'react';
import { Card, Typography, Avatar, Button, Popconfirm, Space } from 'antd';
import { MailOutlined, UserOutlined, FieldTimeOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './DocumentMemberListItem.module.scss';
import Moment from 'react-moment';
import { useUserContext } from '../../providers/UserProvider';
import Toast from '../../helpers/Toast';
import { catchError } from '../../services/DaoService';
import DocumentMemberService from '../../services/DocumentMemberService';

const { Text } = Typography;

export default function DocumentMemberListItem ({ item, onDelete, document }) {
    const { currentUser } = useUserContext();
    const [isDeleting, setIsDeleting] = useState(false);

    const removeMember = (member) => {
        setIsDeleting(true);
        DocumentMemberService.getInstance()
            .remove(item.id)
            .then((response) => {
                Toast.success(response.message);
                onDelete(member);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    };

    return (
        <div className={styles.documentMemberListItem}>
            {item ? (
                <Card classNames={{body: 'p-2'}}
                    hoverable
                    bordered={false}
                >
                    <div className={styles.cardHeader}>
                        <Avatar size={48} icon={<UserOutlined />} className={styles.avatar} />
                        <div className={styles.cardContent}>
                            <Typography.Title level={5} className={styles.userName}>
                                {item.user.name}
                            </Typography.Title>
                            <Space direction="vertical" size={0} className={styles.documentMemberListItemDetails}>
                                <Text className={styles.email}>
                                    <MailOutlined />  &nbsp; <a href={`mailto:${item.user.email}`}>{item.user.email}</a>
                                </Text>
                                <Text className={styles.createdAt}>
                                    <FieldTimeOutlined /> &nbsp; Créé le &nbsp; <Moment format="DD/MM/YYYY">{item.user?.created_at}</Moment>
                                </Text>
                            </Space>
                        </div>
                        {(currentUser?.is_admin || (currentUser?.id===document.responsable_id && !document.closed)) && (
                            <Popconfirm
                                okType='danger'
                                title="Êtes-vous sûr de vouloir supprimer ce membre du document ?"
                                onConfirm={() => removeMember(item)}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button
                                    loading={isDeleting}
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    size="small"
                                    className={styles.buttonDelete}
                                />
                            </Popconfirm>
                        )}
                    </div>
                </Card>
            ) : null}
        </div>
    );
};