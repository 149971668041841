import React from "react";
import { Button, Card, Col, Popconfirm, Row, Tabs, Typography } from "antd";
import { ContactsOutlined, DeleteOutlined, EditFilled, UserAddOutlined } from "@ant-design/icons";
import styles from './ContactDetails.module.scss';
import { catchError } from "../../services/DaoService";
import { useUserContext } from "../../providers/UserProvider";
import { NavLink, useNavigate } from "react-router-dom";
import ContactEdit from "./ContactEdit";
import ContactService from "../../services/ContactService";
import PageTitle from "../utils/PageTitle";
import UserService from "../../services/UserService";
import Toast from '../../helpers/Toast';
import ActivityFeed from "../Activity/ActiviteFeed";
import LogTraces from "../logtrace/LogTraces";
import PieceJointeList from "../piecejointe/PieceJointeList";
import PieceJointeService from "../../services/PieceJointeService";

const { Title, Text } = Typography;

export default function ContactDetails({ contactUid }) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { check } = useUserContext();
  const navigate = useNavigate();
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [selectedUid, setSelectedUid] = React.useState(null);
  const [contact, setContact] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [creatingAccount, setCreatingAccount] = React.useState(false);


  React.useEffect(() => {
    setLoading(true);
    if (!contactUid) return;
    ContactService.getInstance()
      .find(contactUid)
      .then(response => {
        setContact(response.data);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        setLoading(false);
      });
  }, [contactUid]);

  const removeContact = (contact) => {
    setIsDeleting(true);
    ContactService.getInstance()
      .remove(contact.uid)
      .then(() => {
        navigate(`/organisation/${contact.organisation.uid}`);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setIsDeleting(false);
      });
  }

  const setItemToEdit = (contact) => {
    setSelectedUid(contact.uid);
    setEditModalVisible(true);
  }
  const handleContactUpdate = (contact) => {
    setContact(contact);
    setEditModalVisible(false);
  }
  const createAccount = () => {
    let user = {
      name: contact.name,
      email: contact.email,
      contact_id: contact.id,
      telephone: contact.telephone,
    }
    setCreatingAccount(true);
    UserService.getInstance()
      .create(user)
      .then((response) => {
        Toast.success(response.message);
        setContact(contact => ({ ...contact, user_id: response.data.id }));
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setCreatingAccount(false);
      });
  }

  const goToAccount = async () => {
    const response = await ContactService.getInstance().find(contactUid);
    if (response.data.user) {
      navigate(`/parametrage/user/${response.data.user.uid}`);
    }
  }

  const tabItems = [
    check('MANAGE-DATA-OPERATIONS-HISTORY') && contact && {
      key: "trace-logs",
      label: "Historique des modifications",
      children: <LogTraces target={'contact'} parentId={contact?.id} />
    },
    check('VIEW-CONTACT') && contact && {
      key: "attachments",
      label: "Fichiers & Notes",
      children:
        <PieceJointeList canSee={check('VIEW-CONTACT-ATTACHMENTS')}
          canAdd={check('ADD-CONTACT-ATTACHMENTS')} candRemove={check('DELETE-CONTACT-ATTACHMENTS')}
          parentType={PieceJointeService.TYPES.CONTACT} parentId={contact?.id} />
    },
  ];

  return (
    <>
      <ContactEdit onUpdate={handleContactUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
      <PageTitle icon={<ContactsOutlined />} title={contact?.name} subTitle={contact?.email}
        backPageTitle="Liste des contacts" backPageLink="/contact" canSeeBackPageLink={check('VIEW-CONTACTS')}
      >
        {check('EDIT-CONTACT') ? <Button onClick={() => setItemToEdit(contact)} icon={<EditFilled />} className="btn btn-warning">Modifier</Button> : null}
        {check('DELETE-CONTACT') ?
          <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer ce contact?" onConfirm={() => removeContact(contact)} okText="Confirmer" cancelText="Annuler">
            <Button loading={isDeleting}
              type="primary" disabled={!contact?.organisation?.accepted}
              danger
              icon={<DeleteOutlined />}
              className="btn btn-danger"
            >Supprimer</Button>
          </Popconfirm>
          : null}
        {(check('ADD-USER') && !contact?.user_id && contact) ?
          <Button loading={creatingAccount} title='Créer un compte utilisateur' onClick={createAccount} icon={<UserAddOutlined />} className={styles.createAccountButton}>
            Créer compte
          </Button> : null
        }
        {(check('VIEW-USER') && contact?.user_id) ?
          <Button title='voir le compte utilisateur' onClick={goToAccount} icon={<UserAddOutlined />} className={styles.createAccountButton}>
            Voir compte
          </Button> : null
        }
      </PageTitle>
      <Row gutter={[24, 16]}>
        <Col xs={24} lg={16}>
          <Row gutter={[24, 16]}>
            <Col span={24}>
              <Card loading={loading} className={styles.card} bordered>
                <div className={styles.info}>
                  {contact ? (
                    <>
                      <div className={styles.item}>
                        <Title level={5} className={styles.label}>Prénoms</Title>
                        <Text>{contact.prenoms}</Text>
                      </div>
                      <div className={styles.item}>
                        <Title level={5} className={styles.label}>Nom</Title>
                        <Text>{contact.nom}</Text>
                      </div>
                      <div className={styles.item}>
                        <Title level={5} className={styles.label}>Fonction</Title>
                        <Text>{contact.fonction}</Text>
                      </div>
                      <div className={styles.item}>
                        <Title level={5} className={styles.label}>Email</Title>
                        <NavLink to={`mailto:${contact.email}`} style={{ textDecoration: 'none' }}>
                          {contact.email}
                        </NavLink>
                      </div>
                      <div className={styles.item}>
                        <Title level={5} className={styles.label}>Téléphone</Title>
                        <NavLink to={`tel:${contact.telephone}`} style={{ textDecoration: 'none' }}>
                          {contact.telephone}
                        </NavLink>
                      </div>
                      <div className={styles.item}>
                        <Title level={5} className={styles.label}>Exploitant</Title>
                        {check('VIEW-ORGANIZATION') ?
                          <NavLink to={`/parametrage/exploitant/${contact.organisation.uid}`} style={{ textDecoration: 'none' }}>
                            {contact.organisation.nom}
                          </NavLink>
                          : <Text>{contact.organisation.nom}</Text>}
                      </div>
                    </>
                  ) : (
                    <Text>Contact non disponible</Text>
                  )}
                </div>
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                  <Tabs items={tabItems} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8}>
          <ActivityFeed target={'contact'} parentId={contact?.id} />
        </Col>
      </Row>
    </>
  );
}
