import { Button, Card, Form, Input, Modal, Spin } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import ContactService from "../../services/ContactService";
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default function ContactNew({ visible, onCreate, onCancel, organisationId }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [creating, setCreating] = React.useState(false);

  const gotoContactDetails = (organisation) => {
    navigate(`/contact/${organisation.uid}`);
  }

  const handleSave = async (isRedirection) => {
    setCreating(true);
    try {
      const values = await form.validateFields();
      values.organisation_id = organisationId;
      const contact = await ContactService.getInstance().create(values);
      Toast.success(contact.message);
      form.resetFields();
      onCreate(contact.data);
      if (isRedirection) {
        gotoContactDetails(contact.data);
      }
    } catch (error) {
      catchError(error);
    } finally {
      setCreating(false);
    }
  };


  const onFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  return (
    <Modal open={visible} onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>
          Annuler
        </Button>,
        <Button type="primary" loading={creating} onClick={() => handleSave(true)}>
          Enregistrer et Afficher
        </Button>,
        <Button type="primary" loading={creating} onClick={() => handleSave(false)}>
          Enregistrer et Créer
        </Button>,
      ]}>
      <Card>
        <Spin spinning={creating}>
          <Form
            form={form}
            name="newOrganisationForm"
            layout="vertical"
            onFinishFailed={onFailed}
            initialValues={{
              enabled: true,
            }}
          >
            <Form.Item
              label="Prenom"
              name="prenoms"
              rules={[
                { required: true, message: 'Ce champ est obligatoire!' },
                { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Nom"
              name="nom"
              rules={[
                { required: true, message: 'Ce champ est obligatoire!' },
                { min: 3, message: 'Le code doit contenir au moins 3 caractères!' }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Fonction"
              name="fonction"
              rules={[
                { required: true, message: 'Ce champ est obligatoire!' },
                { min: 3, message: 'Le code doit contenir au moins 3 caractères!' }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Veuillez saisir l\'adresse email!' },
                { type: 'email', message: 'Veuillez saisir une adresse email valide!' }
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Téléphone"
              name="telephone"
              rules={[
                { required: true, message: 'Ce champ est obligatoire!' }
              ]}
            >
              <PhoneInput defaultCountry="SN"
                placeholder="Saisir le numéro de téléphone"/>
            </Form.Item>

          </Form>
        </Spin>
      </Card>
    </Modal>
  );

}