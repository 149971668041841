import { DaoService } from "./DaoService";

export default class ChecklistModeleService extends DaoService {
  static getInstance() {
    return super.getInstance("checklistmodele");
  }

  search(data) {
    return ChecklistModeleService.post(`${this.prefix}/search`, data);
  }

  async findByDomaineCategorieAndTypeDossier(
    domaineCategorieId,
    typeDossierUid
  ) {
    return ChecklistModeleService.get(
      `${this.prefix}/${domaineCategorieId}/${typeDossierUid}/domainecategoriedossier`
    );
  }

  all() {
    return ChecklistModeleService.get(`${this.prefix}/all`);
  }
}
