import { Button, Card, Form, Select } from "antd";
import React from "react";
import DomaineCategorieService from "../../services/DomaineCategorieService";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import styles from "./DomaineCategorieConfigItem.module.scss"; // Import du fichier SCSS
import { SettingOutlined } from "@ant-design/icons";

const { Option } = Select;

export default function DomaineCategorieConfigItem({ domaineCategorieItem, exploitants }) {
    const [selectedExploitantIds, setSelectedExploitantIds] = React.useState(domaineCategorieItem.selectedExploitantIds);
    const [linking, setLinking] = React.useState(false);

    const handleChange = (value) => {
        setSelectedExploitantIds(value);
    };

    const save = () => {
        setLinking(true);
        DomaineCategorieService.getInstance().linkExploitants(domaineCategorieItem.domaineCategorie.id, { exploitantIds: selectedExploitantIds })
            .then(response => {
                Toast.success(response.message);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLinking(false);
            });
    };

    return (
        <Card extra={<SettingOutlined />} classNames={{body: 'p-0'}} key={domaineCategorieItem.domaineCategorie.uid} title={domaineCategorieItem.domaineCategorie.categorie_exploitant.nom} className={styles.categorieCard}>
            <Form layout="vertical" onFinish={save}>
                <Form.Item
                    name="exploitants"
                    label="Exploitants"
                    initialValue={selectedExploitantIds}
                    rules={[{ required: true, message: 'Veuillez sélectionner au moins un exploitant' }]}
                >
                    <Select
                        mode="multiple"
                        showSearch
                        optionFilterProp="children"
                        placeholder="Sélectionnez les exploitants"
                        onChange={handleChange}
                        loading={linking}
                        className={styles.selectInput}
                    >
                        {exploitants.map(exploitant => (
                            <Option key={exploitant.uid} value={exploitant.id}>{exploitant.nom}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={linking} className={styles.submitButton}>
                        Enregistrer
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}
