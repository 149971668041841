import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Descriptions, Popconfirm, Result, Row, Tabs } from "antd";
import { DeleteFilled, FolderOpenFilled, ReloadOutlined } from "@ant-design/icons";
import PageTitle from "../../components/utils/PageTitle";
import Main from "../../components/layout/Main";
import { DocumentService } from "../../services/DocumentService";
import DocumentEvaluationForm from "../../components/documentevaluation/DocumentEvaluationForm";
import DocumentEvaluationService from "../../services/DocumentEvaluationService";
import { catchError } from "../../services/DaoService";
import DocumentEvaluationChecklist from "../../components/documentevaluation/DocumentEvaluationChecklist";
import Toast from "../../helpers/Toast";
import { useUserContext } from "../../providers/UserProvider";
import DemandeCorrectionDocumentActive from "../../components/demandecorrectiondocument/DemandeCorrectionDocumentActive";
import DemandeCorrectionDocumentList from "../../components/demandecorrectiondocument/DemandeCorrectionDocumentList";
import LogTraces from "../../components/logtrace/LogTraces";
import DocumentEvaluationMemberList from "../../components/documentevaluationmember/DocumentEvaluationMemberList";
import { InlineEditableInput } from "../../components/utils/InlineEditableInput";
import ActivityFeed from "../../components/Activity/ActiviteFeed";
import PieceJointeList from "../../components/piecejointe/PieceJointeList";
import PieceJointeService from "../../services/PieceJointeService";
import { usePusher } from "../../providers/PusherProvider";

export default function DocumentEvaluationPage() {
  const [document, setDocument] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { uid } = useParams();
  const [documentEvaluation, setDocumentEvaluation] = React.useState(null);
  const { check, currentUser } = useUserContext();
  const { pusher } = usePusher();

    React.useEffect(() =>   {
        if (!pusher) {
            return;
        }
        pusher.subscribe(`documentEvaluation.${documentEvaluation?.uid}`).bind('updated', function (data) {
          setDocumentEvaluation(documentEvaluation => ({ ...documentEvaluation, ...data.updatedData }));
        });
        return function () {
            pusher.unsubscribe(`documentEvaluation.${documentEvaluation?.uid}`);
        }
    }, [pusher, documentEvaluation]);

  const init = useCallback(function () {
    setLoading(true);
    DocumentService.getInstance()
      .find(uid)
      .then(response => {
        setDocument(response.data);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        setLoading(false);
      });
  }, [uid]);

  React.useEffect(function () {
    if (!document) {
      return;
    }

    // rechercher le doc evaluation si document has evaluation
    if (document.has_evaluation) {
      setLoading(true);
      DocumentEvaluationService.getInstance().findByDocument(document.uid)
        .then(response => {
          const documentEvaluation = response.data;
          // merge document in documentEvaluation
          documentEvaluation.document = document;
          setDocumentEvaluation(response.data);
        }).catch(error => {
          catchError(error);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [document]);

  React.useEffect(function () {
    init();
  }, [init]);

  const removeDocumentEvaluation = () => {
    setLoading(true);
    DocumentEvaluationService.getInstance().remove(documentEvaluation.uid)
      .then(response => {
        setDocumentEvaluation(null);
        setDocument({ ...document, has_evaluation: false });
        Toast.success(response.message);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleCreate = (documentEvaluation) => {
    setDocumentEvaluation(documentEvaluation);
    setDocument({ ...document, ...{ has_evaluation: true } });
  }

  const handleFieldChange = (value, fieldName, record) => {
    // merge the new value with the old record
    const newRecord = { ...record, ...{ [fieldName]: value } };
    setLoading(true);
    DocumentEvaluationService.getInstance().update(record.uid, newRecord)
      .then(response => {
        setDocumentEvaluation(newRecord);
        Toast.success(response.message);
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setLoading(false);
      });
  }

  const tabItems = [
    {
      key: 'members',
      label: 'Equipe évaluateurs',
      children: <DocumentEvaluationMemberList documentEvaluation={documentEvaluation} />
    },
    {
      key: 'checklist',
      label: 'Checklist & Evaluation',
      children: <DocumentEvaluationChecklist documentEvaluation={documentEvaluation} refreshDocumentEvaluation={init} />
    },
    {
      key: "history-submissions-ecarts",
      label: "Demandes de correction",
      children: <DemandeCorrectionDocumentList document={document} />
    },
    {
      key: "attachments",
      label: "Fichiers & Notes",
      children:
        <PieceJointeList canSee={check('VIEW-EVALUATION-ATTACHMENTS')}
          canAdd={check('ADD-EVALUATION-ATTACHMENTS') && !document?.closed} candRemove={check('DELETE-EVALUATION-ATTACHMENTS') && !document?.closed}
          parentType={PieceJointeService.TYPES.DOCUMENTEVALUATION} parentId={documentEvaluation?.id} />
    },
    check('MANAGE-DATA-OPERATIONS-HISTORY') && document && {
      key: "trace-logs",
      label: "Logs",
      children: <LogTraces target={'document'} parentId={document?.id} />
    },
  ];

  return (
    <Main>
      <PageTitle icon={<FolderOpenFilled />} title={
        documentEvaluation ? (
          <InlineEditableInput value={documentEvaluation?.nom} record={documentEvaluation} fieldName="nom" onChange={handleFieldChange} canEdit={(check('EVALUATE-DOCUMENT') && !document?.closed) || currentUser?.is_admin}>
            {documentEvaluation?.nom}
          </InlineEditableInput>
        ) : document?.nom
      } subTitle={<>
        <span>Espace d'évaluation du document - {document?.document_number}</span>
        (<span className={`${!documentEvaluation?.validated_at ? 'text-danger' : 'text-success'}`}>
          {!documentEvaluation?.validated_at ? 'En cours d\'évaluation' : 'Validé'}
        </span>)
      </>}
        backPageTitle="Détails document" backPageLink={`/document/${document?.uid}`} canSeeBackPageLink={check('VIEW-DOCUMENT')}
      >
        {documentEvaluation && (currentUser?.is_admin || (currentUser?.id === document?.responsable_id && !document.closed && !documentEvaluation.validated)) && (<Popconfirm okType='danger' title="Voulez-vous vraiment supprimer ce document?" onConfirm={() => removeDocumentEvaluation(document)} okText="Confirmer" cancelText="Annuler">
          <Button danger icon={<DeleteFilled />}>Supprimer</Button>
        </Popconfirm>)}
        <Button loading={loading} icon={<ReloadOutlined />} onClick={init}>Rafraichir</Button>
      </PageTitle>
      {!document?.has_evaluation ? (
        <>
          {(document?.responsable_id === currentUser?.id || currentUser?.is_admin) ?
            <DocumentEvaluationForm document={document} onCreate={handleCreate} /> :
            <Card>
              <Result status="403" title="403" subTitle="Vous n'êtes pas autorisé à initier cette évaluation, veuillez vous réferer au responsable..." />
            </Card>
          }
        </>
      ) : (
        <>
          {documentEvaluation && (
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <Card>
                  <Descriptions>
                    <Descriptions.Item label="Doc. Numéro">{document?.document_number}</Descriptions.Item>
                    <Descriptions.Item label="Date d'Application">{documentEvaluation.date_application ? documentEvaluation.date_application : 'Non spécifiée'}</Descriptions.Item>
                    <Descriptions.Item label="Observations">{documentEvaluation.observations ? documentEvaluation.observations : 'Aucune observation'}</Descriptions.Item>
                    <Descriptions.Item label="Créé par">{documentEvaluation.created_by.name}</Descriptions.Item>
                    <Descriptions.Item label="Créé le">{new Date(documentEvaluation.created_at).toLocaleString()}</Descriptions.Item>
                    <Descriptions.Item label="Mis à jour le">{new Date(documentEvaluation.updated_at).toLocaleString()}</Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
              {documentEvaluation?.has_active_demande_correction && (<Col span={24}>
                <Card>
                  <DemandeCorrectionDocumentActive document={document} />
                </Card>
              </Col>)}
              <Col span={16}>
                <Card>
                  <Tabs animated items={tabItems} />
                </Card>
              </Col>
              <Col xs={24} lg={8}>
                <ActivityFeed target={'documentevaluation'} parentId={documentEvaluation?.id} />
              </Col>
            </Row>
          )}
        </>
      )}
    </Main>
  );
}