import React from "react";
import { Button, Form, Select } from "antd";
import { catchError } from "../../services/DaoService";
import DomaineCategorieDossierService from "./../../services/DomaineCategorieDossierService";
import Toast from "../../helpers/Toast";

const { Option } = Select;

export default function ChecklistModeleConfigItemForm({
  checklistIds,
  checklists,
  domaineCategorieId,
  typeDossierId,
}) {
  const [selectedChecklistModeleIds, setSelectedChecklistModeleIds] =React.useState(checklistIds);
  const [linking, setLinking] = React.useState(false);

  const handleChecklistModeleChange = (value) => {
    setSelectedChecklistModeleIds(value);
  };

  const save = () => {
     setLinking(true);
        DomaineCategorieDossierService.getInstance().linkChecklistModeles({checklistModeleIds: selectedChecklistModeleIds, typeDossierId: typeDossierId, domaineCategorieId:domaineCategorieId})
            .then((response) => {                
                Toast.success(response.message);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLinking(false);
            }); 
  };

  return checklists ? (
    <>
      <Form layout="vertical" onFinish={save}>
        <Form.Item
          name="checklistModeleIds"
          label="Checklists Modeles"
          initialValue={checklistIds}
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner des checklists modeles",
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            mode="multiple"
            placeholder="Sélectionner des checklists modeles"
            value={selectedChecklistModeleIds}
            onChange={handleChecklistModeleChange}
            optionLabelProp="label"
          >
            {checklists.map((checklist) => (
              <Option
                key={checklist.id}
                value={checklist.id}
                label={checklist.nom}
              >
                {checklist.nom}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button loading={linking} type="primary" htmlType="submit">
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </>
  ) : null;
}
