import React, { useState, useEffect } from 'react';
import { Table, Spin, Typography } from 'antd';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';
import styles from './UsersAndContactsByExploitant.module.scss';
import { ResponsiveContainer } from 'recharts';

const { Title } = Typography;

const UsersAndContactsByExploitant = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DashboardService.getInstance()
            .countUsersAndContactsByExploitant()
            .then(response => {
                if (response.error === 0) {
                    setData(response.data);
                } else {
                    catchError(response.message);
                }
            })
            .catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = [
        {
            title: 'Exploitant',
            dataIndex: 'organisation',
            key: 'organisation',
            width: 200,
        },
        {
            title: 'Contacts',
            dataIndex: 'contacts',
            key: 'contacts',
            align: 'center',
            width: 150,
        },
        {
            title: 'Utilisateurs',
            dataIndex: 'users',
            key: 'users',
            align: 'center',
            width: 150,
        },
    ];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className={styles.tableContainer}>
            <Title level={4} className={styles.title}>Utilisateurs et Contacts par Exploitant</Title>
            <ResponsiveContainer width="100%" height={500}>
                <Table scroll={{ x: 500, y: true }}
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.organisation}
                    pagination={false}
                    bordered
                />
            </ResponsiveContainer>
        </div>
    );
};

export default UsersAndContactsByExploitant;
