


import { Button, Col, Divider, Input, Popconfirm, Row, Select, Space, Spin, Tag } from "antd";
import { BuildOutlined, DeleteFilled, EditFilled, EyeOutlined, PlusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import { useUserContext } from "../../providers/UserProvider";
import { useNavigate } from "react-router-dom";
import { catchError } from "../../services/DaoService";
import Main from "../../components/layout/Main";
import PageTitle from "../../components/utils/PageTitle";
import InfiniteTable from "../../components/utils/InfiniteTable";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import QueueService from "../../services/QueueService";
import QueueEdit from "../../components/queue/QueueEdit";
import QueueNew from "../../components/queue/QueueNew";

export default function QueueListPage() {
    const [queues, setQueues] = React.useState([]);
    const [selectedUid, setSelectedUid] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [editModalVisible, setEditModalVisible] = React.useState(false);

    const [hasMore, setHasMore] = React.useState(true);
    const { check } = useUserContext();

    const [searchText, setSearchText] = React.useState(null);
    const [statut, setStatut] = React.useState('tout');
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    const [newModalVisible, setNewModalVisible] = React.useState(false);


    const navigate = useNavigate();

    const setItemToEdit = (record) => {
        setSelectedUid(record.uid);
        setEditModalVisible(true);
    }
    const setItemToView = (record) => {
        setSelectedUid(record.uid);
        navigate(`/parametrage/queue-config/${record.uid}`);
    }


    const handleQueueUpdate = (queue) => {
        setQueues(queues.map(q => q.uid === queue.uid ? queue : q));
        setEditModalVisible(false);
    }

    const handleNewOrganisation = (queue) => {
        setQueues([...queues, queue]);
    }

    const init = useCallback(() => {
        setLoading(true);
        QueueService.getInstance()
            .search({ searchText, statut })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setQueues(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, statut, setLoading, setPaginationData]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (paginationData) {
            setQueues(queues => [...new Map([...queues, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const removeQueue = (queue) => {
        setIsDeleting(true);
        QueueService.getInstance()
            .remove(queue.uid)
            .then(() => {
                setQueues(queues.filter(q => q.id !== queue.id));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    const columns = [
        {
            title: "Nom",
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            width: 100,
            ellipsis: true
        },
        {
            title: "Code",
            dataIndex: 'code',
            key: 'code',
            sorter: (a, b) => a.code.localeCompare(b.code),
            width: 100,
            ellipsis: true
        },
        {
            title: "Decsription",
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            width: 120,
            ellipsis: true,
            render: (description) => description ? description : <span className="badge bg-secondary">Non renseigné</span>
        },
        {
            title: 'Statut',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (enabled) => enabled ? <Tag color="green">Activé</Tag> : <Tag color="red">Désactivé</Tag>,
            width: 100,
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (record) => (
                <Space>
                    {check('VIEW-QUEUE') ? <Button onClick={() => setItemToView(record)} icon={<EyeOutlined />} className="btn btn-primary"></Button> : null}
                    {check('EDIT-QUEUE') ? <Button onClick={() => setItemToEdit(record)} icon={<EditFilled />} className="btn btn-warning"></Button> : null}
                    {check('DELETE-QUEUE') ?
                        <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer cette file d'attente ?" onConfirm={() => removeQueue(record)} okText="Confirmer" cancelText="Annuler">
                            <Button loading={isDeleting} icon={<DeleteFilled />} className="btn btn-danger"></Button>
                        </Popconfirm>
                        : null}
                </Space>
            )
        }
    ].filter(column => column !== null);

    const labelStyle = {
        marginRight: '10px',
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    const loadMore = () => {
        if (hasMore && !loading) {
            setLoading(true);
            QueueService.post(paginationData.next_page_url, { searchText, statut })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    return (
        <Main>
            {check('VIEW-QUEUES') ? (<>
                <QueueNew onCreate={handleNewOrganisation} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} />
                <QueueEdit onUpdate={handleQueueUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
                <PageTitle title="Liste des files d'attente" icon={<BuildOutlined />} subTitle="Accéder et configurer la liste des files d'attente...">
                    {
                        check('ADD-QUEUE') ? <Button onClick={() => setNewModalVisible(true)} icon={<PlusCircleOutlined />} type="primary">Nouvelle file d'attente</Button> : null
                    }
                    <Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>
                </PageTitle>
                <Spin spinning={loading}>
                    <InfiniteTable title={
                        <Row gutter={[24, 16]}>
                            <Col span={24}>
                                <Row gutter={[16, 16]} justify={'center'}>
                                    <Col span={24}>
                                        <Divider>Filtres rapides</Divider>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                        <Input.Search size="large" value={searchText} onInput={
                                            (e) => {
                                                setSearchText(e.target.value);
                                            }} placeholder="Rechercher une file d'attente" />
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                        <div style={containerStyle}>
                                            <div style={labelStyle}>Statut:</div>
                                            <Select value={statut} onChange={
                                                (value) => {
                                                    setStatut(value);
                                                }
                                            } placeholder="Filtrer par statut" style={{ width: '100%' }}>
                                                <Select.Option value="tout">Tous</Select.Option>
                                                <Select.Option value="actif">Activé</Select.Option>
                                                <Select.Option value="inactif">Désactivé</Select.Option>
                                            </Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                        dataSource={queues} loading={loading} columns={columns} scroll={{ x: 1300, y: 500 }}
                        paginationData={paginationData} loadMore={loadMore}
                    />
                </Spin>
            </>) : <UnauthorizedMessage />}
        </Main>
    )
} 