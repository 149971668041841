import { DaoService } from "./DaoService";

export default class CategorieExploitantService extends DaoService {
    static getInstance() {
        return super.getInstance('categorieexploitant')
    }

    all() {
        return CategorieExploitantService.get(`${this.prefix}/all`);
    }
}