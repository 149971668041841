import { Card, Form, Input, Modal } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import ContactService from "../../services/ContactService";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";


export default function ContactEdit({ visible, onUpdate, onCancel, uid }) {
    const [form] = Form.useForm();
    const [creating, setCreating] = React.useState(false);
    const [contact, setContact] = React.useState(null);

    React.useEffect(() => {
        if (!visible) return;
        if (uid) {
            ContactService.getInstance()
                .find(uid)
                .then(response => {
                    setContact(response.data);
                    form.setFieldsValue(response.data);
                }).catch(error => {
                    console.error(error);
                });
        }
    }, [uid, form, visible]);

    const onFinish = (values) => {
        setCreating(true);
        ContactService.getInstance()
            .update(contact.uid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setCreating(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }


    return (
        <Modal open={visible} okText="Mettre à jour" okType="primary" cancelText="Annuler" onOk={form.submit} onCancel={onCancel}>
            <Card loading={creating}>
                <Form
                    form={form} name="editOrganisationForm"
                    layout="vertical"
                    onFinish={onFinish} onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Prenom"
                        name="prenoms"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' },
                            { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Nom"
                        name="nom"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' },
                            { min: 3, message: 'Le code doit contenir au moins 3 caractères!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Fonction"
                        name="fonction"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' },
                            { min: 3, message: 'Le code doit contenir au moins 3 caractères!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Veuillez saisir l\'adresse email!' },
                            { type: 'email', message: 'Veuillez saisir une adresse email valide!' }
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item
                        label="Téléphone"
                        name="telephone"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' },
                        ]}
                    >
                        <PhoneInput defaultCountry="SN"
                placeholder="Saisir le numéro de téléphone"/>
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
}