import React, { useCallback, useState } from "react";
import DemandeCorrectionDocumentService from "../../services/DemandeCorrectionDocumentService";
import { catchError } from "../../services/DaoService";
import { Button, Card, List, Modal } from "antd";
import DemandeCorrectionDocumentListItem from "./DemandeCorrectionDocumentListItem";
import { ArrowDownOutlined } from "@ant-design/icons";
import DemandeCorrectionDocumentDetailsModal from "./DemandeCorrectionDocumentDetailsModal";
import LogTraces from '../../components/logtrace/LogTraces';

export default function DemandeCorrectionDocumentList({ document }) {
    const [demandeCorrectionDocuments, setDemandeCorrectionDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationData, setPaginationData] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedDemandeCorrectionDocument, setSelectedDemandeCorrectionDocument] = useState(null);
    const [logTracesVisible, setLogTracesVisible] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);

    const init = useCallback(() => {
        if (!document) {
            return;
        }
        setLoading(true);
        DemandeCorrectionDocumentService.getInstance()
            .findByDocument(document.uid)
            .then((response) => {
                setPaginationData(response.data);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [document]);

    React.useEffect(() => {
        init();
    }, [init]);

    const loadMore = () => {
        if (hasMore) {
            setLoading(true);
            DemandeCorrectionDocumentService.get(paginationData.next_page_url)
                .then((response) => {
                    if (!response?.data) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    React.useEffect(() => {
        if (!paginationData) {
            return;
        }
        setHasMore(paginationData.next_page_url !== null);
        setDemandeCorrectionDocuments(demandeCorrectionDocuments=>{
            const uniqueDemandes = paginationData.data.filter(demande => !demandeCorrectionDocuments.find(item => item.uid === demande.uid));
            return [...demandeCorrectionDocuments, ...uniqueDemandes];
        });
    }, [paginationData]);

    const handleDelete = (demandeCorrectionDocument) => {
        setDemandeCorrectionDocuments(demandeCorrectionDocuments.filter(item => item.uid !== demandeCorrectionDocument.uid));
    }

    const setItemToView = (demandeCorrectionDocument) => {
        setSelectedDemandeCorrectionDocument(demandeCorrectionDocument);
        setShowDetailsModal(true);        
    }

    const handleLogTracesClick = (demande) => {
        setSelectedModel(demande);
        setLogTracesVisible(true);
    };

    return (
        <>
            <Card>
                <List
                    loading={loading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={demandeCorrectionDocuments}
                    renderItem={item => (
                        <DemandeCorrectionDocumentListItem 
                            onDelete={handleDelete} 
                            key={item.id} 
                            item={item} document={document}
                            setItemToView={setItemToView} 
                            onLogTracesClick={handleLogTracesClick} // Passez la fonction au composant enfant
                        />
                    )}
                />
            </Card>
            <div className='d-flex justify-content-center'>
                <Button icon={<ArrowDownOutlined />} disabled={!hasMore} loading={loading} type="primary" onClick={loadMore} style={{ marginTop: '20px' }}>
                    Voir plus
                </Button>
            </div>
            <DemandeCorrectionDocumentDetailsModal
                demandeCorrectionDocument={selectedDemandeCorrectionDocument} 
                setDemandeCorrectionDocument={setSelectedDemandeCorrectionDocument}
                visible={showDetailsModal} 
                onClose={() => setShowDetailsModal(false)} 
            />

            <Modal
                title="Historique des Logs"
                open={logTracesVisible}
                onCancel={() => setLogTracesVisible(false)}
                footer={null}
                width="80%"
            >
                {selectedModel && (
                    <LogTraces target="demandecorrectiondocument" parentId={selectedModel.id} />
                )}
            </Modal>
        </>
    );
}
