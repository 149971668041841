import { DaoService } from "./DaoService";

export default class ChecklistModeleGroupItemService extends DaoService {
    static getInstance() {
        return super.getInstance('checklistmodelegroupitem');
    }

    async findByChecklistModeleGroupe(uid) {
        return ChecklistModeleGroupItemService.get(`${this.prefix}/${uid}/checklistmodelegroup`);
    }
}