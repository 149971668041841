import { Card, Form, Input, Modal, Switch } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import QueueService from "../../services/QueueService";
const { TextArea } = Input;


export default function QueueEdit({ visible, onUpdate, onCancel, uid }) {
    const [form] = Form.useForm();
    const [creating, setCreating] = React.useState(false);
    const [queue, setQueue] = React.useState(null);

    React.useEffect(() => {
        if (!visible) return;
        if (uid) {
            QueueService.getInstance()
                .find(uid)
                .then(response => {
                    setQueue(response.data);
                    form.setFieldsValue(response.data);
                }).catch(error => {
                    console.error(error);
                });
        }
    }, [uid, form, visible]);

    const onFinish = (values) => {
        setCreating(true);
        QueueService.getInstance()
            .update(queue.uid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setCreating(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }


    return (
        <Modal open={visible} okText="Mettre à jour" okType="primary" cancelText="Annuler" onOk={form.submit} onCancel={onCancel}>
            <Card loading={creating}>
                <Form
                    form={form} name="editQueueForm"
                    layout="vertical"
                    onFinish={onFinish} onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Nom"
                        name="nom"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' },
                            { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' },
                            { min: 3, message: 'Le code doit contenir au moins 3 caractères!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"

                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Statut"
                        name="enabled"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
}