import { DaoService } from "./DaoService";
export default class DomaineCategorieDossierService extends DaoService {
  static getInstance() {
    return super.getInstance("domainecategoriedossier");
  }
  findByTypeDossier(typeDossierId) {
    return DomaineCategorieDossierService.get(
      `${this.prefix}/${typeDossierId}/typedossier`
    );
  }

  linkChecklistModeles(data) {
    return DomaineCategorieDossierService.post(`${this.prefix}/link-checklistmodeles`,data);
  }
}
