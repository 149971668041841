import React, { useState, useEffect } from 'react';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer, Tooltip, Cell } from 'recharts';
import { Spin, Typography } from 'antd';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';
import styles from './DocumentsByStatutClientForExploitant.module.scss';

const { Title } = Typography;

const DocumentsByStatutClientForExploitant = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DashboardService.getInstance()
            .countDocumentsByStatutClientForExploitant()
            .then(response => {
                if (response.error === 0) {
                    setData(response.data);
                } else {
                    catchError(response.message);
                }
            })
            .catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA46BE', '#FF6361'];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className={styles.chartContainer}>
            <Title level={4} className={styles.title}>Documents par Statut Client pour Exploitant</Title>
            <ResponsiveContainer width="100%" height={400}>
                <RadialBarChart 
                    cx="50%" 
                    cy="50%" 
                    innerRadius="20%" 
                    outerRadius="90%" 
                    barSize={10} 
                    data={data} 
                    startAngle={90} 
                    endAngle={-270}
                >
                    <RadialBar 
                        minAngle={15} 
                        label={{ position: 'insideStart', fill: '#fff' }} 
                        background 
                        clockWise 
                        dataKey="documents"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </RadialBar>
                    <Legend 
                        iconSize={10} 
                        layout="horizontal" 
                        verticalAlign="bottom" 
                        align="center"
                        payload={data.map((entry, index) => ({
                            value: `${entry.statut} : ${entry.documents}`,
                            type: "square",
                            id: entry.statut,
                            color: COLORS[index % COLORS.length],
                        }))}
                    />
                    <Tooltip />
                </RadialBarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DocumentsByStatutClientForExploitant;
