import { Card, Form, Input, Modal, Select, Spin } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import TypeDossierService from "../../services/TypeDossierService";
import TypeDocumentService from "../../services/TypeDocumentService";
import { DocumentService } from "../../services/DocumentService";
import CategorieDocumentService from "../../services/CategorieDocumentService";
import DomaineService from "../../services/DomaineService";
import DomaineCategorieService from "../../services/DomaineCategorieService";
import DocumentPrioriteService from "../../services/DocumentPrioriteService";
export default function DocumentEdit({ visible, onUpdate, onCancel, uid }) {
  const [form] = Form.useForm();
  const [editing, setEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [document, setDocument] = React.useState(null);
  const [domaines, setDomaines] = React.useState([]);
  const [selectedDomaine, setSelectedDomaine] = React.useState("");
  const [categorieDocuments, setCategorieDocuments] = React.useState([]);
  const [typeDocuments, setTypeDocuments] = React.useState([]);
  const [typeDossiers, setTypeDossiers] = React.useState([]);
  const [typeCodification, setTypeCodification] = React.useState("");
  const [selectedDomaineCategorieId, setSelectedDomaineCategorieId] =
    React.useState("");
  const [domaineCategories, setDomaineCategories] = React.useState([]);
  const [isAutreDomaine, setIsAutreDomaine] = React.useState(false);
  const [documentPriorites, setDocumentPriorites] = React.useState([]);
  React.useEffect(() => {
    setEditing(true);
    Promise.all([
      DomaineService.getInstance().all(),
      CategorieDocumentService.getInstance().all(),
    ])
      .then(([domaineResponse, categorieDocumentResponse]) => {
        setDomaines(domaineResponse.data);
        setCategorieDocuments(categorieDocumentResponse.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setEditing(false));
  }, []);

  React.useEffect(() => {
    if (!selectedDomaineCategorieId) {
      return;
    }
    setLoading(true);
    Promise.all([
      TypeDossierService.getInstance().findByDomaineCategorie(
        selectedDomaineCategorieId
      ),
      TypeDocumentService.getInstance().findByDomaineCategorie(
        selectedDomaineCategorieId
      ),
    ])
      .then(
        ([
          typeDossiersResponse,
          typeDocumentsResponse,
          categorieDocumentsResponse,
        ]) => {
          if (typeDossiersResponse?.data) {
            setTypeDossiers(typeDossiersResponse.data);
          }
          if (typeDocumentsResponse?.data) {
            setTypeDocuments(typeDocumentsResponse.data);
          }
          if (categorieDocumentsResponse?.data) {
            setCategorieDocuments(categorieDocumentsResponse.data);
          }
        }
      )
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setLoading(false));
  }, [selectedDomaineCategorieId]);

  React.useEffect(() => {
    if (
      !selectedDomaine ||
      selectedDomaine.code === DomaineService.CODE.OTHER_DOMAIN
    ) {
      return;
    }
    setEditing(true);
    DomaineCategorieService.getInstance()
      .findByDomaine(selectedDomaine.uid)
      .then((response) => {
        if (response.data) {
          setDomaineCategories(response.data);
        }
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setEditing(false));
  }, [selectedDomaine]);

  React.useEffect(() => {
    setEditing(true);
    // Charger les priorités
    DocumentPrioriteService.getInstance()
      .all()
      .then((response) => {
        setDocumentPriorites(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setEditing(false));
  }, []);

  React.useEffect(() => {
    setEditing(true);
    if (!visible) return;
    if (uid) {
      DocumentService.getInstance()
        .find(uid)
        .then((response) => {
          const data = response.data;
          setDocument(data);
          form.setFieldsValue(data);
          // Déterminer le type de codification par défaut
          if (data.version) {
            setTypeCodification("VS");
            form.setFieldsValue({ type_codification: "VS" });
          } else if (data.edition && data.revision) {
            setTypeCodification("ER");
            form.setFieldsValue({ type_codification: "ER" });
          } else if (data.codification && data.code_codification) {
            setTypeCodification("AU");
            form.setFieldsValue({ type_codification: "AU" });
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setEditing(false));
    }
  }, [uid, form, visible]);

  React.useEffect(() => {
    if (document) {
      if (!document.domaine_categorie) {
        const otherDomain = domaines.find(
          (item) => item.code === DomaineService.CODE.OTHER_DOMAIN
        );
        setSelectedDomaine(otherDomain);
        form.setFieldsValue({
          domaine_id: otherDomain.id,
          domaine_categorie_id: null,
        });
        setIsAutreDomaine(true);
      } else {
        setIsAutreDomaine(false);
        setSelectedDomaine(() => {
          return domaines.find(
            (item) => item.id === document.domaine_categorie.domaine_id
          );
        });
        setSelectedDomaineCategorieId(document.domaine_categorie_id);
        form.setFieldsValue({
          domaine_id: document.domaine_categorie.domaine_id,
          domaine_categorie_id: document.domaine_categorie_id,
        });
      }
    }
  }, [document, domaines, form]);

  const onFinish = (values) => {
    if (isAutreDomaine) {
      values.domaine_categorie_id = null;
      values.type_document_id = null;
      values.type_dossier_id = null;
    }
    setEditing(true);
    DocumentService.getInstance()
      .update(document.uid, values)
      .then((response) => {
        Toast.success(response.message);
        form.resetFields();
        onUpdate(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setEditing(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    catchError(errorInfo);
  };

  const typeCodifications = [
    { label: "Edition/Revision", value: "ER" },
    { label: "Version", value: "VS" },
    { label: "Autre", value: "AU" },
  ];

  const handleTypeCodificationChange = (value) => {
    setTypeCodification(value);
  };

  const handleDomaineChange = (value) => {
    form.setFieldsValue({
      domaine_categorie_id: undefined,
      type_document_id: undefined,
      type_dossier_id: undefined,
    });
    const domaine = domaines.find((item) => item.id === value);
    setSelectedDomaine(domaine);
    domaine.code === DomaineService.CODE.OTHER_DOMAIN
      ? setIsAutreDomaine(true)
      : setIsAutreDomaine(false);
  };

  const handleDomaineCategorieChange = (value) => {
    setSelectedDomaineCategorieId(value);
  };

  return (
    <Modal
      open={visible}
      okText="Mettre à jour"
      okType="primary"
      cancelText="Annuler"
      onOk={form.submit}
      onCancel={onCancel}
    >
      <Card loading={loading}>
        <Spin spinning={editing}>
          <Form
            form={form}
            name="editDocumentForm"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Domaine"
              name="domaine_id"
              rules={[{ required: true, message: "Ce champ est obligatoire!" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Sélectionner un domaine"
                onChange={handleDomaineChange}
              >
                {domaines.map((domaine) => (
                  <Select.Option key={domaine.id} value={domaine.id}>
                    {domaine.nom}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Categorie Document"
              name="categorie_document_id"
              rules={[{ required: true, message: "Ce champ est obligatoire!" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Sélectionner une catégorie de document"
              >
                {categorieDocuments.map((type) => (
                  <Select.Option key={type.id} value={type.id}>
                    {type.nom}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {isAutreDomaine && (
              <Form.Item
                label="Nom"
                name="nom"
                rules={[
                  { required: true, message: "Ce champ est obligatoire!" },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {!isAutreDomaine && (
              <>
                <Form.Item
                  label="Categorie Exploitant"
                  name="domaine_categorie_id"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Select
                    onChange={handleDomaineCategorieChange}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Sélectionner une catégorie"
                  >
                    {domaineCategories.map((domaineCategorie) => (
                      <Select.Option
                        key={domaineCategorie.id}
                        value={domaineCategorie.id}
                      >
                        {domaineCategorie.categorie_exploitant.nom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Type Document"
                  name="type_document_id"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Sélectionner un type de document"
                  >
                    {typeDocuments.map((type) => (
                      <Select.Option key={type.id} value={type.id}>
                        {type.nom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Type Dossier"
                  name="type_dossier_id"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Sélectionner un type de dossier"
                  >
                    {typeDossiers.map((type) => (
                      <Select.Option key={type.id} value={type.id}>
                        {type.nom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Type Codification"
              name="type_codification"
              rules={[{ required: true, message: "Ce champ est obligatoire!" }]}
            >
              <Select
                onChange={handleTypeCodificationChange}
                showSearch
                optionFilterProp="children"
                placeholder="Sélectionner le type de codification"
              >
                {typeCodifications.map((type) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {typeCodification === "VS" && (
              <Form.Item
                label="Version"
                name="version"
                rules={[
                  { required: true, message: "Ce champ est obligatoire!" },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {typeCodification === "ER" && (
              <>
                <Form.Item
                  label="Edition"
                  name="edition"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Revision"
                  name="revision"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Date d'édition"
                  name="date_edition"
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est obligatoire!",
                    },
                  ]}
                >
                  <Input type="date" />
                </Form.Item>
                <Form.Item
                  label="Date de révision"
                  name="date_revision"
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est obligatoire!",
                    },
                  ]}
                >
                  <Input type="date" />
                </Form.Item>
              </>
            )}
            {typeCodification === "AU" && (
              <>
                <Form.Item
                  label="Codification"
                  name="codification"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Code Codification"
                  name="code_codification"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
            <Form.Item
              label="Priorité"
              name="document_priorite_id"
              rules={[{ required: true, message: "Ce champ est obligatoire!" }]}
            >
              <Select placeholder="Sélectionner une priorité">
                {documentPriorites.map((priorite) => (
                  <Select.Option key={priorite.id} value={priorite.id}>
                    {priorite.nom}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Date Retour Souhaitée"
              name="date_retour_souhaitee"
            >
              <Input type="date" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </Modal>
  );
}
