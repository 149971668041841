import { DaoService } from "./DaoService";

export default class DocumentEvaluationService extends DaoService {

    static getInstance() {
        return super.getInstance('documentevaluation');
    }

    async findByDocument(uid) {
        return DocumentEvaluationService.get(`${this.prefix}/${uid}/document`);
    }

    async validate(uid) {
        return DocumentEvaluationService.post(`${this.prefix}/${uid}/validate`);
    }

    search(data) {
        return DocumentEvaluationService.post(`${this.prefix}/search`, data);
    }

}