import React from 'react';
import { Card } from 'antd';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer } from 'recharts';
import styles from './CounterCard.module.scss';

const CounterCard = ({ title, value }) => {
    const data = [
        {
            name: 'Documents',
            value: value,
            fill: '#1890ff',
        }
    ];

    return (
        <Card className={styles.counterCard}>
            <div className={styles.content}>
                <ResponsiveContainer width="100%" height={120}>
                    <RadialBarChart
                        cx="50%"
                        cy="50%"
                        innerRadius="10%"
                        outerRadius="80%"
                        barSize={10}
                        data={data}
                    >
                        <RadialBar
                            minAngle={15}
                            clockWise
                            dataKey="value"
                        />
                        <Legend
                            iconSize={10}
                            width={120}
                            height={140}
                            layout="vertical"
                            verticalAlign="middle"
                            wrapperStyle={styles.legend}
                        />
                    </RadialBarChart>
                </ResponsiveContainer>
                <div className={styles.text}>
                    <h3>{value}</h3>
                    <p>{title}</p>
                </div>
            </div>
        </Card>
    );
};

export default CounterCard;
