import React from "react";
import Main from "../components/layout/Main";
import { Col, Row } from "antd";
import ExploitantCountStats from "../components/dashboard/ExploitantCountStats";
import UserCountStats from "../components/dashboard/UserCountStats";
import DocumentStatsByStatutClientTable from "../components/dashboard/DocumentStatsByStatutClientTable";
import DocumentsByStatutDocumentGlobal from "../components/dashboard/DocumentsByStatutDocumentGlobal";
import UsersAndContactsByExploitant from "../components/dashboard/UsersAndContactsByExploitant";
import { useUserContext } from "../providers/UserProvider";
import UsersContactsDocumentsStatsForExploitant from "../components/dashboard/UsersContactsDocumentsStatsForExploitant";
import DocumentsByStatutClientForExploitant from "../components/dashboard/DocumentsByStatutClientForExploitant";
import LatestDocumentsForExploitant from "../components/dashboard/LatestDocumentsForExploitant";
import DashboardTitle from "../components/dashboard/DashboardTitle";
import MyDocuments from "../components/dashboard/MyDocuments";
function HomePage() {
  const { currentUser } = useUserContext();

  return (
    <Main>
      {currentUser && !currentUser?.is_contact && (
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <DashboardTitle title="Tableau de bord de ANACIM" />
          </Col>
          <Col span={24}>
            <ExploitantCountStats />
          </Col>
          <Col span={24}>
            <DocumentStatsByStatutClientTable />
          </Col>
          <Col span={24}>
            <MyDocuments currentUser={currentUser} />
          </Col>
          <Col span={24}>
            <UserCountStats />
          </Col>
          <Col span={24} lg={8}>
            <DocumentsByStatutDocumentGlobal />
          </Col>
          <Col span={24} lg={16}>
            <UsersAndContactsByExploitant />
          </Col>
        </Row>
      )}
      {currentUser && currentUser?.is_contact && (
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <DashboardTitle title={`Tableau de bord de ${currentUser?.organisation?.nom}`} />
          </Col>
          <Col span={24} lg={8}>
            <UsersContactsDocumentsStatsForExploitant />
          </Col>
          <Col span={24} lg={16}>
            <DocumentsByStatutClientForExploitant />
          </Col>
          <Col span={24}>
            <MyDocuments currentUser={currentUser} />
          </Col>
          <Col span={24} lg={24}>
            <LatestDocumentsForExploitant />
          </Col>
        </Row>
      )}
    </Main>
  );
}

export default HomePage;
