// UserDetailsPage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Avatar, Tag, Button, Popconfirm, Row, Col, Tabs } from 'antd';
import { EditFilled, DeleteFilled, LockFilled, UnlockFilled, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import styles from './UserDetailsPage.module.scss';
import Main from '../../components/layout/Main';
import UserService from '../../services/UserService';
import { useUserContext } from '../../providers/UserProvider';
import UserGroupes from '../../components/groupe/UserGroupes';
import LoadingPlaceholder from '../../components/utils/LoadingPlaceholder';
import PieceJointeList from '../../components/piecejointe/PieceJointeList';
import PieceJointeService from '../../services/PieceJointeService';
import { catchError } from '../../services/DaoService';
import Toast from '../../helpers/Toast';
import DisableUserModal from '../../components/user/DisableUserModal';
import UserEdit from '../../components/user/UserEdit';
import PageTitle from '../../components/utils/PageTitle';
import LogTraces from '../../components/logtrace/LogTraces';


const UserDetailsPage = () => {
    const { uid } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const { check } = useUserContext();
    const [resendLoading, setResendLoading] = useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isUserStateChanging, setIsUserStateChanging] = React.useState(false);
    const [disabledModalVisible, setDisabledModalVisible] = React.useState(false);
    const [updateModalVisible, setUpdateModalVisible] = React.useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!check('VIEW-USER')) {
            return;
        }
        UserService.getInstance()
            .find(uid)
            .then(response => {
                setUser(response.data);
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [uid, check]);

    const openEditModal = (user) => {
        setUpdateModalVisible(true);
    };

    const openDisabledModal = (user) => {
        setDisabledModalVisible(true);
    }

    const handleDisable = (user) => {
        setUser(user);
        setDisabledModalVisible(false);
    };

    const handleEnable = (user) => {
        setIsUserStateChanging(true);
        UserService.getInstance()
            .enable(user.uid)
            .then((response) => {
                Toast.success(response.message);
                setUser(response.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setIsUserStateChanging(false);
            });
    };

    const handleUpdate = (user) => {
        setUser(user);
        setUpdateModalVisible(false);
    }

    const handleDelete = (user) => {
        setIsDeleting(true);
        UserService.getInstance().remove(user.uid)
            .then(() => {
                navigate(`/parametrage/user`);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    };

    const resendVerificationEmail = () => {
        setResendLoading(true);
        UserService.getInstance().resendVerifyEmailToken(user.uid)
            .then((response) => {
                Toast.success(response.message);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setResendLoading(false);
            });
    }

    const tabItems = [
        check('VIEW-USER-ATTACHMENTS') && {
            key: "attachments",
            label: "Pièces jointes",
            children: <PieceJointeList canSee={check('VIEW-USER-ATTACHMENTS')}
                canAdd={check('ADD-USER-ATTACHMENTS')} candRemove={check('DELETE-USER-ATTACHMENTS')}
                parentType={PieceJointeService.TYPES.USER} parentId={user?.id} />
        },
        check('MANAGE-DATA-OPERATIONS-HISTORY') && user && {
            key: "trace-logs",
            label: "Historique des modifications",
            children: <LogTraces target={'user'} parentId={user?.id} />
        },
    ];


    return (
        <Main>
            <PageTitle title={user ? user.name : 'Utilisateur'} icon={<UserOutlined />}
                subTitle={user?.email} backPageTitle="Liste des utilisateurs"
                backPageLink={"/parametrage/user"} canSeeBackPageLink={check('VIEW-USERS')}>
                {check('EDIT-USER') ?
                    <Button onClick={() => openEditModal(user)} type="primary" className='btn btn-warning' icon={<EditFilled />}>Éditer</Button>
                    : null}
                {user?.enabled ? (
                    check('DISABLE-USER') ? (
                        <Button disabled={user?.organisation && !user?.organisation?.accepted} loading={isUserStateChanging} onClick={() => openDisabledModal(user)} type="primary" danger icon={<LockFilled />}>Désactiver</Button>
                    ) : <></>
                ) : (
                    check('ENABLE-USER') ? (
                        <Popconfirm title="Voulez-vous vraiment activer cet uilisateur ?" onConfirm={() => handleEnable(user)} okText="Activer" cancelText="Annuler" okType='primary'>
                            <Button disabled={user?.organisation && !user?.organisation?.accepted} type='primary' loading={isUserStateChanging} icon={<UnlockFilled title='Activer un utilisateur' />}>Activer</Button>
                        </Popconfirm>
                    ) : <></>
                )}
                {check('DELETE-USER') ?
                    <Popconfirm title="Voulez-vous vraiment supprimer cet utilisateur?" onConfirm={() => handleDelete(user)} okText="Oui" okButtonProps={{ danger: true }} cancelText="Non">
                        <Button disabled={user?.organisation && !user?.organisation?.accepted} type="primary" loading={isDeleting} danger icon={<DeleteFilled />}>Supprimer</Button>
                    </Popconfirm>
                    : null}
                {/* resend verification email */}
                {check('EDIT-USER') && !user?.email_verified_at ? <Popconfirm title="Voulez-vous vraiment renvoyer l'email de vérification?" onConfirm={resendVerificationEmail} okText="Renvoyer" cancelText="Annuler">
                    <Button loading={resendLoading} type="default" danger icon={<ReloadOutlined />}>Renvoyer l'email de vérification</Button>
                </Popconfirm> : null}
            </PageTitle>
            {loading ? (
                <LoadingPlaceholder />
            ) :
                <Card loading={loading} className={styles.card}>
                    <div className={styles.header}>
                        <Avatar size={100} src={user.avatar} className={styles.avatar} />
                        <div className={styles.info}>
                            <h1 className={styles.name}>{user.name}</h1>
                            <p className={styles.email}>{user.email}</p>
                            <p className={styles.email}>{user.telephone}</p>
                            {user.is_contact ? (
                                <span className="badge bg-success">Contact</span>
                            ) :  <span className="badge bg-success p-2 fs-6">{user.user_fonction?.nom}</span>}

                        </div>
                    </div>
                    <div className={styles.details}>
                        <p><strong>Statut :</strong> {user.enabled ? <Tag color="green">Activé</Tag> : <Tag color="red">Désactivé</Tag>}</p>
                    </div>
                    <Card className={styles.card}>
                        <Row>
                            <Col sm={24} md={12}>
                                <UserGroupes user={user} />
                            </Col>
                            <Col sm={24} md={12}>
                                <Tabs items={tabItems} />
                            </Col>
                        </Row>
                    </Card>
                </Card>
            }
            <UserEdit
                visible={updateModalVisible}
                uid={user ? user.uid : null}
                onUpdate={handleUpdate}
                onCancel={() => setUpdateModalVisible(false)}
            />
            <DisableUserModal
                visible={disabledModalVisible}
                uid={user ? user.uid : null}
                onUpdate={handleDisable}
                onCancel={() => setDisabledModalVisible(false)}
            />
        </Main>
    );
};

export default UserDetailsPage;