import React from "react";
import { catchError } from "../../services/DaoService";
import { useUserContext } from "../../providers/UserProvider";
import { Button, Card, Space, Spin, Row, Col, Empty } from 'antd';
import { ArrowDownOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import DocumentEvaluationMemberService from "../../services/DocumentEvaluationMemberService";
import DocumentEvaluationMemberListItem from "./DocumentEvaluationMemberListItem";
import DocumentEvaluationMemberNew from "./DocumentEvaluationMemberNew";

export default function DocumentEvaluationMemberList({ documentEvaluation }) {
  const [loading, setLoading] = React.useState(true);
  const [documentEvaluationMembers, setDocumentEvaluationMembers] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState(null);
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [documentEvaluationMember, setDocumentEvaluationMember] = React.useState([]);
  const { check, currentUser } = useUserContext();
  const [loaded, setLoaded] = React.useState(false);

  const init = React.useCallback(() => {
    if (!documentEvaluation) {
      return;
    }
    setLoading(true);
    DocumentEvaluationMemberService.getInstance()
      .findByDocumentEvaluation(documentEvaluation.uid)
      .then((response) => {
        setPaginationData(response.data);
        setLoaded(true);
        setDocumentEvaluationMembers(response.data.data);
      }).catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [documentEvaluation]);

  React.useEffect(() => {
    if (!check('VIEW-DOCUMENT-EVALUATION-MEMBERS')) {
      return;
    }
    init();
  }, [init, check, documentEvaluationMember]);

  React.useEffect(() => {
    if (paginationData) {
      setDocumentEvaluationMembers(prevMembers => {
        const uniqueMembers = paginationData.data.filter((newMember) => {
          return !prevMembers.find((prevMember) => prevMember.id === newMember.id);
        });
        return [...prevMembers, ...uniqueMembers];
      });

      // Définissez si plus de données sont disponibles pour la pagination
      setHasMore(paginationData.next_page_url !== null);
    } else {
      setDocumentEvaluationMembers([]);
      setHasMore(false);
    }
  }, [paginationData]);


  const handleAssignMenmbersToDocumentEvaluation = (member) => {
    setDocumentEvaluationMember(member);
    setNewModalVisible(false);
  }

  const setItemToNew = () => {
    setNewModalVisible(true)
  }

  const handleDelete = (member) => {
    setDocumentEvaluationMembers(documentEvaluationMembers.filter(item => item.id !== member.id));
  }

  const handleSigned = (updatedMember) => {
    init();
  }

  const loadMore = () => {
    if (hasMore) {
      setLoading(true);
      DocumentEvaluationMemberService.get(paginationData.next_page_url)
        .then((response) => {
          if (!response?.data) return;
          setPaginationData(response.data);
        }).catch((error) => {
          catchError(error);
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <div>
      <DocumentEvaluationMemberNew onSave={handleAssignMenmbersToDocumentEvaluation} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} documentEvaluation={documentEvaluation} />
      <Spin spinning={loading}>
        <Card extra={
          <>
            <Space>
              <Button onClick={init} icon={<ReloadOutlined />}>
                Rafraîchir
              </Button>
              {currentUser?.is_admin || (currentUser?.id===documentEvaluation.document?.responsable_id && !documentEvaluation.closed) ?
                <Button onClick={() => setItemToNew()} icon={<PlusCircleOutlined />} type="primary">Ajouter des membres</Button>
                : null}
            </Space>
          </>
        }
        >
          <Row gutter={16}>
            {documentEvaluationMembers.map((item, index) => (
              <Col xs={24} md={12} key={index} style={{ paddingTop: '20px' }}>
                <DocumentEvaluationMemberListItem documentEvaluation={documentEvaluation} item={item} onDelete={handleDelete} onSigned={handleSigned} />
              </Col>
            ))}
            {documentEvaluationMembers.length === 0 && loaded && (
              <Col span={24}>
                <Empty description="Aucun membre trouvé" />
              </Col>
            )}
            <Col span={8} style={{ paddingTop: '20px' }}>
              <Spin spinning={loading} />
            </Col>
          </Row>
        </Card>
      </Spin>
      <div className='d-flex justify-content-center'>
        <Button icon={<ArrowDownOutlined />} disabled={!hasMore} loading={loading} type="primary" onClick={loadMore} style={{ marginTop: '20px' }}>
          Voir plus
        </Button>
      </div>
    </div>
  );
}
