import React, { useState } from 'react';
import { Button, Descriptions, List, Popconfirm, Space, Typography, Card } from 'antd';
import { DeleteOutlined, EyeOutlined, FilePdfOutlined, HistoryOutlined } from '@ant-design/icons';
import DemandeCorrectionDocumentService from '../../services/DemandeCorrectionDocumentService';
import { catchError } from '../../services/DaoService';
import styles from './DemandeCorrectionDocumentListItem.module.scss';
import { NavLink } from 'react-router-dom';
import { useUserContext } from '../../providers/UserProvider';

export default function DemandeCorrectionDocumentListItem({ onDelete, item, setItemToView, onLogTracesClick, document }) {
    const [isDeleting, setIsDeleting] = useState(false);
    const { currentUser } = useUserContext();

    const handleDelete = () => {
        setIsDeleting(true);
        DemandeCorrectionDocumentService.getInstance()
            .remove(item.uid)
            .then(() => {
                onDelete(item);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    };

    // Détermine le statut du document
    const correctionStatus = item.corrige ? (
        <Typography.Text type="success">Corrigé</Typography.Text>
    ) : (
        <Typography.Text type="warning">En attente des corrections</Typography.Text>
    );

    return (
        <div className={styles.cardItem}>
            <Card bordered={false} className={{ body: 'py-1' }}>
                <List.Item
                    actions={[
                        <Space>
                            <NavLink to={item.report_link} target="_blank">
                                <Button danger icon={<FilePdfOutlined />} className={styles.actionButton} />
                            </NavLink>
                            <Button onClick={() => setItemToView(item)} icon={<EyeOutlined />} className={styles.actionButton} />
                            <Button onClick={() => onLogTracesClick(item)} icon={<HistoryOutlined />} className={styles.actionButton} />
                            {(currentUser?.is_admin || (document?.responsable_id === currentUser?.id && !document.closed)) && (
                                <Popconfirm
                                    okType="danger"
                                    okText="Supprimer"
                                    cancelText="Annuler"
                                    placement="topRight"
                                    title="Voulez-vous vraiment supprimer cette demande de correction de document ?"
                                    onConfirm={handleDelete}
                                >
                                    <Button
                                        loading={isDeleting}
                                        type="primary"
                                        danger
                                        icon={<DeleteOutlined />}
                                        className={styles.actionButton}
                                    />
                                </Popconfirm>
                            )}
                        </Space>
                    ]}
                >
                    <List.Item.Meta
                        title={
                            <Typography.Title onClick={() => setItemToView(item)} level={4} className={styles.title}>
                                Numéro: {item.numero}
                            </Typography.Title>
                        }
                        description={
                            <div className={styles.description}>
                                <Descriptions column={1} size="small" className={styles.descriptions}>
                                    <Descriptions.Item label="Date d'envoi">{item.date_envoie}</Descriptions.Item>
                                    <Descriptions.Item label="Date de correction">{item.date_correction || 'Non corrigé'}</Descriptions.Item>
                                    <Descriptions.Item label="Créé par">{item.created_by?.name || 'Inconnu'}</Descriptions.Item>
                                    <Descriptions.Item label="Observations">{item.observations || 'Aucune observation'}</Descriptions.Item>
                                    <Descriptions.Item label="Statut">{correctionStatus}</Descriptions.Item> {/* Ajout du statut */}
                                </Descriptions>
                            </div>
                        }
                    />
                </List.Item>
            </Card>
        </div>
    );
}
