import React, { useState, useRef } from "react";
import { Form, Input, Modal, Space, Divider, Progress } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import styles from "./PieceJointeSingleForm.module.scss";
import Toast from "../../helpers/Toast";
import PieceJointeService from "../../services/PieceJointeService";
import { catchError } from "../../services/DaoService";

const PieceJointeSingleForm = ({ visible, onClose, parentType, parentId, onUploadFinish }) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [description, setDescription] = useState("");
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const fileInputRef = useRef(null);

    React.useEffect(() => {
        if (!visible) {
            setFile(null);
            setFileName("");
            setDescription("");
            setUploadProgress(0);
        }
    }, [visible]);

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            const maxSize = 1024 * 1024 * 1024; // 1024 Mo = 1 Go en octets
            if (selectedFile.size > maxSize) {
                Toast.error("La taille du fichier ne doit pas dépasser 1 Go.");
                return;
            }
            setFile(selectedFile);
            const nameWithoutExtension = selectedFile.name
                .split(".")
                .slice(0, -1)
                .join(".");
            setFileName(nameWithoutExtension.replace(/[-_]/g, " "));
        } else {
            setFile(null);
            setFileName("");
            setDescription("");
        }
    };

    const handleUpload = () => {
        if (!file || !fileName) {
            Toast.error("Veuillez sélectionner un fichier et entrer un nom.");
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append("piece", file);
        formData.append("nom", fileName);
        formData.append("description", description);
        formData.append("type", parentType);
        formData.append("parent_id", parentId);

        PieceJointeService.getInstance()
            .create(formData, setUploadProgress)
            .then((response) => {
                Toast.success(response.message);
                setFile(null);
                setFileName("");
                setDescription("");
                onClose(); // Ferme le modal après succès
                onUploadFinish(); // Appelle la fonction pour rafraîchir la liste
            })
            .catch((error) => {
                Toast.error("Erreur lors du chargement de la pièce jointe.");
                catchError(error);
            })
            .finally(() => {
                setUploading(false);
            });
    };

    return (
        <Modal
            title="Ajouter Pièce Jointe"
            open={visible}
            onCancel={onClose}
            onOk={handleUpload}
            confirmLoading={uploading}
        >
            <Form layout="vertical">
                <Divider orientation="left" />
                <Form.Item label="Fichier">
                    <div
                        className={styles.uploadContainer}
                        onClick={() => fileInputRef.current.click()}
                    >
                        <Space>
                            <UploadOutlined className={styles.uploadIcon} />
                            <span>
                                {file ? fileName : "Cliquez pour sélectionner un fichier"}
                            </span>
                        </Space>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.png,.jpg,.jpeg,.mp4,.mp3,.zip,.rar"
                            style={{ display: "none" }}
                        />
                    </div>
                </Form.Item>
                <Form.Item label="Nom du fichier">
                    <Input
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Description">
                    <TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Item>

                <Form.Item>
                    <Progress percent={uploadProgress} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PieceJointeSingleForm;
