import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Checkbox,
  Popconfirm,
  Tabs,
  Button,
} from "antd";
import { GroupeService } from "../../services/GroupeService";
import { catchError } from "../../services/DaoService";
import Toast from "../../helpers/Toast";
import styles from "./GroupeDetails.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Main from "../../components/layout/Main";
import UserGroupeList from "../../components/user/UserGroupeList";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";
import { useUserContext } from "../../providers/UserProvider";
import { EditFilled, DeleteFilled, UsergroupAddOutlined } from "@ant-design/icons";
import GroupeEdit from "../../components/groupe/GroupeEdit";
import PageTitle from "../../components/utils/PageTitle";
import LogTraces from "../../components/logtrace/LogTraces";


const { Paragraph, Text, Title } = Typography;

const GroupeDetailsPage = () => {
  const { uid } = useParams();
  const [loading, setLoading] = useState(true);
  const [groupeData, setGroupeData] = useState(null);
  const [permissionsData, setPermissionsData] = useState([]);
  const { check } = useUserContext();
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [groupes, setGroupes] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      GroupeService.getInstance().getPermissions(),
      GroupeService.getInstance().find(uid),
    ])
      .then(([permissionsResponse, groupeResponse]) => {
        setPermissionsData(permissionsResponse.data);
        setGroupeData(groupeResponse.data);
      })
      .catch((error) => {
        catchError(error);
        Toast.error("Erreur lors du chargement des données.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uid, editModalVisible]);

  const tabItems = [
    {
      key: "permissions",
      label: "Permissions",
      children: (
        <Row>
          <Col span={24}>
            <Title level={4}>Permissions</Title>
            {permissionsData.map((group) => (
              <div key={group.code} className={styles.permissionsGroup}>
                <Title level={5}>{group.nom}</Title>
                <Row>
                  {group.permissions.map((permission) => (
                    <Col span={12} key={permission.code}>
                      <Checkbox
                        checked={groupeData.permissions.includes(
                          permission.code
                        )}
                        disabled
                      >
                        {permission.nom}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      ),
    },
    {
      key: "users",
      label: "Utilisateurs",
      children: <UserGroupeList groupeUid={uid} />,
    },
    check('MANAGE-DATA-OPERATIONS-HISTORY') && groupeData && {
      key: "trace-logs",
      label: "Historique des modifications",
      children: <LogTraces target={'groupe'} parentId={groupeData?.id} />
    },
  ];

  const setItemToEdit = (groupe) => {
    setGroupeData(groupe);
    setEditModalVisible(true);
  };
  const handleGroupeUpdate = (groupe) => {
    setEditModalVisible(false);
  };
  const removeItem = (groupe) => {
    setIsDeleting(true);
    GroupeService.getInstance()
      .remove(groupe.uid)
      .then(() => {
        setGroupes(groupes.filter(g => g.id !== groupe.id));
        navigate('/parametrage/groupe');
      }).catch(error => {
        catchError(error);
      }).finally(() => {
        setIsDeleting(false);
      });

  }

  return (
    <Main>
      <PageTitle title={groupeData?.nom} icon={<UsergroupAddOutlined />}
        backPageTitle={"Liste des groupes"} backPageLink={"/parametrage/groupe"}
        canSeeBackPageLink={check("VIEW-GROUPS")} subTitle={groupeData?.description}>
        {check("EDIT-GROUP") ? (
          <Button
            onClick={() => setItemToEdit(groupeData)}
            icon={<EditFilled />}
            className="btn btn-warning"
          >Modifier</Button>
        ) : null}
        {check("DELETE-GROUP") ? (
          <Popconfirm
            title="Voulez-vous vraiment supprimer ce groupe?"
            onConfirm={() => {
              removeItem(groupeData);
            }}
            okText="Supprimer"
            cancelText="Annuler"
            okType="danger"
          >
            <Button
              loading={isDeleting}
              type="primary"
              danger
              icon={<DeleteFilled />}
            >Supprimer</Button>
          </Popconfirm>
        ) : null}
      </PageTitle>
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <>

          <Card className="mb-2">
            <GroupeEdit
              visible={editModalVisible}
              uid={uid}
              onUpdate={handleGroupeUpdate}
              onCancel={() => setEditModalVisible(false)}
            />
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Paragraph>
                  <Text strong>Nom :</Text> {groupeData.nom}
                </Paragraph>
              </Col>
              <Col xs={24} md={12}>
                <Paragraph>
                  <Text strong>Code :</Text> {groupeData.code}
                </Paragraph>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Paragraph>
                  <Text strong>Description :</Text> {groupeData.description}
                </Paragraph>
              </Col>
            </Row>
          </Card>
          <Card bordered>
            <Tabs defaultActiveKey="permissions" items={tabItems} />
          </Card>
        </>
      )}
    </Main>
  );
};

export default GroupeDetailsPage;
