import React from "react";
import { List, Avatar, Button, Spin, Card, Space, Typography, Popconfirm, Badge } from "antd";
import { DeleteOutlined, EyeOutlined, UserOutlined } from "@ant-design/icons";
import { catchError } from "../../services/DaoService";
import UserService from "../../services/UserService";
import styles from "./OrganisationUserList.module.scss";
import { NavLink } from "react-router-dom";
import Toast from "../../helpers/Toast";
import Moment from "react-moment";

export default function OrganisationUserList({ organisation }) {
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    const [hasMore, setHasMore] = React.useState(true);

    const init = React.useCallback(() => {
        if (!organisation) return;

        setLoading(true);
        UserService.getInstance()
            .findByOrganisation(organisation.uid)
            .then((response) => {
                setPaginationData(response.data);
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [organisation]);

    React.useEffect(() => {
        init();
    }, [init]);

    const loadMore = () => {
        if (hasMore) {
            setLoading(true);
            UserService.get(paginationData.next_page_url)
                .then((response) => {
                    if (!response?.data) return;
                    setPaginationData(response.data);
                })
                .catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    React.useEffect(() => {
        if (!paginationData) return;

        setHasMore(paginationData.next_page_url !== null);
        setUsers((prevUsers) => {
            const uniqueUsers = paginationData.data.filter(
                (user) => !prevUsers.find((item) => item.uid === user.uid)
            );
            return [...prevUsers, ...uniqueUsers];
        });
    }, [paginationData]);

    const deleteUser = (user) => {
        UserService.getInstance()
            .remove(user.uid)
            .then((response) => {
                Toast.success(response.message);
                setUsers((prevUsers) => prevUsers.filter((item) => item.uid !== user.uid));
            })
            .catch((error) => {
                catchError(error);
            });
    }

    return (
        <div className={styles.card}>
            <Card bordered={false}
                extra={<Button loading={loading} type="default" icon={<EyeOutlined />} onClick={init}>Raffraichir</Button>}
            >
                <List
                    className={styles.userList}
                    loading={loading && users.length === 0}
                    itemLayout="horizontal"
                    dataSource={users}
                    renderItem={(user) => (
                        <List.Item className={styles.listItem} actions={[
                            <Space>
                                <NavLink to={`/parametrage/user/${user.uid}`}>
                                    <Button type="link" size="small" icon={<EyeOutlined />}></Button>
                                </NavLink>
                            </Space>,
                            <Popconfirm title="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
                                okText="Supprimer" okType="danger" cancelText="Annuler" onConfirm={() => deleteUser(user)}>
                                <Button type="link" style={{ color: "red" }} size="small" icon={<DeleteOutlined />}></Button>
                            </Popconfirm>
                        ]}>
                            <List.Item.Meta
                                avatar={
                                    <Avatar size="large" icon={<UserOutlined />} className={styles.avatar} />
                                }
                                title={<Typography.Text className={styles.userName}>{user.name}</Typography.Text>}
                                description={
                                    <>
                                        <Typography.Text className={styles.userEmail}>{user.email}</Typography.Text> <br />
                                        {user.telephone ?
                                            <>
                                                <Typography.Text className={styles.userEmail}>{user.telephone}</Typography.Text><br />
                                            </>
                                            : null}
                                        <Typography.Text className={styles.userLastLogin}>Dernière connexion: {user.last_login_at ? <Moment fromNow>{user.last_login_at}</Moment> : 'Jamais'}</Typography.Text><br />
                                        <Typography.Text className={styles.userStatus}>{
                                            user.enabled ? <Badge status="success" text="Actif" /> : <Badge status="error" text="Inactif" />}
                                        </Typography.Text>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
                <div className={styles.loadMore}>
                    {loading && <Spin />}
                    {hasMore && !loading && (
                        <Button onClick={loadMore} className={styles.loadMoreButton}>
                            Charger plus
                        </Button>
                    )}
                </div>
            </Card>
        </div>
    );
}
