

import Main from "../../components/layout/Main";
import InfiniteTable from "../../components/utils/InfiniteTable";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import PageTitle from "../../components/utils/PageTitle";
import React, { useCallback } from "react";
import { useUserContext } from "../../providers/UserProvider";
import { Button, Col, Divider, Input, Row, Select, Space, Spin, Tag, Typography } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import DocumentEvaluationService from "../../services/DocumentEvaluationService";
import { catchError } from "../../services/DaoService";
import { BuildOutlined, EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import Moment from "react-moment";

const { Text } = Typography;

export default function DocumentEvaluationListPage() {
    const [documentEvaluations, setDocumentEvaluations] = React.useState([]);
    const [hasMore, setHasMore] = React.useState(true);
    const { check } = useUserContext();
    const [searchText, setSearchText] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    const [statut, setStatut] = React.useState('tout');
    const navigate = useNavigate();

    const setItemToView = (record) => {
        navigate(`/document-evaluation/${record.document.uid}`);
    }

    const init = useCallback(() => {
        setLoading(true);
        DocumentEvaluationService.getInstance()
            .search({ searchText, statut  })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setDocumentEvaluations(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, statut, setLoading, setPaginationData]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (paginationData) {
            setDocumentEvaluations(documentEvaluations => [...new Map([...documentEvaluations, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const columns = [
        {
            title: "Numéro document",
            dataIndex: 'document',
            key: 'document',
            render: (document) => (
                check('VIEW-DOCUMENT') ?
                    <NavLink to={`/document/${document?.uid}`} style={{ textDecoration: 'none' }}>
                        {document?.document_number}
                    </NavLink>
                    : <Text>{document?.document_number}</Text>
            ),
            sorter: (a, b) => a.document.nom.localeCompare(b.document?.nom),
            width: 80,
            ellipsis: true
        },
        {
            title: "Nom",
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            width: 200,
            ellipsis: true
        },
        {
            title: "Date de création",
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => <Moment format="DD/MM/YYYY">{text}</Moment>,
            width: 100,
            ellipsis: true
        },
        {
            title: "Observations",
            dataIndex: 'observations',
            key: 'observations',
            width: 200,
            ellipsis: true,
            render: (text) => <Text>{text ?? 'Aucune observation notée...'}</Text>
        },
        {
            title: "Statut",
            key: 'validated',
            dataIndex: 'validated',
            render: (validated) => {
                let color;
                let text;
        
                if (validated) {
                    color = 'green';
                    text = 'Validée';
                } else {
                    color = 'orange';
                    text = 'En cous d\'évaluation';
                }
        
                return <Tag color={color}>{text}</Tag>;
            },
            width: 100
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (record) => (
                <Space>
                    {check('SHOW-DOCUMENT-EVALUATION') ? <Button onClick={() => setItemToView(record)} icon={<EyeOutlined />} className="btn btn-primary"></Button> : null}
                </Space>
            )
        }
    ];


    const labelStyle = {
        marginRight: '10px', 
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    const loadMore = () => {
         if (hasMore && !loading) {
             setLoading(true);
             DocumentEvaluationService.post(paginationData.next_page_url, { searchText, statut })
                 .then((response) => {
                     if (!response) return;
                     setPaginationData(response.data);
                 }).catch((error) => {
                     catchError(error);
                 })
                 .finally(() => {
                     setLoading(false);
                 });
         }
    }

    return (
        <Main>
            {check('SHOW-DOCUMENT-EVALUATION') ? (<>
                <PageTitle title="Liste des évaluations de document" icon={<BuildOutlined />} subTitle="Accéder et configurer la liste des évaluations de document...">
                    <Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>
                </PageTitle>
                <Spin spinning={loading}>
                    <InfiniteTable title={
                        <Row gutter={[16, 16]} justify={'center'}>
                            <Col span={24}>
                                <Divider>Filtres rapides</Divider>
                            </Col>
                            <Col xs={24} md={8} lg={6}>
                                <Input.Search size="large" value={searchText} onInput={
                                    (e) => {
                                        setSearchText(e.target.value);
                                    }} placeholder="Rechercher une évaluation" />
                            </Col>
                            <Col xs={24} md={8} lg={6}>
                                <div style={containerStyle}>
                                    <div style={labelStyle}>Statut:</div>
                                    <Select value={statut} onChange={
                                        (value) => {
                                            setStatut(value);
                                        }
                                    } placeholder="Filtrer par statut" style={{ width: '100%' }}>
                                        <Select.Option value="tout">Tous</Select.Option>
                                        <Select.Option value="pending">En attente</Select.Option>
                                        <Select.Option value="validated">Validée</Select.Option>
                                        <Select.Option value="rejet">Réjetée</Select.Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                    }
                        dataSource={documentEvaluations} loading={loading} columns={columns} scroll={{ x: 1300, y: 500 }}
                        paginationData={paginationData} loadMore={loadMore}
                    />
                </Spin>
            </>) : <UnauthorizedMessage />}
        </Main>
    )
} 