import React from 'react';
import styles from './PaginationInfo.module.scss';


const PaginationInfo = ({ paginationData, loadedCount }) => {
    if (!paginationData) return null;

    const totalElements = paginationData.total;

    return (
        <div className={styles.paginationInfoCard}>
            <strong>Éléments affichés :</strong> {loadedCount} sur {totalElements}
        </div>
    );
};

export default PaginationInfo;
