import { DaoService } from "./DaoService";

export default class StatutClientService extends DaoService {

    static STATUS = {
        DRAFT: 'DRAFT',
        TRANSMIS: 'SENT',
    }
    static getInstance() {
        return super.getInstance('statutclient');
    }

    all() {
        return StatutClientService.get(`${this.prefix}/all`);
    }

}