import { DaoService } from "./DaoService";

export default class DomaineCategorieService extends DaoService {
    static getInstance() {
        return super.getInstance('domainecategorie');
    }

    findByDomaine(domaineUid) {
        return DomaineCategorieService.get(`${this.prefix}/${domaineUid}/domaine`);
    }

    linkCategorieExploitants(domaineUid, data) {
        return DomaineCategorieService.post(`${this.prefix}/${domaineUid}/link-categorie-exploitants`, data);
    }

    linkExploitants(domaineCategorieId, data) {
        return DomaineCategorieService.post(`${this.prefix}/${domaineCategorieId}/link-exploitants`, data);
    }

    linkTypes(domaineCategorieId, data) {
        return DomaineCategorieService.post(`${this.prefix}/${domaineCategorieId}/link-types`, data);
    }

    findByOrganisationAndDomaine(organisationUid, domaineUid) {
        return DomaineCategorieService.get(`${this.prefix}/${organisationUid}/organisation/${domaineUid}/domaine`);
    }
}