import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Spin, Typography } from 'antd';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';
import styles from './DocumentsByStatutDocumentGlobal.module.scss';

const { Title } = Typography;

const DocumentsByStatutDocumentGlobal = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DashboardService.getInstance()
            .countDocumentsByStatutDocumentGlobal()
            .then(response => {
                if (response.error === 0) {
                    setData(response.data);
                } else {
                    catchError(response.message);
                }
            })
            .catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // Couleurs pour les différentes sections du Pie Chart
    const COLORS = ['#1f51a0', '#00C49F', '#FFBB28', '#FF8042', '#AA00FF', '#FF0050', '#00A000'];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className={styles.documentStatusChart}>
            <Title level={4} className={styles.title}>Statistiques des Documents par Statut Global</Title>
            <ResponsiveContainer width="100%" height={500}>
                <PieChart>
                    <Pie
                        data={data}
                        dataKey="documents"
                        nameKey="statut"
                        cx="50%"
                        cy="50%"
                        outerRadius={150}
                        fill="#8884d8"
                        label
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DocumentsByStatutDocumentGlobal;
