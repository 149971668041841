import { Form, Input, Modal, Spin } from "antd";
import React from "react";
import ChecklistModeleService from "../../services/ChecklistModeleService";
import { catchError } from "../../services/DaoService";
import Toast from "../../helpers/Toast";

export default function ChecklistModeleForm({ visible, onCancel, onCreate }) {
  const [creating, setCreating] = React.useState(false);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    setCreating(true);
    ChecklistModeleService.getInstance()
      .create(values)
      .then((response) => {
        Toast.success(response.message);
        form.resetFields();
        onCreate(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return (
    <Modal
      width={"70%"}
      title="Creation du modèle de checklist"
      okText="Enregistrer"
      onOk={form.submit}
      open={visible}
      onCancel={onCancel}
    >
      <Spin spinning={creating}>
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item
            name="nom"
            label="Nom"
            rules={[{ required: true, message: "Veuillez saisir un nom" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
