import { DaoService } from "./DaoService";

export default class OrganisationService extends DaoService {
    static getInstance() {
        return super.getInstance('organisation');
    }

    all() {
        return OrganisationService.get(`${this.prefix}/all`);
    }

    search(data) {
        return OrganisationService.post(`${this.prefix}/search`, data);
    }

    createPublicExploitant(data) {
        return OrganisationService.post(`${this.prefix}/new-exploitant`, data);
    }

    approve(uid) {
        return OrganisationService.post(`${this.prefix}/${uid}/approve`);
    }

    reject(uid) {
        return OrganisationService.post(`${this.prefix}/${uid}/reject`);
    }
}