import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";
import { Button, Col, Form, Input, Layout, Result, Row, Typography, Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import UserService from "../../services/UserService";
import Toast from "../../helpers/Toast";
import { catchError, storeToken } from "../../services/DaoService";
import logo from "../../assets/images/logo.png";
import { useUserContext } from "../../providers/UserProvider";

const { Content, Footer } = Layout;
const { Title } = Typography;

export default function ResetPasswordViaTokenPage() {
  const [verifying, setVerifying] = React.useState(true);
  const [verified, setVerified] = React.useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [uid, setUid] = React.useState(null);
  const [verificationErrorMsg, setVerificationErrorMsg] = React.useState(null);
  const { setCurrentUser, } = useUserContext();

  const onFinish = (values) => {
    values.uid = uid;
    setLoading(true);
    UserService.getInstance()
      .resetPassword(token, values)
      .then((response) => {
        Toast.success(response.message);
        storeToken(response.data.token);
        setCurrentUser(response.data.user);
        if (response.data.user.is_contact) {
          navigate('/document');
        } else {
          navigate('/');
        }
      }).catch((error) => {
        catchError(error);
      }).finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  React.useEffect(() => {
    if (!token) {
      return;
    }
    UserService.getInstance()
      .verifyEmail(token)
      .then((response) => {
        Toast.success(response.message);
        setUid(response.data.uid);
        setVerifying(false);
        setVerified(true);
      }).catch((error) => {
        setVerificationErrorMsg(error?.response?.data?.message);
        setVerified(false);
        catchError(error);
      }).finally(() => {
        setVerifying(false);
      });
  }, [token]);

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin d-flex full-height justify-content-center align-items-center">
        <Card bordered={false} className="card" style={{ maxWidth: '600px', width: '100%', marginBottom: '15px', padding: '80px' }}>
          <Row gutter={[24, 0]} justify="center">
            <Col xs={24} className="d-flex justify-content-center">
              <img height={100} src={logo} alt="Logo" />
            </Col>
          </Row>
          <Row gutter={[24, 0]} justify="center">
            <Col xs={24}>
              {verifying ? (
                <Result
                  extra={<LoadingPlaceholder rows={1} />}
                  status="info"
                  icon={<LoadingOutlined />}
                  title="Verification en cours"
                  subTitle="Veuillez patienter pendant que nous vérifions le lien de réinitialisation..."
                />
              ) : (
                <>
                  {verified ? (
                    <>
                      <Title className="mb-15 text-center" style={{ fontSize: '24px' }}>Changement de mot de passe</Title>
                      <Title className="font-regular text-muted" level={5} style={{ fontSize: '14px', maxWidth: '100%', margin: 'auto' }}>
                        Veuillez saisir votre nouveau mot de passe et confirmer le changement.
                      </Title>
                      <Form
                        className="row-col"
                        form={form}
                        name="changePasswordForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        style={{ margin: '0 auto' }}
                      >
                        <Form.Item
                          label="Nouveau mot de passe"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Veuillez saisir votre nouveau mot de passe.',
                            },
                            {
                              min: 8,
                              message: 'Le mot de passe doit contenir au moins 8 caractères.',
                            },
                          ]}
                        >
                          <Input.Password style={{ fontSize: '16px' }} />
                        </Form.Item>

                        <Form.Item
                          label="Confirmer le nouveau mot de passe"
                          name="password_confirmation"
                          dependencies={['password']}
                          rules={[
                            {
                              required: true,
                              message: 'Veuillez confirmer votre nouveau mot de passe.',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject('Les deux mots de passe ne correspondent pas.');
                              },
                            }),
                          ]}
                        >
                          <Input.Password style={{ fontSize: '16px' }} />
                        </Form.Item>

                        <Form.Item>
                          <Button size="large" loading={loading} type="primary" htmlType="submit" style={{ width: "100%", fontSize: '16px' }}>
                            Changer le mot de passe
                          </Button>
                        </Form.Item>
                      </Form>
                    </>
                  ) : (
                    <Result status="error" title="Erreur de vérification" subTitle={verificationErrorMsg} />
                  )}
                </>
              )}
            </Col>
          </Row>
        </Card>
      </Content>
      <Footer style={{ textAlign: 'center', padding: '10px 50px' }}>
        <p className="copyright">
          Copyright © {new Date().getFullYear()}{" "}
          <a href="https://terangagroup.net">Teranga Cloud Solutions</a>
        </p>
      </Footer>
    </Layout>
  );
}
