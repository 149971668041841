import React from "react";
import { Button, Card, Result, Table, Tag } from "antd";
import PropTypes from "prop-types";
import { useUserContext } from "../../providers/UserProvider";
import LogTraceService from "../../services/LogTraceService";
import { catchError } from "../../services/DaoService";
import { ReloadOutlined } from "@ant-design/icons";

export default function LogTraces({ target, parentId }) {
    const [logTraces, setLogTraces] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [hasMore, setHasMore] = React.useState(true);

    const { check } = useUserContext();

    const fetchLogTraces = React.useCallback(() => {
        setLoading(true);
        setLogTraces([]);
        LogTraceService.getInstance()
            .findAll(target, parentId)
            .then((response) => {
                setPaginationData(response.data);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [target, parentId]);

    React.useEffect(() => {
        fetchLogTraces();
    }, [fetchLogTraces]);

    React.useEffect(() => {
        if (paginationData) {
            setLogTraces(logTraces => {
                const uniqueLogTraces = paginationData.data.filter((logTrace) => {
                    return !logTraces.find((lt) => lt.id === logTrace.id);
                });
                return [...logTraces, ...uniqueLogTraces]
            });
            setHasMore(paginationData.next_page_url !== null);
        } else {
            setLogTraces([]);
            setHasMore(false);
        }
    }, [paginationData]);

    const loadMore = () => {
        if (hasMore) {
            setLoading(true);
            LogTraceService
                .get(paginationData.next_page_url)
                .then((response) => {
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }


    const columns = [
        {
            title: "No",
            dataIndex: "id",
            key: "id",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Operation",
            dataIndex: "operation",
            key: "operation",
            render: (operation) => {
                let color = "blue";
                if (operation === "create") color = "green";
                else if (operation === "delete") color = "red";
                return <Tag color={color}>{operation.toUpperCase()}</Tag>;
            },
        },
        {
            title: "Valeur Avant",
            dataIndex: "before_value",
            key: "before_value",
            render: (text) => (
                <code>
                    <pre>
                        {JSON.stringify(JSON.parse(text), null, 2)}
                    </pre>
                </code>
            ),
        },
        {
            title: "Valeur Aprés",
            dataIndex: "after_value",
            key: "after_value",
            render: (text) => (
                <code>
                    <pre>
                        {JSON.stringify(JSON.parse(text), null, 2)}
                    </pre>
                </code>
            ),
        },
        // user 
        {
            title: "Utilisateur",
            dataIndex: "user",
            key: "user",
            render: (user) => (
                <div>
                    <p>{user.name}</p>
                    <p>{user.email}</p>
                </div>
            ),
        },
        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
            render: (date) => new Date(date).toLocaleString(),
        },
    ];

    if (!check("MANAGE-DATA-OPERATIONS-HISTORY")) {
        return <Result status="403" title="403" subTitle="Désolé, vous n'êtes pas autorisé à accéder à cette page." />;
    }

    return (
        <Card actions={[
            <Button type="primary" onClick={loadMore} disabled={!hasMore}>
                {hasMore ? "Charger plus" : "Fin de la liste"}
            </Button>
        ]} extra={
            <Button icon={<ReloadOutlined />} iconPosition="end" loading={loading} type="default" onClick={fetchLogTraces}>Rafraîchir</Button>
        }
        >
            <Table scroll={{
                x: true,
            }} loading={loading} pagination={false} columns={columns} dataSource={logTraces} rowKey="id" />
        </Card>
    );
}


LogTraces.propTypes = {
    target: PropTypes.string.isRequired,
    parentId: PropTypes.number.isRequired,
};