import { Button, Card, Form, Input, Modal, Spin, Switch } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import OrganisationService from "../../services/OrganisationService";
import { useNavigate } from "react-router-dom";


export default function OrganisationNew({ visible, onCreate, onCancel, parent_id = null }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [creating, setCreating] = React.useState(false);

  const gotoOrganisationDetails = (organisation) => {
    navigate(`/parametrage/exploitant/${organisation.uid}`);
  }

  const handleSave = (isRedirection) => {
    setCreating(true);
    form.validateFields().then(values => {
      values.parent_id = parent_id;
      OrganisationService.getInstance()
        .create(values)
        .then(response => {
          Toast.success(response.message);
          form.resetFields();
          onCreate(response.data);
          if (isRedirection) {
            gotoOrganisationDetails(response.data);
          }
        }).catch(error => {
          catchError(error);
        }).finally(() => {
          setCreating(false);
        });
    }).catch(info => {
      console.log('Validation Failed:', info);
      setCreating(false);
    });
  };

  const onFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  }

  return (
    <Modal open={visible} onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>
          Annuler et fermer
        </Button>,
        <Button type="primary" loading={creating} onClick={() => handleSave(true)}>
          Enregistrer et afficher
        </Button>,
        <Button type="primary" loading={creating} onClick={() => handleSave(false)}>
          Enregistrer et créer
        </Button>,
      ]}>
      <Card>
        <Spin spinning={creating}>
          <Form
            form={form}
            name="newOrganisationForm"
            layout="vertical"
            onFinishFailed={onFailed}
            initialValues={{
              enabled: true,
            }}
          >
            <Form.Item
              label="Nom"
              name="nom"
              rules={[
                { required: true, message: 'Ce champ est obligatoire!' },
                { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Sigle"
              name="sigle"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="Responsable"
              name="responsable"
            >
              <Input />
            </Form.Item>
            <Form.Item label="Statut" name="enabled" valuePropName="checked">
              <Switch checkedChildren={<span>Activé</span>} unCheckedChildren={<span>Inactivé</span>} />
            </Form.Item>

          </Form>
        </Spin>
      </Card>
    </Modal>
  );

}