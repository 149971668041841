import { DaoService } from "./DaoService";
export default class DomaineService extends DaoService {

    static CODE = {
        OTHER_DOMAIN: 'AUTRE',
    }
    static getInstance() {
        return super.getInstance('domaine');
    }

    all() {
        return DomaineService.get(`${this.prefix}/all`);
    }

    findWithDataByUid(uid) {
        return DomaineService.get(`${this.prefix}/${uid}/find-with-data`);
    }

    findTypesByUid(uid) {
        return DomaineService.get(`${this.prefix}/${uid}/types`);
    }

    findByOrganisation(organisationUid) {
        return DomaineService.get(`${this.prefix}/${organisationUid}/organisation`);
    }

}