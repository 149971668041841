import Main from "../../components/layout/Main";
import InfiniteTable from "../../components/utils/InfiniteTable";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import PageTitle from "../../components/utils/PageTitle";
import React, { useCallback } from "react";
import { useUserContext } from "../../providers/UserProvider";
import { catchError } from "../../services/DaoService";
import {
    Button,
    Col,
    Divider,
    Input,
    Popconfirm,
    Row,
    Space,
    Spin,
} from "antd";
import {
    BuildOutlined,
    DeleteFilled,
    PlusCircleOutlined,
    ReloadOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import ChecklistModeleService from "../../services/ChecklistModeleService";
import { InlineEditableInput } from "../../components/utils/InlineEditableInput";
import Toast from "../../helpers/Toast";
import ChecklistModeleForm from "../../components/checklistmodel/ChecklistModeleForm";
import ChecklistModeleGroupModal from "../../components/checklistmodel/ChecklistModeleGroupsModal";

export default function ChecklistModeleListPage() {
    const [checklistModeles, setChecklistModeles] = React.useState([]);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [newModalVisible, setNewModalVisible] = React.useState(false);
    const [newModalConfigurationVisible, setNewModalConfigurationVisible] =
        React.useState(false);
    const [selectedChecklistModele, setSelectedChecklistModele] =
        React.useState(null);

    const [hasMore, setHasMore] = React.useState(true);
    const { check } = useUserContext();

    const [searchText, setSearchText] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);

    const setItemToConfigure = (record) => {
        setSelectedChecklistModele(record);
        setNewModalConfigurationVisible(true);
    };

    const init = useCallback(() => {
        setLoading(true);
        ChecklistModeleService.getInstance()
            .search({ searchText })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setChecklistModeles(response.data.data);
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, setLoading, setPaginationData]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (paginationData) {
            setChecklistModeles((checklistModeles) => [
                ...new Map(
                    [...checklistModeles, ...paginationData.data].map((item) => [
                        item["id"],
                        item,
                    ])
                ).values(),
            ]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const removeChecklistModele = (checklistModele) => {
        setIsDeleting(true);
        ChecklistModeleService.getInstance()
            .remove(checklistModele.uid)
            .then(() => {
                setChecklistModeles(
                    checklistModeles.filter((c) => c.id !== checklistModele.id)
                );
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };

    const handleChecklistModeleUpdate = (value, fieldName, record) => {
        ChecklistModeleService.getInstance()
            .update(record.uid, { ...record, [fieldName]: value })
            .then(() => {
                setChecklistModeles(
                    checklistModeles.map((c) =>
                        c.id === record.id ? { ...c, [fieldName]: value } : c
                    )
                );
                Toast.success("Modele de checklist mis à jour avec succès");
            })
            .catch((error) => {
                catchError(error);
            });
    };

    const columns = [
        {
            title: "Nom",
            dataIndex: "nom",
            key: "nom",
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            width: 400,
            render: (nom, record) => (
                <InlineEditableInput
                    canEdit={check("EDIT-CHECKLIST-MODELE")}
                    fieldName="nom"
                    onChange={handleChecklistModeleUpdate}
                    record={record}
                    value={nom}
                >
                    <div
                        style={{
                            maxWidth: '500px',
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden',     
                            textOverflow: 'ellipsis' 
                        }}
                    >
                        {nom}
                    </div>
                </InlineEditableInput>
            ),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: 300,
            render: (description, record) => (
                <InlineEditableInput fieldName="description" record={record} type="textarea"
                    value={description} onChange={handleChecklistModeleUpdate} canEdit={check("EDIT-CHECKLIST-MODELE")}>
                    <div
                        style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            maxWidth: 300,
                        }}
                    >
                        {description ? description : <span className="badge bg-secondary">Non renseigné</span>}
                    </div>
                </InlineEditableInput>
            ),
        },
        {
            title: "Actif",
            dataIndex: "enabled",
            key: "enabled",
            sorter: (a, b) => a.enabled.localeCompare(b.enabled),
            width: 80,
            render: (enabled, record) => (
                <InlineEditableInput
                    canEdit={check("EDIT-CHECKLIST-MODELE")}
                    fieldName="enabled"
                    onChange={handleChecklistModeleUpdate}
                    record={record}
                    value={enabled}
                    type="switch"
                >
                    {enabled ? (
                        <span className="badge bg-success">Oui</span>
                    ) : (
                        <span className="badge bg-danger">Non</span>
                    )}
                </InlineEditableInput>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            width: 150,
            render: (record) => (
                <Space>
                    {/* {check('VIEW-CONTACT') ? <Button onClick={() => setItemToView(record)} icon={<EyeOutlined />} className="btn btn-primary"></Button> : null} */}
                    {check("CONFIGURE-CHECKLIST-MODELS") ? (
                        <Button
                            onClick={() => setItemToConfigure(record)}
                            icon={<SettingOutlined />}
                            className="btn"
                        ></Button>
                    ) : null}
                    {check("DELETE-CHECKLIST-MODELE") ? (
                        <Popconfirm
                            okType="danger"
                            title="Voulez-vous vraiment supprimer ce modele de checklist ?"
                            onConfirm={() => removeChecklistModele(record)}
                            okText="Confirmer"
                            cancelText="Annuler"
                        >
                            <Button
                                loading={isDeleting}
                                icon={<DeleteFilled />}
                                className="btn btn-danger"
                            ></Button>
                        </Popconfirm>
                    ) : null}
                </Space>
            ),
        },
    ].filter((column) => column !== null);

    const loadMore = () => {
        if (hasMore && !loading) {
            setLoading(true);
            ChecklistModeleService.post(paginationData.next_page_url, { searchText })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                })
                .catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleselectedChecklistModele = (checklistModele) => {
        setNewModalVisible(false);
        setSelectedChecklistModele(checklistModele);
        setNewModalConfigurationVisible(true);
        setChecklistModeles([...checklistModeles, checklistModele]);
    };
    return (
        <Main>
            <ChecklistModeleForm
                visible={newModalVisible}
                onCreate={handleselectedChecklistModele}
                onCancel={() => setNewModalVisible(false)}
            />
            <ChecklistModeleGroupModal
                checklistModele={selectedChecklistModele}
                visible={newModalConfigurationVisible}
                onClose={() => setNewModalConfigurationVisible(false)}
            />
            {check("VIEW-CHECKLIST-MODELE") ? (
                <>
                    <PageTitle
                        title="Liste des modèles de checklists"
                        icon={<BuildOutlined />}
                        subTitle="Accéder et configurer la liste des modèles de checklists..."
                    >
                        {check("ADD-CHECKLIST-MODELE") ? (
                            <Button
                                onClick={() => setNewModalVisible(true)}
                                icon={<PlusCircleOutlined />}
                                type="primary"
                            >
                                Nouveau modèle de checklist
                            </Button>
                        ) : null}
                        <Button onClick={init} icon={<ReloadOutlined />} type="default">
                            Rafraîchir
                        </Button>
                    </PageTitle>
                    <Spin spinning={loading}>
                        <InfiniteTable
                            title={
                                <Row gutter={[16, 16]} justify={"center"}>
                                    <Col span={24}>
                                        <Divider>Filtres rapides</Divider>
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                        <Input.Search
                                            size="large"
                                            value={searchText}
                                            onInput={(e) => {
                                                setSearchText(e.target.value);
                                            }}
                                            placeholder="Rechercher un modèle de checklist"
                                        />
                                    </Col>
                                </Row>
                            }
                            dataSource={checklistModeles}
                            loading={loading}
                            columns={columns}
                            scroll={{ x: 1300, y: 500 }}
                            paginationData={paginationData}
                            loadMore={loadMore}
                        />
                    </Spin>
                </>
            ) : (
                <UnauthorizedMessage />
            )}
        </Main>
    );
}
