import React from 'react';
import { Divider, Modal, Button, Spin, Result } from 'antd';
import DocumentEvaluationGroupItemService from '../../services/DocumentEvaluationGroupItemService';
import { catchError } from '../../services/DaoService';
import { CheckCircleOutlined, SendOutlined } from '@ant-design/icons';
import DemandeCorrectionDocumentService from '../../services/DemandeCorrectionDocumentService';
import Toast from '../../helpers/Toast';
import DocumentEvaluationService from '../../services/DocumentEvaluationService';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function PreviewNotAcceptedByDocumentEvaluation({ visible, onClose, documentEvaluation }) {
    const [items, setItems] = React.useState([]);
    const [sending, setSending] = React.useState(false);
    const [observations, setObservations] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        if (!documentEvaluation || !visible) return;
        setLoading(true);
        DocumentEvaluationGroupItemService.getInstance()
            .findNotAcceptedByDocumentEvaluation(documentEvaluation.uid)
            .then(response => {
                setItems(response.data);
                setLoaded(true);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, [documentEvaluation, visible]);

    const handleObservationsChange = (value) => {
        setObservations(value);
    };

    const generateAndSend = () => {
        setSending(true);
        DemandeCorrectionDocumentService.getInstance()
            .create({ observations, documentEvaluationUid: documentEvaluation.uid })
            .then((response) => {
                setItems([]);
                setObservations('');
                onClose();
                Toast.success(response.message);
            }).catch(err => catchError(err))
            .finally(() => setSending(false));
    };

    const validateEvaluation = () => {
        setSending(true);
        DocumentEvaluationService.getInstance()
            .validate(documentEvaluation.uid)
            .then((response) => {
                onClose();
                Toast.success(response.message);
            }).catch(err => catchError(err))
            .finally(() => setSending(false));
    };

    return (
        <Modal
            width={"80%"}
            title="Liste des écarts notés lors de l'évaluation"
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <Spin spinning={loading}>
                <Divider />
                <div className="table-responsive">
                    {items.length > 0 ? (
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Index</th>
                                    <th scope="col">Nom</th>
                                    <th scope="col">Observation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.nom}</td>
                                        <td dangerouslySetInnerHTML={{ __html: item.observation }}></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <Result status={'success'} title="Aucun écart noté dans cette évaluation pour le moment." />
                    )}
                </div>
                <Divider />
                <ReactQuill
                    theme="snow"
                    value={observations}
                    onChange={handleObservationsChange}
                    placeholder="Préciser des observations avant de transmettre au client"
                    style={{ marginTop: "8px", width: "100%", borderRadius: "4px" }}
                    modules={{
                        toolbar: [
                            [{ 'color': [] }, { 'background': [] }], 
                            ['bold', 'italic', 'underline', 'strike'], 
                            [{ 'align': [] }]
                        ]
                    }}
                />
                <Divider />
                <Button icon={<SendOutlined />} loading={sending}
                    type="primary"
                    disabled={items.length === 0}
                    onClick={generateAndSend}
                    style={{ display: 'block', margin: '0 auto', marginTop: '20px' }}
                >
                    Soumettre à l'exploitant pour correction
                </Button>
                {loaded && items.length === 0 && (
                    <Button icon={<CheckCircleOutlined />} loading={sending}
                        type="primary" danger className='bg-success' size='large'
                        onClick={validateEvaluation}
                        style={{ display: 'block', margin: '0 auto', marginTop: '20px' }}
                    >
                        Valider l'évaluation
                    </Button>
                )}
            </Spin>
        </Modal>
    );
}
