import { DaoService } from "./DaoService";

export default class ContactService extends DaoService {
    static getInstance() {
        return super.getInstance('contact');
    }

    findByOrganisation(organisationUid) {
        return ContactService.get(`${this.prefix}/${organisationUid}/organisation`);
    }

    findAllByOrganisation(organisationUid) {
        return ContactService.get(`${this.prefix}/${organisationUid}/all`);
    }

    assignManager(contactUid) {
        return ContactService.put(`${this.prefix}/${contactUid}/assign-manager`);
    }

    removeManager(contactUid) {
        return ContactService.put(`${this.prefix}/${contactUid}/remove-manager`);
    }

    search(data) {
        return ContactService.post(`${this.prefix}/search`, data);
    }

}