import React from "react";
import { Button, Form, Select } from "antd";
import DomaineCategorieService from "../../services/DomaineCategorieService";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";

const { Option } = Select;

export default function DomaineTypesConfigFormItem({ domaineCategorieItem, typeDossiers, typeDocuments }) {

    const [selectedTypeDossierIds, setSelectedTypeDossierIds] = React.useState(domaineCategorieItem.typeDossierIds);
    const [selectedTypeDocumentIds, setSelectedTypeDocumentIds] = React.useState(domaineCategorieItem.typeDocumentIds);
    const [linking, setLinking] = React.useState(false);
    const [form] = Form.useForm(); 

    const handleTypeDossierChange = (value) => {
        setSelectedTypeDossierIds(value);
    };

    const handleTypeDocumentChange = (value) => {
        setSelectedTypeDocumentIds(value);
    };

    const save = () => {
        setLinking(true);
        DomaineCategorieService.getInstance().linkTypes(domaineCategorieItem.domaineCategorie.id, {
            typeDossierIds: selectedTypeDossierIds, 
            typeDocumentIds: selectedTypeDocumentIds
        })
            .then((response) => {
                Toast.success(response.message);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLinking(false);
            });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={save}
        >
            <Form.Item
                name="typeDocumentIds"
                label="Types de documents"
                initialValue={selectedTypeDocumentIds}
            >
                <Select
                    showSearch
                    optionFilterProp="label"
                    mode="multiple"
                    placeholder="Sélectionner des types de documents"
                    value={selectedTypeDocumentIds}
                    onChange={handleTypeDocumentChange}
                    optionLabelProp="label"
                >
                    {typeDocuments.map(typeDocument => (
                        <Option key={typeDocument.uid} value={typeDocument.id} label={typeDocument.nom}>
                            {typeDocument.nom}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="typeDossierIds"
                label="Types de dossiers"
                initialValue={selectedTypeDossierIds}
            >
                <Select
                    showSearch
                    optionFilterProp="label"
                    mode="multiple"
                    placeholder="Sélectionner des types de dossiers"
                    value={selectedTypeDossierIds}
                    onChange={handleTypeDossierChange}
                    optionLabelProp="label"
                >
                    {typeDossiers.map(typeDossier => (
                        <Option key={typeDossier.uid} value={typeDossier.id} label={typeDossier.nom}>
                            {typeDossier.nom}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button loading={linking} type="primary" htmlType="submit">
                    Enregistrer
                </Button>
            </Form.Item>
        </Form>
    );
}