import React from 'react';
import { Divider, Input, Modal, Spin, Table, Button, Card, Descriptions, Typography, Popconfirm } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import styles from './DemandeCorrectionDocumentDetailsModal.module.scss';
import EcartDocumentService from '../../services/EcartDocumentService';
import { catchError } from '../../services/DaoService';
import Toast from '../../helpers/Toast';
import { useUserContext } from '../../providers/UserProvider';
import PieceJointeList from '../piecejointe/PieceJointeList';
import DemandeCorrectionDocumentService from '../../services/DemandeCorrectionDocumentService';
import { usePusher } from "../../providers/PusherProvider";
const { Text } = Typography;

export default function DemandeCorrectionDocumentDetailsModal({ visible, onClose, demandeCorrectionDocument, setDemandeCorrectionDocument }) {
    const [ecartDocuments, setEcartDocuments] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [updating, setUpdating] = React.useState(false);
    const { currentUser } = useUserContext();
    const { pusher } = usePusher(); 
    React.useEffect(() => {
        if (!demandeCorrectionDocument || !visible) {
            return;
        }
        setLoading(true);
        EcartDocumentService.getInstance()
            .findByDemandeCorrectionDocument(demandeCorrectionDocument.uid)
            .then((response) => {
                setEcartDocuments(response.data);
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [demandeCorrectionDocument, visible]);

    React.useEffect(() => {
        if (!pusher || !demandeCorrectionDocument?.uid) {
            return;
        }
    
        const channel = pusher.subscribe(`demandecorrectiondocument.${demandeCorrectionDocument.uid}`);
        channel.bind('updated', function (data) {
            setDemandeCorrectionDocument(demandeCorrectionDocument => ({ ...demandeCorrectionDocument, ...data.updatedData }));
        });
    
        return () => {
            pusher.unsubscribe(`demandecorrectiondocument.${demandeCorrectionDocument.uid}`);
        };
    }, [pusher, setDemandeCorrectionDocument, demandeCorrectionDocument?.uid]);

    const handleRemarquesAppliqueesChange = ($e, record) => {
        const newEcartDocuments = ecartDocuments.map((ecart) => {
            if (ecart.uid === record.uid) {
                return { ...ecart, remarques_appliquees: $e.target.value };
            }
            return ecart;
        });
        setEcartDocuments(newEcartDocuments);
    };

    const handleRemarquesAppliqueesBlur = ($e, record) => {
        setUpdating(true);
        EcartDocumentService.getInstance()
            .update(record.uid, { remarques_appliquees: $e.target.value })
            .then((response) => {
                setEcartDocuments((ecartDocuments) => {
                    return ecartDocuments.map((ecart) => {
                        if (ecart.uid === record.uid) {
                            return { ...ecart, remarques_appliquees: $e.target.value };
                        }
                        return ecart;
                    });
                });
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setUpdating(false);
            });
    };
    
    const columns = [
        {
            // row number
            title: 'N°',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => index + 1,
            fixed: 'left',
            width: 50,
        },
        {
            title: 'Observations',
            dataIndex: 'observations',
            key: 'observations',
            width: 450,
            // force line break
            render: (text) => (
                <div style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    maxWidth: 450
                }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Référence des corrections',
            dataIndex: 'remarques_appliquees',
            key: 'remarques_appliquees',
            render: (text, record) => {
                return (
                    <>
                        {currentUser?.is_contact && !demandeCorrectionDocument.corrige ? (
                            <Input.TextArea
                                onChange={($e) => handleRemarquesAppliqueesChange($e, record)}
                                onBlur={($e) => handleRemarquesAppliqueesBlur($e, record)}
                                rows={1}
                                value={text}
                            />
                        ) : (
                            <div style={{
                                whiteSpace: 'pre-wrap',
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                maxWidth: 400
                            }}>
                                {text}
                            </div>
                        )}
                    </>
                );
            },
            width: 400,
        },
    ];

    const transmettreDocumentCorrige = () => {
        setUpdating(true);
        DemandeCorrectionDocumentService.getInstance()
            .transmettreDocumentCorrige(demandeCorrectionDocument.uid)
            .then((response) => {
                Toast.success(response.message);
                setDemandeCorrectionDocument(response.data);
                onClose();
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setUpdating(false);
            });
    }


    return (
        <Modal
            title="Détails - Demande Correction Document"
            open={visible}
            onCancel={onClose}
            footer={null}
            width="80%"
        >
            <Spin spinning={loading || updating}>
                <Divider />
                {currentUser?.is_contact && !demandeCorrectionDocument?.corrige && (<div className={styles.message}>
                    <p>
                        <strong>Important :</strong> Veuillez renseigner les corrections apportées pour chaque remarque
                        et ajouter les pièces jointes corrigées avant de transmettre les remarques.
                    </p>
                </div>)}
                {demandeCorrectionDocument ? (
                    <div className="mb-3">

                        <Card>
                            <Descriptions>
                                <Descriptions.Item label="Numéro">{demandeCorrectionDocument.numero}</Descriptions.Item>
                                <Descriptions.Item label="Date d'envoi">{demandeCorrectionDocument.date_envoie}</Descriptions.Item>
                                <Descriptions.Item label="Date de correction">{demandeCorrectionDocument.date_correction}</Descriptions.Item>
                                <Descriptions.Item label="Créé par">{demandeCorrectionDocument.created_by?.name}</Descriptions.Item>
                                <Descriptions.Item label="Observations">{demandeCorrectionDocument.observations ? demandeCorrectionDocument.observations : 'Aucune observation'}</Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </div>
                ) : (
                    <Text>Demande de correction de document non disponible</Text>
                )}

                <div className={styles.container}>
                    {ecartDocuments && ecartDocuments.length > 0 ? (
                        <>
                            <Table scroll={{ x: 900 }}
                                columns={columns}
                                dataSource={ecartDocuments}
                                rowKey={(record) => record.index}
                                pagination={false}
                            />
                            <Divider />
                            <PieceJointeList parentId={demandeCorrectionDocument?.id}
                                parentType={'demandeCorrectionDocument'} canAdd={currentUser?.is_contact}
                                canSee={true} candRemove={currentUser?.is_contact && !demandeCorrectionDocument.corrige} />
                            <Divider />
                            {currentUser?.is_contact  && !demandeCorrectionDocument.corrige && (  
                                <Popconfirm 
                                title="Êtes-vous sûr d'avoir terminé les corrections et prêt à transmettre pour approbation ?"
                                description="Verifiez aussi que toutes les pièces jointes corrigées sont bien ajoutées si nécessaire."
                                onConfirm={transmettreDocumentCorrige}
                                okText="Confirmer et transmettre" 
                                okType="danger" 
                                cancelText="Annuler et revoir le contenu"
                            >
                                <Button loading={updating} style={{ display: 'block', margin: '0 auto', marginTop: '20px' }}
                                    type="primary" icon={<SendOutlined />}>
                                    Transmettre les corrections pour approbation
                                </Button>
                            </Popconfirm>
                            )}
                        </>
                    ) : (
                        <p>Aucun écart documenté trouvé.</p>
                    )}
                </div>
            </Spin>
        </Modal>
    );
}
