import { Card, Form, Input, Modal, Switch } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import OrganisationService from "../../services/OrganisationService";


export default function OrganisationEdit({ visible, onUpdate, onCancel, uid }) {
    const [form] = Form.useForm();
    const [creating, setCreating] = React.useState(false);
    const [organisation, setOrganisation] = React.useState(null);

    React.useEffect(() => {
        if(!visible) return;
        if (uid) {
            OrganisationService.getInstance()
                .find(uid)
                .then(response => {
                    setOrganisation(response.data);
                    form.setFieldsValue(response.data);
                }).catch(error => {
                    console.error(error);
                });
        }
    }, [uid, form, visible]);

    const onFinish = (values) => {
        setCreating(true);
        OrganisationService.getInstance()
            .update(organisation.uid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onUpdate(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setCreating(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }


    return (
        <Modal open={visible} okText="Mettre à jour" okType="primary" cancelText="Annuler" onOk={form.submit} onCancel={onCancel}>
            <Card loading={creating}>
                <Form
                    form={form} name="editOrganisationForm"
                    layout="vertical"
                    onFinish={onFinish} onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Nom"
                        name="nom"
                        rules={[
                            { required: true, message: 'Ce champ est obligatoire!' },
                            { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Sigle"
                        name="sigle"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Responsable"
                        name="responsable"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Statut" name="enabled">
                        <Switch />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
}