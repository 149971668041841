import React from "react";
import { useUserContext } from "../../providers/UserProvider";
import Main from "../../components/layout/Main";
import QueueDetails from "../../components/queue/QueueDetails";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import { useParams } from "react-router-dom";

export default function QueueDetailsPage() {
    const { uid } = useParams();
    const { check } = useUserContext();
    return (
        <Main>
            {
                check('VIEW-CONTACT') ?
                    <QueueDetails queueUid={uid} />
                    : <UnauthorizedMessage />
            }
        </Main>
    );
} 