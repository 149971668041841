import { DaoService } from "./DaoService";

export default class QueueMemberService extends DaoService {
    static  getInstance() {
        return super.getInstance('queuemember');
    }

    findByQueue(queueUid) {
        return QueueMemberService.get(`${this.prefix}/${queueUid}/queue`);
    }

    linkUsersToQueue(uid, data) {
        return QueueMemberService.post(`${this.prefix}/${uid}/users`, data);
    }
    
}