import { Button, Card, Form, Result, Select, Spin } from "antd";
import React from "react";
import ChecklistModeleService from "../../services/ChecklistModeleService";
import { catchError } from "../../services/DaoService";
import DocumentEvaluationService from "../../services/DocumentEvaluationService";
import Toast from "../../helpers/Toast";

export default function DocumentEvaluationForm({ document, onCreate }) {
    const [form] = Form.useForm();
    const [checklistModeles, setChecklistModeles] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [creating, setCreating] = React.useState(false);

    React.useEffect(function () {
        if (!document?.type_dossier) return;
        // load checklistModele from document.type_dossier.uid
        ChecklistModeleService.getInstance()
            .findByDomaineCategorieAndTypeDossier(document.domaine_categorie_id, document.type_dossier.uid)
            .then(response => {
                setChecklistModeles(response.data);
            }).catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, [document]);


    const onFinish = (values) => {
        values.document_id = document.id;
        setCreating(true);
        DocumentEvaluationService.getInstance()
            .create(values)
            .then(response => {
                onCreate(response.data);
                Toast.success(response.message);
                form.resetFields();
            }).catch(err => catchError(err))
            .finally(() => {
                setCreating(false);
            });
    }


    return (
        <>
            {!document ? (
                <Spin />
            ) : document.closed && document.type_dossier ? (
                <Card>
                    <Result
                        status="warning"
                        title="Document Clôturé"
                        subTitle="Vous ne pouvez pas créer d'évaluation pour un document déjà clôturé."
                    />
                </Card>
            ) : !document.type_dossier ? (
                <Card>
                    <Result
                        status="warning"
                        title="Document non classé par type de dossier"
                        subTitle="Aucun modèle d'évaluation n'est défini pour les documents sans type de document spécifié."
                    />
                </Card>
            ) : (
                <Card title="Veuillez choisir un modèle de checklist à appliquer....">
                    <Form onFinish={onFinish} form={form}>
                        <Form.Item
                            name="checklist_modele_id"
                            label="Modèle de checklist"
                            rules={[{ required: true, message: "Veuillez choisir un modèle de checklist" }]}
                        >
                            <Select loading={loading} placeholder="Choisir un modèle de checklist">
                                {checklistModeles.map(checklistModele => (
                                    <Select.Option key={checklistModele.uid} value={checklistModele.id}>
                                        {checklistModele.nom}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button loading={creating} htmlType="submit" type="primary">
                                Initier l'évaluation
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            )}
        </>
    );    


}