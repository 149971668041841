import { DaoService } from "./DaoService";

export default class StatutDocumentService extends DaoService {
    static getInstance() {
        return super.getInstance('statutdocument');
    }

    all() {
        return StatutDocumentService.get(`${this.prefix}/all`);
    }
}