import { DaoService } from "./DaoService";

export default class ChecklistModeleGroupService extends DaoService {
    static getInstance() {
        return super.getInstance('checklistmodelegroup');
    }

    async findByChecklistModele(uid) {
        return ChecklistModeleGroupService.get(`${this.prefix}/${uid}/checklistmodele`);
    }
}