import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Row, Col, Card, Select } from 'antd';
import { catchError } from '../../services/DaoService';
import Toast from '../../helpers/Toast';
import UserService from '../../services/UserService';
import UserFonctionService from '../../services/UserFonctionService';
import PhoneInput from 'react-phone-number-input';

const { Option } = Select;

const UserEdit = ({ visible, uid, onUpdate, onCancel }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [userFonctions, setUserFonctions] = React.useState([]);

    useEffect(() => {
        setLoading(true);
        UserFonctionService.getInstance()
            .findAll()
            .then((response) => {
                setUserFonctions(response.data);
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []); // Assurez-vous de déclencher cet effet uniquement lorsque la modal devient visible

    React.useEffect(() => {
        if (!visible) return;
        setLoading(true);
        UserService.getInstance().find(uid)
            .then((userResponse) => {
                // Préremplir le formulaire avec les données de l'utilisateur
                form.setFieldsValue(userResponse.data);

            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [uid, visible, form]);


    const onFinish = (values) => {
        setLoading(true);
        UserService.getInstance()
            .update(uid, values)
            .then((response) => {
                Toast.success(response.message);
                onUpdate(response.data);
            }).catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            open={visible}
            title="Modifier l'utilisateur"
            okText="Enregistrer"
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields().then((values) => {
                    onFinish(values);
                }).catch((info) => {
                    console.log('Validate Failed:', info);
                });
            }}
        >
            <Card loading={loading} style={{ marginBottom: 20 }}>
                <Form layout='vertical' form={form}>
                    <Row gutter={24}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Veuillez saisir l\'adresse email!' },
                                    { type: 'email', message: 'Veuillez saisir une adresse email valide!' }
                                ]}
                            >
                                <Input disabled type="email" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Nom"
                                name="name"
                                rules={[
                                    { required: true, message: 'Veuillez saisir le nom!' },
                                    { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Téléphone"
                                name="telephone"
                                rules={[
                                    { required: true, message: 'Ce champ est obligatoire!' }
                                ]}
                            >
                                <PhoneInput defaultCountry="SN"
                                    placeholder="Saisir le numéro de téléphone" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="user_fonction_id"
                                label="Fonction"
                                rules={[{ required: true, message: 'Veuillez sélectionner une fonction' }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Sélectionnez une fonction"
                                >
                                    {userFonctions.map(userFonction => (
                                        <Option key={userFonction.id} value={userFonction.id} disabled={!userFonction.enabled} >{userFonction.nom}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal>
    );
};

export default UserEdit;
