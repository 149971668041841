import React, { useCallback } from "react";
import Main from "../../components/layout/Main";
import PageTitle from "../../components/utils/PageTitle";
import { ReloadOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Card, Collapse } from "antd";
import { catchError } from "../../services/DaoService";
import DomaineService from "../../services/DomaineService";
import OrganisationService from "../../services/OrganisationService";
import CategorieExploitantService from "../../services/CategorieExploitantService";
import DomaineConfigItem from "../../components/domaine/DomaineConfigItem";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";


export default function DomaineConfigPage() {

    const [domaines, setDomaines] = React.useState([]);
    const [categorieExploitants, setCategorieExploitant] = React.useState([]);
    const [exploitants, setExploitants] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [activeKey, setActiveKey] = React.useState([]);

    const init = useCallback(() => {
        setLoading(true);
        Promise.all([
            DomaineService.getInstance().all(),
            CategorieExploitantService.getInstance().all(),
            OrganisationService.getInstance().all()
        ]).then(([domainesResponse, categorieExploitantsResponse, exploitantsResponse]) => {
            setDomaines(domainesResponse.data);
            if (domainesResponse.data.length > 0)
                setActiveKey([domainesResponse.data[0].code]);
            setCategorieExploitant(categorieExploitantsResponse.data);
            setExploitants(exploitantsResponse.data);
        }).catch(error => {
            catchError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, [setDomaines, setCategorieExploitant, setExploitants, setLoading, setActiveKey]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        setItems(domaines.map(domaine => {
            return {
                key: domaine.code,
                label: domaine.nom,
                children: activeKey[0] === domaine.code ? <DomaineConfigItem domaine={domaine} categorieExploitants={categorieExploitants} exploitants={exploitants} /> : <LoadingPlaceholder />
            }
        }));
    }, [domaines, categorieExploitants, exploitants, activeKey]);

    const onChange = (key) => {
        setActiveKey(key);
    }

    return (
        <Main>
            <PageTitle icon={<SettingOutlined />} title="Configuration du domaine et des exploitants"
                subTitle="Paramétrer tous les domaines, catégories exploitants et exploitant en un endroit.">
                <Button type="default" icon={<ReloadOutlined />} loading={loading} onClick={init}>Rafraîchir</Button>
            </PageTitle>
            <Card classNames={{
                body: 'p-0'
            }} loading={loading}>
                <Collapse accordion items={items} defaultActiveKey={activeKey} onChange={onChange} />
            </Card>
        </Main>
    );
}