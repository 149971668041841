import React, { useCallback } from "react";
import DocumentEvaluationGroupService from "../../services/DocumentEvaluationGroupService";
import { catchError } from "../../services/DaoService";
import {
  Form,
  Spin,
  Button,
  Divider,
  Result,
  Space,
  Typography,
  List,
} from "antd";
import { FileOutlined, CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import PreviewNotAcceptedByDocumentEvaluation from "../documentevaluationgroupitem/PreviewNotAcceptedByDocumentEvaluation";
import { NavLink } from "react-router-dom";
import EvaluationFormGroup from "./EvaluationFormGroup";
import { useUserContext } from "../../providers/UserProvider";

const { Text } = Typography;

export default function DocumentEvaluationChecklist({
  documentEvaluation,
  refreshDocumentEvaluation,
}) {
  const [groupData, setGroupData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [isNAPreviewOpened, setIsNAPreviewOpened] = React.useState(false);
  const { currentUser } = useUserContext(); // Assure-toi que currentUser est bien défini

  // Initialisation des données des groupes
  const init = useCallback(() => {
    if (!documentEvaluation) return;
    DocumentEvaluationGroupService.getInstance()
      .findByDocumentEvaluation(documentEvaluation.uid)
      .then((response) => {
        setGroupData(response.data);
      })
      .catch((err) => catchError(err))
      .finally(() => {
        setLoading(false);
      });
  }, [documentEvaluation]);

  // Récupération des données lors du premier rendu
  React.useEffect(() => {
    init();
  }, [init]);

  // Gestion de la fermeture de la fenêtre de prévisualisation
  const handleNAPreviewClose = () => {
    setIsNAPreviewOpened(false);
    refreshDocumentEvaluation();
  };

  return (
    <Spin spinning={loading} tip="Chargement...">
      <Form layout="vertical">
        {groupData.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <Text strong>Les termes utilisés sont :</Text>
            <List
              size="small"
              style={{ marginTop: "10px" }}
              dataSource={[
                {
                  icon: <CheckCircleOutlined style={{ color: "green" }} />,
                  text: "S - Satisfaisant",
                },
                {
                  icon: <CloseCircleOutlined style={{ color: "red" }} />,
                  text: "N/S - Non Satisfaisant",
                },
                {
                  icon: <MinusCircleOutlined style={{ color: "gray" }} />,
                  text: "N/A - Non Applicable",
                },
              ]}
              renderItem={(item) => (
                <List.Item>
                  <Space>
                    {item.icon}
                    <Text>{item.text}</Text>
                  </Space>
                </List.Item>
              )}
            />
          </div>
        )}

        {/* Affichage des groupes d'évaluation */}
        {groupData.map((group) => (
          <EvaluationFormGroup
            key={group.documentEvaluationGroup.uid}
            documentEvaluation={documentEvaluation}
            group={group}
            isAdmin={currentUser?.is_admin}
            currentUserId={currentUser?.id}
          />
        ))}
      </Form>
      <Divider />
      {!documentEvaluation?.closed && !documentEvaluation.validated ? (
        !documentEvaluation?.has_active_demande_correction &&
          (currentUser?.is_admin || documentEvaluation.document.responsable_id === currentUser?.id) ? (
          <Space
            direction="vertical"
            style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
          >
            <Button
              onClick={() => setIsNAPreviewOpened(true)}
              type="primary"
              icon={<FileOutlined />}
              style={{ borderRadius: "4px" }}
            >
              Générer rapport écarts et transmettre
            </Button>
            <NavLink to={documentEvaluation?.report_link} target="_blank">
              <Button
                danger
                icon={<FileOutlined />}
                style={{ borderRadius: "4px" }}
              >
                Générer le rapport PDF
              </Button>
            </NavLink>
          </Space>
        ) : (
          <Result
            status="info"
            title="Une révision est en cours par l'exploitant"
            subTitle="Vous devez attendre la fin de la révision pour transmettre d'autres écarts"
          />
        )
      ) : (
        <Result
          status="success"
          title="Evaluation terminée"
          subTitle="Vous ne pouvez plus modifier l'évaluation car le document est clos."
        />
      )}
      <PreviewNotAcceptedByDocumentEvaluation
        visible={isNAPreviewOpened}
        onClose={handleNAPreviewClose}
        documentEvaluation={documentEvaluation}
      />
    </Spin>
  );
}
