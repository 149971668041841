import React, { useState, useCallback } from 'react';
import { Checkbox, Input, Select, Space, Switch, Typography } from 'antd';
import { EditFilled } from '@ant-design/icons';

export const InlineEditableInput = ({ 
    value, 
    record, 
    onChange, 
    fieldName, 
    children,
    canEdit=false,
    type = "text", 
    options = [] 
}) => {
    if (!onChange || !fieldName) {
        throw new Error('InlineEditableInput: All props (value, onChange, fieldName) are required.');
    }

    const [inputValue, setInputValue] = useState(value);
    const [isEditing, setIsEditing] = useState(false);

    React.useEffect(() => {
        setInputValue(value);
    }, [value]);

    const toggleEdit = useCallback(() => setIsEditing(prev => !prev), []);
    
    const handleChange = useCallback(e => {
        const newValue = e?.target ? e.target.value : e;
        setInputValue(newValue);
    }, []);

    const save = useCallback(() => {
        onChange(inputValue, fieldName, record);
        setIsEditing(false);
    }, [inputValue, onChange, fieldName, record]);

    // Configuration des propriétés communes
    const inputProps = {
        size: "small",
        value: inputValue,
        onChange: handleChange,
        onBlur: save,
        onPressEnter: save,
        autoFocus: true
    };

    const renderInput = () => {
        switch (type) {
            case 'number':
                return <Input style={{ width: '100%' }} {...inputProps} type="number" />;
            case 'date':
                return <Input style={{ width: '100%' }} {...inputProps} type="date" />;
            case 'datetime-local':
                return <Input style={{ width: '100%' }} {...inputProps} type="datetime-local" />;
            case 'email':
                return <Input style={{ width: '100%' }} {...inputProps} type="email" />;
            case 'password':
                return <Input style={{ width: '100%' }} {...inputProps} type="password" />;
            case 'textarea':
                return <Input.TextArea {...inputProps} />;
            case 'checkbox':
                return <Checkbox checked={inputValue} onChange={e => handleChange(e.target.checked)} onBlur={save} autoFocus />;
            case 'switch':
                return <Switch checked={inputValue} onChange={handleChange} onBlur={save} autoFocus />;
            case 'select':
                return (
                    <Select 
                        size="small"
                        style={{ width: '100%' }} 
                        value={inputValue} 
                        onChange={handleChange} 
                        onBlur={save} 
                        autoFocus
                        showSearch 
                        optionFilterProp="children"
                    >
                        {options.map(option => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                );
            default:
                return <Input {...inputProps} />;
        }
    };

    return (
        <div style={{minWidth: "200px"}}>
            {isEditing ? renderInput() : (
                <Space>
                    <Typography.Text>{children}</Typography.Text>
                    {!!canEdit && (<EditFilled onClick={toggleEdit} />) }
                </Space>
            )}
        </div>
    );
};
