import { DaoService } from "./DaoService";

export default class PieceJointeService extends DaoService {
    // déclare une constante pour les types : organisation,user
    static TYPES = {
        ORGANISATION: 'organisation',
        USER: 'user',
        DOCUMENT: 'document',
        CONTACT: 'contact',
        DOCUMENTEVALUATION:'documentevaluation',
    };

    static getInstance() {
        return super.getInstance('piecejointe');
    }

    findByParent(type, parentId) {
        return PieceJointeService.get(`${this.prefix}/${type}/${parentId}`);
    }

    create(data, onUploadProgress) {
        return PieceJointeService.upload(`${this.prefix}`, data, onUploadProgress);
    }
    
    getPreviewUrl(fileId) {
        return PieceJointeService.download(`${this.prefix}/${fileId}`);
    }
}