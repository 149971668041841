import React, { useCallback } from "react";
import Main from "../../components/layout/Main";
import PageTitle from "../../components/utils/PageTitle";
import { ReloadOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Card, Collapse } from "antd";
import { catchError } from "../../services/DaoService";
import TypeDossierService from "../../services/TypeDossierService";
import TypeDocumentService from "../../services/TypeDocumentService";
import DomaineService from "../../services/DomaineService";
import LoadingPlaceholder from "../../components/utils/LoadingPlaceholder";
import DomaineTypesConfigItem from "../../components/domaine/DomaineTypesConfigItem";

export default function DomaineTypesConfigPage() {
    const [loading, setLoading] = React.useState(false);
    const [domaines, setDomaines] = React.useState([]);
    const [typeDossiers, setTypeDossiers] = React.useState([]);
    const [typeDocuments, setTypeDocuments] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [activeKey, setActiveKey] = React.useState([]);

    const init = useCallback(() => {
        setLoading(true);
        Promise.all([
            DomaineService.getInstance().all(),
            TypeDossierService.getInstance().all(),
            TypeDocumentService.getInstance().all()
        ]).then(([domainesResponse, typeDossiersResponse, typeDocumentsResponse]) => {
            setDomaines(domainesResponse.data);
            if (domainesResponse.data.length > 0)
                setActiveKey([domainesResponse.data[0].code]);
            setTypeDossiers(typeDossiersResponse.data);
            setTypeDocuments(typeDocumentsResponse.data);
        }).catch(error => {
            catchError(error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        setItems(domaines.map(domaine => {
            return {
                key: domaine.code,
                label: domaine.nom,
                children: activeKey[0] === domaine.code ?
                    <DomaineTypesConfigItem domaine={domaine} typeDossiers={typeDossiers}
                        typeDocuments={typeDocuments} /> : <LoadingPlaceholder />
            }
        }));
    }, [domaines, activeKey, typeDossiers, typeDocuments]);

    const onChange = (key) => {
        setActiveKey(key);
    }

    return (
        <Main>
            <PageTitle icon={<SettingOutlined />} title="Configuration du domaine et des types documents & dossiers"
                subTitle="Paramétrer tous les types de documents et les types de dossiers rattachés aux domaines">
                <Button type="default" icon={<ReloadOutlined />} loading={loading} onClick={init}>Rafraîchir</Button>
            </PageTitle>
            <Card classNames={{ body: 'p-0' }} loading={loading}>
                <Collapse accordion items={items} defaultActiveKey={activeKey} onChange={onChange} />
            </Card>
        </Main>
    );
}