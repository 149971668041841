import React from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import styles from "./ActivityForm.module.scss";
import ActiviteService from "../../services/ActiviteService";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";

const { Option } = Select;

const ActivityForm = ({
  visible,
  onClose,
  onAddActivity,
  parentId,
  target,
  users,
}) => {
  const [form] = Form.useForm();
  const [activityType, setActivityType] = React.useState("");
  const [isInserting, setIsInserting] = React.useState(false);

  const handleTypeChange = (value) => {
    setActivityType(value);
  };

  const onFinish = (values) => {
    if (!values.done) {
      values.done = false;
    }
    values.parent_id = parentId;
    values.target = target;
    setIsInserting(true);
    ActiviteService.getInstance()
      .create(values)
      .then((response) => {
        onAddActivity(response.data);
        form.resetFields();
        onClose();
        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setIsInserting(false);
      });
  };

  return (
    <Modal
      title="Ajouter une activité"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <hr />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className={styles.activityForm}
      >
        <Form.Item
          name="type"
          label="Type de l'activité"
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner le type d'activité",
            },
          ]}
        >
          <Select onChange={handleTypeChange}>
            <Option value="Appel">Appel</Option>
            <Option value="Reunion">Réunion</Option>
            <Option value="Tache">Tâche</Option>
            <Option value="Rappel">Rappel</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="nom"
          label="Titre de l'activité"
          rules={[
            {
              required: true,
              message: "Veuillez renseigner le titre de l'activité",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {activityType === "Reunion" && (
          <Form.Item name="start_at" label="Date et heure de début">
            <Input type="datetime-local" />
          </Form.Item>
        )}
        <Form.Item
          name="end_at"
          label="Date et heure d'échéance"
          rules={[
            {
              required: true,
              message: "Veuillez sélectionner la date et l'heure d'échéance",
            },
          ]}
        >
          <Input type="datetime-local" />
        </Form.Item>

        <Form.Item name="whoid" label="Assigné à (moi-même si non renseigné)">
          <Select showSearch optionFilterProp="children">
            {users.map((user) => (
              <Option key={user.id} value={user.id}>
                {user.name} ({user.email})
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            loading={isInserting}
            type="primary"
            htmlType="submit"
            className="float-end"
          >
            Enregistrer
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ActivityForm;
