import React from 'react';
import styles from './Path.module.scss';
import PropTypes from 'prop-types';
import { Button } from 'antd';

export default function Path({
    currentStep,
    steps,
    onCurrentPathChange, loading = false, readOnly = false
}) {
    const [selected, setSelected] = React.useState(null);

    const markAsCurrent = () => {
        const currentStepObj = steps.find(step => step.code === selected);
        onCurrentPathChange(currentStepObj);
    };

    const handlePathClick = (step) => {
        if (!readOnly) {
            setSelected(step);
        }
    };

    React.useEffect(() => {
        if (!loading) setSelected(null);
    }, [loading]);

    const isStepCompleted = (index) => {
        return steps.findIndex(step => step.code === currentStep) >= index;
    };

    return (
        <div className={styles.pathContainer}>
            {steps.map((stepItem, index) => {
                const isCompleted = isStepCompleted(index);
                const isSelected = selected === stepItem.code;

                return (
                    <div
                        onClick={() => handlePathClick(stepItem.code)}
                        key={index}
                        title={stepItem.nom}
                        className={`${styles.pathStep} ${isCompleted ? styles.completed : ''} ${isSelected ? styles.selected : ''} ${index === steps.length - 1 ? styles.lastStep : ''}`}
                    >
                        <div className={styles.pathLabel}>{stepItem.nom}</div>
                    </div>
                );
            })}
            {!readOnly && (
                <Button disabled={!selected} loading={loading} onClick={markAsCurrent} className={styles.completeButton}>
                    Enregistrer
                </Button>
            )}
        </div>
    );
}

Path.propTypes = {
    currentStep: PropTypes.string.isRequired,
    steps: PropTypes.array.isRequired,
    onCurrentPathChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    readOnly: PropTypes.bool,
};
