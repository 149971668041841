import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Button, Popconfirm, Typography, Avatar } from "antd";
import {
  EyeOutlined,
  EditFilled,
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  UserAddOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import styles from "./ContactCard.module.scss";
import ContactService from "../../services/ContactService";
import { catchError } from "../../services/DaoService";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../providers/UserProvider";
import ContactEdit from "./ContactEdit";
import Toast from "../../helpers/Toast";
import UserService from "../../services/UserService";

const { Meta } = Card;
const { Text } = Typography;

const ContactCard = ({ item, onDelete, organisation, onAssignManager }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const navigate = useNavigate();
  const { check } = useUserContext();
  const [contact, setContact] = useState(item);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [isUpdatingManager, setIsUpdatingManager] = useState(false);

  const removeContact = (contact) => {
    setIsDeleting(true);
    ContactService.getInstance()
      .remove(contact.uid)
      .then((response) => {
        Toast.success(response.message);
        onDelete(contact);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const openEditModal = () => {
    setEditModalVisible(true);
  };

  const handleContactUpdate = (updatedContact) => {
    setEditModalVisible(false);
    setContact(updatedContact);
  };

  const setItemToView = (contact) => {
    navigate(`/contact/${contact.uid}`);
  };

  const createAccount = () => {
    let user = {
      name: contact.name,
      email: contact.email,
      contact_id: contact.id,
      telephone: contact.telephone,
    };
    setCreatingAccount(true);
    UserService.getInstance()
      .create(user)
      .then((response) => {
        Toast.success(response.message);
        setContact((contact) => ({ ...contact, user_id: response.data.id }));
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setCreatingAccount(false);
      });
  };

  const assignManager = () => {
    setIsUpdatingManager(true);
    ContactService.getInstance()
      .assignManager(contact.uid)
      .then((response) => {
        onAssignManager();
        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setIsUpdatingManager(false));
  };

  const removeManager = () => {
    setIsUpdatingManager(true);
    ContactService.getInstance()
      .removeManager(contact.uid)
      .then((response) => {
        setContact(response.data);

        Toast.success(response.message);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setIsUpdatingManager(false));
  };

  return (
    <>
      <ContactEdit
        onUpdate={handleContactUpdate}
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        uid={contact.uid}
      />
      <Card hoverable className={styles.contactCard}>
        <Meta
          avatar={<Avatar size="large" icon={<UserOutlined />} />}
          title={`${contact.prenoms} ${contact.nom}`}
          description={
            <div className={styles.contactDetails}>
              <div>
                <Text>
                  <MailOutlined />{" "}
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </Text>
              </div>
              <div>
                <Text>
                  <PhoneOutlined />{" "}
                  <a href={`tel:${contact.telephone}`}>{contact.telephone}</a>
                </Text>
              </div>
              <div>
                <Text>
                  <UserOutlined />{" "}
                  {contact.fonction ?? "Fonction non renseignée"}
                </Text>
              </div>
            </div>
          }
        />
        <div className={styles.actionButtons}>
          {check("VIEW-CONTACT") && (
            <Button
              title="Voir détails"
              onClick={() => setItemToView(contact)}
              icon={<EyeOutlined />}
              className={styles.viewButton}
            />
          )}
          {check("EDIT-CONTACT") && (
            <Button
              title="Editer"
              onClick={() => openEditModal()}
              icon={<EditFilled />}
              className={styles.editButton}
            />
          )}
          {check("ADD-USER") && !contact?.user_id && (
            <Button
              loading={creatingAccount}
              title="Créer un compte utilisateur"
              onClick={createAccount}
              icon={<UserAddOutlined />}
              className={styles.createAccountButton}
            />
          )}
          {check("DELETE-CONTACT") && (
            <Popconfirm
              okType="danger"
              title="Voulez-vous vraiment supprimer ce contact?"
              onConfirm={() => removeContact(contact)}
              okText="Confirmer"
              cancelText="Annuler"
            >
              <Button
                title="Supprimer le contact"
                loading={isDeleting}
                disabled={!organisation?.accepted}
                type="primary"
                danger
                icon={<DeleteOutlined />}
                className={styles.deleteButton}
              />
            </Popconfirm>
          )}
          {check("EDIT-CONTACT") &&
            (contact.manager ? (
              <Popconfirm
                title="Voulez-vous vraiment retirer le statut de manager ?"
                onConfirm={removeManager}
                okText="Oui"
                cancelText="Non"
              >
                <Button
                  loading={isUpdatingManager}
                  title="Retirer le statut de manager"
                  icon={<StarFilled />}
                  className={styles.managerButton}
                />
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Voulez-vous définir ce contact comme manager ?"
                onConfirm={assignManager}
                okText="Oui"
                cancelText="Non"
              >
                <Button
                  loading={isUpdatingManager}
                  title="Définir comme manager"
                  icon={<StarOutlined />}
                  className={styles.managerButton}
                />
              </Popconfirm>
            ))}
        </div>
      </Card>
    </>
  );
};

ContactCard.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ContactCard;
