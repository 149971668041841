import React from "react";
import { Form, Input, Button, Switch } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { catchError, storeToken } from "../../services/DaoService";
import Toast from "../../helpers/Toast";
import { useUserContext } from "../../providers/UserProvider";
import styles from "./LoginForm.module.scss";
import UserService from "../../services/UserService";

export default function LoginForm() {
  const [loading, setLoading] = React.useState(false);
  const { setCurrentUser } = useUserContext();
  const [step, setStep] = React.useState(1);
  const [uid, setUid] = React.useState(null);
  const navigate = useNavigate();
  
  const onFinish = (values) => {
    setLoading(true);
    UserService.getInstance()
      .login(values)
      .then((response) => {
        setLoading(false);
        Toast.success(response.message || "Connexion réussie");
        setUid(response.data.uid);
        setStep(2);
      })
      .catch((error) => {
        catchError(error);
        setLoading(false);
      });
  };

  const onCodeFormFinish = (values) => {
    setLoading(true);
    const data = {
      ...values,
      uid: uid,
    };
    UserService.getInstance()
      .verifyOneTimePassword(data)
      .then((response) => {
        setLoading(false);
        storeToken(response.data.token);
        Toast.success(response.message || "Vérification réussie");
        setCurrentUser(response.data.user);
        const newExploitant = response.data.user.organisation;
        if (!newExploitant?.accepted) {
          navigate('/document');
        } else {
          navigate('/dashboard');
        }
      })
      .catch((error) => {
        catchError(error);
        setLoading(false);
      });
  };

  return (
    <div className={styles.loginForm}>
      {step === 1 ? (
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Adresse Email"
            name="email"
            rules={[
              { required: true, message: "Veuillez entrer votre email !" },
              { type: "email", message: "Adresse email invalide !" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Mot de passe"
            name="password"
            rules={[
              { required: true, message: "Veuillez entrer votre mot de passe !" },
            ]}
          >
            <Input.Password placeholder="Mot de passe" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Switch defaultChecked />
            <span className={styles.rememberText}>Se souvenir de moi</span>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginButton}
              loading={loading}
            >
              Connexion
            </Button>
          </Form.Item>
          <Form.Item>
            <NavLink to={`/forgot-password`}>
              Mot de passe oublié ?
            </NavLink>
          </Form.Item>
        </Form>
      ) : (
        <Form onFinish={onCodeFormFinish} layout="vertical">
          <Form.Item
            label="Code de vérification"
            name="otp"
            rules={[
              { required: true, message: "Veuillez entrer le code de vérification !" },
              { len: 6, message: "Le code doit contenir 6 caractères !" },
            ]}
          >
            <Input placeholder="Code de vérification" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.loginButton}
              loading={loading}
            >
              Valider
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
