import { Button, Card, Divider, Form, Select, Spin } from "antd";
import React from "react";
import { catchError } from "../../services/DaoService";
import DomaineService from "../../services/DomaineService";
import DomaineCategorieService from "../../services/DomaineCategorieService";
import Toast from "../../helpers/Toast";
import DomaineCategorieConfigItem from "./DomaineCategorieConfigItem";
import styles from "./DomaineConfigItem.module.scss"; // Import du fichier SCSS

const { Option } = Select;

export default function DomaineConfigItem({ domaine, categorieExploitants, exploitants }) {
    const [loading, setLoading] = React.useState(false);
    const [selectedCategorieExploitantIds, setSelectedCategorieExploitantIds] = React.useState([]);
    const [domaineCategorieData, setDomaineCategorieData] = React.useState([]);
    const [linking, setLinking] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        DomaineService.getInstance().findWithDataByUid(domaine.uid)
            .then(response => {
                setSelectedCategorieExploitantIds(response.data.selectedCategorieExploitantIds);
                setDomaineCategorieData(response.data.domaineCategorieData);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [domaine]);

    const handleChange = (value) => {
        setSelectedCategorieExploitantIds(value);
    };

    const save = () => {
        setLinking(true);
        DomaineCategorieService.getInstance().linkCategorieExploitants(domaine.uid, { categorieExploitantIds: selectedCategorieExploitantIds })
            .then(response => {
                setDomaineCategorieData(response.data);
                Toast.success(response.message);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLinking(false);
            });
    };

    return (
        <Card classNames={{body: 'p-0'}} className={styles.domaineCard} loading={loading} title={domaine.name}>
            {loading ? (
                <Spin />
            ) : (
                <>
                    <Form layout="vertical" onFinish={save} className={styles.domaineForm}>
                        <Form.Item
                            name="categories"
                            label="Catégories d'exploitants"
                            initialValue={selectedCategorieExploitantIds}
                            rules={[{ required: true, message: 'Veuillez sélectionner des catégories d\'exploitants' }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="label"
                                mode="multiple"
                                className={styles.selectInput}
                                placeholder="Sélectionner des catégories d'exploitants"
                                value={selectedCategorieExploitantIds}
                                onChange={handleChange}
                                optionLabelProp="label"
                            >
                                {categorieExploitants.map(categorieExploitant => (
                                    <Option key={categorieExploitant.uid} value={categorieExploitant.id} label={categorieExploitant.nom}>
                                        {categorieExploitant.nom}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button loading={linking} type="primary" htmlType="submit" className={styles.submitButton}>
                                Enregistrer
                            </Button>
                        </Form.Item>
                    </Form>
                    <Divider />
                    <div className={styles.domaineCategorieList}>
                        {domaineCategorieData.map((domaineCategorieItem, index) => (
                            <DomaineCategorieConfigItem key={index} domaineCategorieItem={domaineCategorieItem} exploitants={exploitants} />
                        ))}
                    </div>
                </>
            )}
        </Card>
    );
}
