import { DaoService } from "./DaoService";

export default class DocumentEvaluationMemberService extends DaoService {

    static getInstance() {
        return super.getInstance('documentevaluationmember');
    }
    
    findByDocumentEvaluation(uid) {
        return DocumentEvaluationMemberService.get(`${this.prefix}/${uid}/documentevaluation`);
    }

    linkMembersToDocumentEvaluation(uid, data) {
        return DocumentEvaluationMemberService.post(`${this.prefix}/${uid}/members`, data);
    }

    signEvaluation(uid, data) {
        return DocumentEvaluationMemberService.post(`${this.prefix}/${uid}/sign-evaluation`, data);
    }
}