import { Card, Form, Modal, Select, Spin } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import UserService from "../../services/UserService";
import DocumentEvaluationMemberService from "../../services/DocumentEvaluationMemberService";

export default function DocumentEvaluationMemberNew({ visible, onSave, onCancel, documentEvaluation }) {
    const [form] = Form.useForm();
    const [creating, setCreating] = React.useState(false);
    const [membreOptions, setMembreOptions] = React.useState([]);
    const [unattachedUsers, setUnattachedUsers] = React.useState([]);

    React.useEffect(() => {
        if (!visible) return;
        Promise.all([
            UserService.getInstance().all(),
            UserService.getInstance().getUserIdsAttachedToDocumentEvaluation(documentEvaluation.uid)
        ]).then(([allUsersResponse, attachedUserIdsResponse]) => {
            const allUsers = allUsersResponse.data;
            const attachedUserIds = attachedUserIdsResponse.data;
            const unattached = allUsers.filter(user => {
                return !attachedUserIds.some(attachedUserId => attachedUserId === user.id);
            });
            setUnattachedUsers(unattached);
        }).catch((error) => {
            catchError(error);
        }).finally(() => {
        });
    }, [documentEvaluation, visible]);

    React.useEffect(() => {
        const options = unattachedUsers.map(item => ({
            label: `${item.name} <${item.email}>`, value: item.id, key: item.id
        }));
        setMembreOptions(options);
    }, [unattachedUsers]);


    const handleAddMembers = (values) => {
        setCreating(true);
        DocumentEvaluationMemberService.getInstance()
            .linkMembersToDocumentEvaluation(documentEvaluation.uid, values)
            .then(response => {
                Toast.success(response.message);
                form.resetFields();
                onSave(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setCreating(false);
            });
    };

    const onFailed = (errorInfo) => {
        catchError(errorInfo);
    }

    return (
        <Modal open={visible} okText="Enregistrer" cancelText="Annuler" onOk={form.submit} onCancel={onCancel}>
            <Card>
                <Spin spinning={creating}>
                    <Form form={form} layout="vertical" onFinish={handleAddMembers} onFinishFailed={onFailed}>
                        <Form.Item label="Ajouter des membres" name="userIds">
                            <Select
                                mode="multiple" optionFilterProp="label"
                                allowClear
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Sélectionnez des membres"
                                options={membreOptions}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Card>
        </Modal>
    );
}