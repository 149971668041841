import { Card, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import React from "react";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import TypeDocumentService from "../../services/TypeDocumentService";
import TypeDossierService from "../../services/TypeDossierService";
import { DocumentService } from "../../services/DocumentService";
import CategorieDocumentService from "../../services/CategorieDocumentService";
import DomaineService from "../../services/DomaineService";
import DomaineCategorieService from "../../services/DomaineCategorieService";
import DocumentPrioriteService from "../../services/DocumentPrioriteService";
import moment from 'moment'; 
export default function DocumentNew({ visible, onCreate, onCancel }) {
  const [form] = Form.useForm();
  const [categorieDocuments, setCategorieDocuments] = React.useState([]);
  const [domaines, setDomaines] = React.useState([]);
  const [domaineCategories, setDomaineCategories] = React.useState([]);
  const [typeDocuments, setTypeDocuments] = React.useState([]);
  const [typeDossiers, setTypeDossiers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedDomaine, setSelectedDomaine] = React.useState("");
  const [selectedDomaineCategorieId, setSelectedDomaineCategorieId] = React.useState("");
  const [typeCodification, setTypeCodification] = React.useState("");
  const [isAutre, setIsAutre] = React.useState(false);
  const [documentPriorites, setDocumentPriorites] = React.useState([]);
  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      DomaineService.getInstance().all(),
      CategorieDocumentService.getInstance().all(),
      DocumentPrioriteService.getInstance().all(), // Charger les priorités
    ])
      .then(([domaineResponse, categorieDocumentResponse, prioriteResponse]) => {
        setDomaines(domaineResponse.data);
        setCategorieDocuments(categorieDocumentResponse.data);
        setDocumentPriorites(prioriteResponse.data); // Assignez les priorités
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    if (selectedDomaine.code === DomaineService.CODE.OTHER_DOMAIN) {
      setIsAutre(true);
      setSelectedDomaineCategorieId("");
      setTypeDossiers([]);
      setTypeDocuments([]);
      setDomaineCategories([]);
      return;
    }
    setIsAutre(false);
    if (!selectedDomaine) {
      return;
    }
    setLoading(true);
    DomaineCategorieService.getInstance()
      .findByDomaine(selectedDomaine.uid)
      .then((response) => {
        if (response.data) {
          setDomaineCategories(response.data);
        }
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setLoading(false));
  }, [selectedDomaine]);

  React.useEffect(() => {
    if (!selectedDomaineCategorieId) {
      return;
    }
    // Trouver le domaineCategorie avec domaine et la categorie_exploitant
    const domaineCategorie = domaineCategories.find(
      (item) => item.id === selectedDomaineCategorieId
    );
    if (!domaineCategorie) {
      return;
    }
    setLoading(true);
    Promise.all([
      TypeDossierService.getInstance().findByDomaineCategorie(
        domaineCategorie.id
      ),
      TypeDocumentService.getInstance().findByDomaineCategorie(
        domaineCategorie.id
      ),
    ])
      .then(([typeDossiersResponse, typeDocumentsResponse]) => {
        if (typeDossiersResponse?.data) {
          setTypeDossiers(typeDossiersResponse.data);
        }
        if (typeDocumentsResponse?.data) {
          setTypeDocuments(typeDocumentsResponse.data);
        }
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setLoading(false));
  }, [selectedDomaineCategorieId, domaineCategories]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        values.domaine_categorie_id = selectedDomaineCategorieId;
        setLoading(true);
        DocumentService.getInstance()
          .create(values)
          .then((response) => {
            Toast.success(response.message);
            form.resetFields();
            onCreate(response.data);
          })
          .catch((error) => {
            catchError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const onFailed = (errorInfo) => {
    catchError(errorInfo);
  };

  const typeCodifications = [
    { label: "Edition/Revision", value: "ER" },
    { label: "Version", value: "VS" },
    { label: "Autre", value: "AU" },
  ];

  const handleTypeCodificationChange = (value) => {
    setTypeCodification(value);
  };

  const handleDomaineChange = (value) => {
    const domaine = domaines.find((item) => item.id === value);
    setSelectedDomaine(domaine);
  };

  const handleDomaineCategorieChange = (value) => {
    setSelectedDomaineCategorieId(value);
  };

  return (
    <Modal
      title="Formulaire de soumission d'un nouveau document"
      width={"80%"}
      open={visible}
      onCancel={onCancel}
      okText="Créer"
      onOk={handleSave}
      cancelText="Annuler"
    >
      <Card>
        <Spin spinning={loading}>
          <Form
            form={form}
            name="newDocumentForm"
            layout="vertical"
            onFinishFailed={onFailed}
          >
            <Row gutter={[16, 16]}>
              <Col span={24} lg={12}>
                <Form.Item
                  label="Domaine"
                  name="domaine_id"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Sélectionner un domaine"
                    onChange={handleDomaineChange}
                  >
                    {domaines.map((domaine) => (
                      <Select.Option key={domaine.id} value={domaine.id}>
                        {domaine.nom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {isAutre && (
                <Col span={24} lg={12}>
                  <Form.Item
                    label="Nom du Document"
                    name="nom"
                    rules={[
                      { required: true, message: "Ce champ est obligatoire!" },
                    ]}
                  >
                    <Input placeholder="Saisir un nom significatif pour le document" />
                  </Form.Item>
                </Col>
              )}
              {!isAutre && (
                <>
                  <Col span={24} lg={12}>
                    <Form.Item
                      label="Categorie Exploitant"
                      name="categorie_exploitant_id"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Select
                        onChange={handleDomaineCategorieChange}
                        showSearch
                        optionFilterProp="children"
                        placeholder="Sélectionner un categorie"
                      >
                        {domaineCategories.map((domaineCategorie) => (
                          <Select.Option
                            key={domaineCategorie.categorie_exploitant.uid}
                            value={domaineCategorie.id}
                          >
                            {domaineCategorie.categorie_exploitant.nom}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      label="Type Dossier"
                      name="type_dossier_id"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Sélectionner un type de dossier"
                      >
                        {typeDossiers.map((type) => (
                          <Select.Option key={type.id} value={type.id}>
                            {type.nom}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      label="Type Document"
                      name="type_document_id"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Sélectionner un type de document" 
                        options={typeDocuments
                          .map((type) => ({ value: type.id, label: type.nom }))
                        }
                      >
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={24} lg={12}>
                <Form.Item
                  label="Categorie Document"
                  name="categorie_document_id"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="label"
                    placeholder="Sélectionner un categorie de document" 
                    options={categorieDocuments
                      .map((type) => ({ value: type.id, label: type.nom }))

                    }
                  >
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} lg={12}>
                <Form.Item
                  label="Type Codification"
                  name="type_codification"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Select
                    onChange={handleTypeCodificationChange}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Sélectionner le type de codification" 
                    options={typeCodifications
                      .map((type) => ({ value: type.value, label: type.label }))
                    }
                  >
                  </Select>
                </Form.Item>
              </Col>
              {typeCodification === "VS" && (
                <Col span={24} lg={12}>
                  <Form.Item
                    label="Numéro Version"
                    name="version"
                    rules={[
                      { required: true, message: "Ce champ est obligatoire!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
              {typeCodification === "ER" && (
                <>
                  <Col span={24} lg={6} md={12}>
                    <Form.Item
                      label="Edition"
                      name="edition"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={6} md={12}>
                    <Form.Item
                      label="Date d'édition"
                      name="date_edition"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Input type="date" />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={6} md={12}>
                    <Form.Item
                      label="Revision"
                      name="revision"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={6} md={12}>
                    <Form.Item
                      label="Date de révision"
                      name="date_revision"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Input type="date" />
                    </Form.Item>
                  </Col>
                </>
              )}
              {typeCodification === "AU" && (
                <>
                  <Col span={24} lg={12}>
                    <Form.Item
                      label="Codification"
                      name="codification"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <Form.Item
                      label="Code Codification"
                      name="code_codification"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est obligatoire!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={24} lg={12}>
                <Form.Item
                  label="Priorité"
                  name="document_priorite_id"
                  rules={[
                    { required: true, message: "Ce champ est obligatoire!" },
                  ]}
                >
                  <Select
                    placeholder="Sélectionner une priorité"
                  >
                    {documentPriorites.map((priorite) => (
                      <Select.Option key={priorite.id} value={priorite.id}>
                        {priorite.nom}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} lg={12}>
                <Form.Item help="Cette date est optionnelle, renseignez-la si vous souhaitez une date de retour souhaitée"
                  label="Date Retour Souhaitée"
                  name="date_retour_souhaitee"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value || moment(value).isSameOrAfter(moment(), 'day')) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("La date de retour souhaitée ne peut pas être antérieure à aujourd'hui!"));
                      },
                    },
                  ]}
                >
                  <Input type="date" />
                </Form.Item>
              </Col>
              <Col span={24} lg={12}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </Modal>
  );
}
