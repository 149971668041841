import React from 'react';
import DemandeCorrectionDocumentService from '../../services/DemandeCorrectionDocumentService';
import { catchError } from '../../services/DaoService';
import styles from './DemandeCorrectionDocumentActive.module.scss';
import DemandeCorrectionDocumentDetailsModal from './DemandeCorrectionDocumentDetailsModal';
import { usePusher } from '../../providers/PusherProvider';

export default function DemandeCorrectionDocumentActive({ document }) {
    const [demandeCorrectionDocument, setDemandeCorrectionDocument] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const { pusher } = usePusher();

    const init = React.useCallback(() => {
        if(!document) {
            return;
        }
        setLoading(true);
        DemandeCorrectionDocumentService.getInstance()
            .findActiveByDocument(document.uid)
            .then(response => {
                setDemandeCorrectionDocument(response.data);
            }).catch(err => catchError(err))
            .finally(() => setLoading(false));
    }, [document]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (!pusher || !demandeCorrectionDocument?.uid) {
            return;
        }
    
        const channel = pusher.subscribe(`demandecorrectiondocument.${demandeCorrectionDocument.uid}`);
        channel.bind('updated', function (data) {
            setDemandeCorrectionDocument(demandeCorrectionDocument => ({ ...demandeCorrectionDocument, ...data.updatedData }));
        });
    
        return () => {
            pusher.unsubscribe(`demandecorrectiondocument.${demandeCorrectionDocument.uid}`);
        };
    }, [pusher, demandeCorrectionDocument?.uid]);

    return (
        <div className={styles.container}>
            {loading ? (
                <div className={styles.loading}>Chargement...</div>
            ) : (
                demandeCorrectionDocument && demandeCorrectionDocument.corrige === 0 && (
                    <div className={styles.activeCorrection} onClick={() => setModalVisible(true)}>
                        <h5 className={styles.title}>Demande de correction active</h5>
                        <p className={styles.date}>
                            Date d'envoi : {new Date(demandeCorrectionDocument.date_envoie).toLocaleDateString()}
                        </p>
                        {demandeCorrectionDocument.observations ? (
                            <p className={styles.observations}>
                                Observations : {demandeCorrectionDocument.observations}
                            </p>
                        ) : (
                            <p className={styles.noObservations}>
                                Aucune observation spécifique n'a été notée.
                            </p>
                        )}
                    </div>
                )
            )}
            <DemandeCorrectionDocumentDetailsModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)} setDemandeCorrectionDocument={setDemandeCorrectionDocument}
                demandeCorrectionDocument={demandeCorrectionDocument}
            />
        </div>
    );
}
