import React, { useCallback } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { DocumentService } from "../../services/DocumentService";
import { catchError } from "../../services/DaoService";
import PageTitle from "../../components/utils/PageTitle";
import {
  CheckCircleOutlined,
  DeleteFilled,
  EditFilled,
  FileOutlined,
  FolderOpenFilled,
  ReloadOutlined,
  SendOutlined,
} from "@ant-design/icons";
import Main from "../../components/layout/Main";
import { Button, Col, Popconfirm, Row, Spin, Tabs } from "antd";
import { Card, Descriptions } from "antd";
import styles from "./DocumentDetailsPage.module.scss";
import Path from "../../components/utils/Path";
import Moment from "react-moment";
import PieceJointeList from "../../components/piecejointe/PieceJointeList";
import PieceJointeService from "../../services/PieceJointeService";
import StatutDocumentService from "../../services/StatutDocumentService";
import StatutClientService from "../../services/StatutClientService";
import { useUserContext } from "../../providers/UserProvider";
import Toast from "../../helpers/Toast";
import DocumentEdit from "../../components/document/DocumentEdit";
import ActivityFeed from "../../components/Activity/ActiviteFeed";
import DemandeCorrectionDocumentActive from "../../components/demandecorrectiondocument/DemandeCorrectionDocumentActive";
import LogTraces from "../../components/logtrace/LogTraces";
import DemandeCorrectionDocumentList from "../../components/demandecorrectiondocument/DemandeCorrectionDocumentList";
import { InlineEditableInput } from "../../components/utils/InlineEditableInput";
import UserService from "../../services/UserService";
import DocumentPrioriteService from "../../services/DocumentPrioriteService";
import DocumentClosingModal from "./DocumentClosingModal";
import DocumentMemberList from "../../components/documentmember/DocumentMemberList";
import { usePusher } from "../../providers/PusherProvider";
import ContactService from "../../services/ContactService";
import DocumentContactInfos from "./DocumentContactInfos";

export default function DocumentDetailsPage() {
  const { uid } = useParams();
  const [document, setDocument] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { check } = useUserContext();
  const [statutDocuments, setStatutDocuments] = React.useState([]);
  const [pathChanging, setPathChanging] = React.useState(false);
  const [statutClients, setStatutClients] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const { currentUser } = useUserContext();
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const navigate = useNavigate();
  const [users, setUsers] = React.useState([]);
  const [documentPriorites, setDocumentPriorites] = React.useState([]);
  const [closingModalOpened, setClosingModalOpened] = React.useState(false);
  const { pusher } = usePusher();
  const [contacts, setContacts] = React.useState([]);

  React.useEffect(() => {
    if (!pusher) {
      return;
    }

    const channel = pusher.subscribe(`document.${uid}`);

    channel.bind("updated", function (data) {
      setDocument((document) => ({ ...document, ...data.updatedData }));
    });

    return function () {
      pusher.unsubscribe(`document.${uid}`);
    };
  }, [pusher, uid]);

  const init = useCallback(
    function () {
      setLoading(true);
      DocumentService.getInstance()
        .find(uid)
        .then((response) => {
          setDocument(response.data);
        })
        .catch((err) => catchError(err))
        .finally(() => setLoading(false));

      UserService.getInstance()
        .all()
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          catchError(error);
        });
    },
    [uid]
  );

  React.useEffect(() => {
    if (!document) {
      return;
    }
    setLoading(true);
    ContactService.getInstance()
      .findAllByOrganisation(document.organisation?.uid)
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => setLoading(false));
  }, [document]);

  React.useEffect(function () {
    setLoading(true);
    Promise.all([
      StatutDocumentService.getInstance().all(),
      StatutClientService.getInstance().all(),

      DocumentPrioriteService.getInstance().all(),
    ])
      .then(
        ([statutDocumentResponse, statutClientResponse, prioriteResponse]) => {
          setStatutDocuments(statutDocumentResponse.data);
          setStatutClients(statutClientResponse.data);
          setDocumentPriorites(prioriteResponse.data);
        }
      )
      .catch((err) => catchError(err))
      .finally(() => setLoading(false));
  }, []);

  React.useEffect(
    function () {
      init();
    },
    [init]
  );

  const submitForApproval = () => {
    setSubmitting(true);
    DocumentService.getInstance()
      .submitForApproval(uid)
      .then((response) => {
        setDocument(response.data);
        Toast.success(response.message);
      })
      .catch((err) => catchError(err))
      .finally(() => setSubmitting(false));
  };

  const setItemToEdit = (document) => {
    setEditModalVisible(true);
  };

  const handleDocumentUpdate = (document) => {
    setDocument(document);
    setEditModalVisible(false);
  };

  const removeItem = (document) => {
    setIsDeleting(true);
    DocumentService.getInstance()
      .remove(document.uid)
      .then(() => {
        navigate("/document");
      })
      .catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const tabItems = [
    {
      key: "attachments",
      label: "Fichiers & Notes",
      children: (
        <PieceJointeList
          canSee={check("VIEW-DOCUMENT-ATTACHMENTS")}
          canAdd={
            (check("ADD-DOCUMENT-ATTACHMENTS") &&
              ((currentUser.is_contact &&
                document?.statut_document?.code === "OND") ||
                (!currentUser.is_contact && !document?.closed))) ||
            currentUser.is_admin
          }
          candRemove={
            (check("DELETE-DOCUMENT-ATTACHMENTS") &&
              ((currentUser.is_contact &&
                document?.statut_document?.code === "OND") ||
                (!currentUser.is_contact && !document?.closed))) ||
            currentUser.is_admin
          }
          parentType={PieceJointeService.TYPES.DOCUMENT}
          parentId={document?.id}
        />
      ),
    },
    document?.has_evaluation && {
      key: "history-submissions-ecarts",
      label: "Demandes de correction",
      children: <DemandeCorrectionDocumentList document={document} />,
    },
    check("VIEW-DOCUMENT-USERS") && {
      key: "members",
      label: "Membres",
      children: <DocumentMemberList document={document} />,
    },
    check("MANAGE-DATA-OPERATIONS-HISTORY") && {
      key: "trace-logs",
      label: "Logs",
      children: <LogTraces target={"document"} parentId={document?.id} />,
    },
    check("VIEW-CONTACT") && {
      key: "contact",
      label: "Infos du contact",
      children: <DocumentContactInfos contact={document?.contact} />,
    },
  ];

  const handleCurrentPathChange = (currentStatus) => {
    if (!currentStatus) {
      return;
    }
    // si le statut courant est le meme que le statut actuel du document
    if (document.statut_document.code === currentStatus.code) {
      return;
    }
    if (currentStatus.code === "CLOSED") {
      setClosingModalOpened(true);
      // ouvrir le modal ici qui va afficher le formulaire de cloture
      return;
    }

    setPathChanging(true);
    DocumentService.getInstance()
      .updateStatus(uid, { statut_document_id: currentStatus.id })
      .then((response) => {
        setDocument(response.data);
      })
      .catch((err) => catchError(err))
      .finally(() => setPathChanging(false));
  };

  const takeOwnership = () => {
    setPathChanging(true);
    DocumentService.getInstance()
      .takeOwnership(uid)
      .then((response) => {
        setDocument(response.data);
      })
      .catch((err) => catchError(err))
      .finally(() => setPathChanging(false));
  };

  const handleViewDocumentEvaluation = () => {
    if (document) {
      navigate(`/document-evaluation/${document.uid}`);
    }
  };

  const handleFieldChange = (value, fieldName, record) => {

    if((fieldName === 'document_priorite_id' || fieldName === 'contact_id') && !Number.isInteger(value)){
      return;
    }
    // merge the record with the new value
    const newRecord = { ...record, [fieldName]: value };
    
    DocumentService.getInstance()
      .update(record.uid, newRecord)
      .then((response) => {
        setDocument(response.data);
        init();
      })
      .catch((err) => catchError(err));
  };

  const handleClosingModalClose = () => {
    setClosingModalOpened(false);
  };

  return (
    <Main>
      {!document ? (
        <Spin fullscreen size="large" spinning />
      ) : (
        <>
          <PageTitle
            icon={<FileOutlined />}
            title={document?.nom ?? "Chargement en cours..."}
            subTitle={
              currentUser?.is_contact ? (
                document?.statut_client?.nom
              ) : (
                <>
                  {document?.statut_document?.nom}{" "}
                  {document?.closed ? (
                    document?.accepted ? (
                      <span className="text-success">(Accepté)</span>
                    ) : document?.approved ? (
                      <span className="text-success">(Approuvé)</span>
                    ) : (
                      <span className="text-danger">(Non Approuvé)</span>
                    )
                  ) : (
                    <span className="text-danger">(Non Approuvé)</span>
                  )}
                </>
              )
            }
            backPageTitle="Liste des documents"
            backPageLink="/document"
            canSeeBackPageLink={check("VIEW-DOCUMENTS")}
          >
            {!currentUser?.is_contact &&
              !document?.responsable_id && (
                <Popconfirm
                  okText="Oui"
                  onConfirm={takeOwnership}
                  title="Êtes-vous sûr de vouloir prendre en charge ce dossier ?"
                  okType="primary"
                >
                  <Button
                    loading={pathChanging}
                    type="primary"
                    className="mr-2 bg-primary"
                    icon={<CheckCircleOutlined />}
                  >
                    Prendre en charge le dossier
                  </Button>
                </Popconfirm>
              )}
            {currentUser?.is_contact &&
              document?.statut_client?.code ===
              StatutClientService.STATUS.DRAFT && (
                <Popconfirm
                  onConfirm={submitForApproval}
                  title="Êtes-vous sûr de vouloir soumettre ce document pour approbation ?"
                  okType="primary"
                >
                  <Button
                    loading={submitting}
                    type="primary"
                    className="mr-2 bg-success"
                    icon={<SendOutlined />}
                  >
                    Soumettre pour approbation
                  </Button>
                </Popconfirm>
              )}
            <Button loading={loading} icon={<ReloadOutlined />} onClick={init}>
              Rafraichir
            </Button>
            <Button
              type="primary"
              disabled={document?.closed}
              onClick={() => setItemToEdit(document)}
              className="bg-warning"
              icon={<EditFilled />}
            >
              Modifier
            </Button>
            {!!(
              (currentUser?.is_contact &&
                document.statut_client?.code ===
                StatutClientService.STATUS.DRAFT) ||
              document?.responsable_id === currentUser?.id ||
              currentUser?.is_admin
            ) && (
                <Popconfirm
                  onConfirm={() => {
                    removeItem(document);
                  }}
                  title="Êtes-vous sûr de vouloir supprimer ce document ?"
                  okType="danger"
                >
                  <Button
                    disabled={document?.closed}
                    loading={isDeleting}
                    type="primary"
                    danger
                    icon={<DeleteFilled />}
                  >
                    Supprimer
                  </Button>
                </Popconfirm>
              )}
            {!currentUser?.is_contact &&
              document?.responsable_id &&
              check("EVALUATE-DOCUMENT") && (
                <Button
                  onClick={() => handleViewDocumentEvaluation()}
                  icon={<FolderOpenFilled />}
                  className="btn btn-primary"
                >
                  Evaluation
                </Button>
              )}
          </PageTitle>
          {!document ? (
            <Spin spinning />
          ) : (
            <>
              {currentUser?.is_contact ? (
                <Path
                  readOnly
                  steps={statutClients}
                  currentStep={document.statut_client.code}
                />
              ) : (
                <Path
                  readOnly={
                    !currentUser?.is_admin &&
                    (document.responsable_id !== currentUser?.id ||
                      document.closed)
                  }
                  loading={pathChanging}
                  steps={statutDocuments}
                  currentStep={document.statut_document.code}
                  onCurrentPathChange={handleCurrentPathChange}
                />
              )}
              <Card className={styles.documentCard}>
                <Descriptions
                  size="middle" bordered
                  column={{ sm: 1, md: 2, lg: 3 }}
                >
                  <Descriptions.Item label="Numéro">
                    {document.document_number || "Non encore renseigné"}
                  </Descriptions.Item>

                  <Descriptions.Item label="Priorité">
                    <InlineEditableInput
                      value={document.document_priorite?.nom}
                      fieldName="document_priorite_id"
                      record={document}
                      onChange={handleFieldChange}
                      canEdit={currentUser}
                      options={documentPriorites.map((prior) => ({
                        value: prior.id,
                        label: prior.nom,
                      }))}
                      type="select"
                    >
                      <span>{document.document_priorite?.nom}</span>
                    </InlineEditableInput>
                  </Descriptions.Item>
                  <Descriptions.Item label="Date Retour Souhaitee">
                    {document.date_retour_souhaitee ? (
                      <Moment format="DD/MM/YYYY">
                        {document.date_retour_souhaitee}
                      </Moment>
                    ) : (
                      "N/A"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Categorie Document">
                    {document.categorie_document?.nom}
                  </Descriptions.Item>
                  <Descriptions.Item label="Type Document">
                    {document.type_document?.nom || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Type Dossier">
                    {document.type_dossier?.nom || "N/A"}
                  </Descriptions.Item>
                  {document.version ? (
                    <Descriptions.Item label="Version">
                      {document.version}
                    </Descriptions.Item>
                  ) : null}
                  {document.edition ? (
                    <>
                      <Descriptions.Item label="Edition">
                        {document.edition}
                      </Descriptions.Item>
                      <Descriptions.Item label="Date Edition">
                        {document.date_edition}
                      </Descriptions.Item>
                    </>
                  ) : null}
                  {document.revision ? (
                    <>
                      <Descriptions.Item label="Révision">
                        {document.revision}
                      </Descriptions.Item>
                      <Descriptions.Item label="Date Révision">
                        {document.date_revision}
                      </Descriptions.Item>
                    </>
                  ) : null}

                  {document.codification ? (
                    <Descriptions.Item label="Codification">
                      {document.codification}
                    </Descriptions.Item>
                  ) : null}
                  {document.code_codification ? (
                    <Descriptions.Item label="Code Codification">
                      {document.code_codification}
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item
                    label={currentUser?.is_contact ? "Statut" : "Statut Client"}
                  >
                    {document.statut_client.nom}
                  </Descriptions.Item>
                  {!currentUser?.is_contact ? (
                    <>
                      <Descriptions.Item label="Statut Document">
                        {document.statut_document.nom}
                      </Descriptions.Item>
                      <Descriptions.Item label="Exploitant">
                        {check("VIEW-ORGANISATION") ? (
                          <NavLink
                            to={`/parametrage/exploitant/${document.organisation.uid}`}
                          >
                            {document.organisation.nom}
                          </NavLink>
                        ) : (
                          <span>{document.organisation.nom}</span>
                        )}
                      </Descriptions.Item>
                    </>
                  ) : null}
                  <Descriptions.Item label="Responsable">
                    <InlineEditableInput
                      value={document.responsable?.nom}
                      record={document}
                      fieldName="responsable_id"
                      onChange={handleFieldChange}
                      canEdit={
                        currentUser?.is_admin ||
                        (document?.responsable_id === currentUser?.id &&
                          !document?.closed)
                      }
                      options={users.map((user) => ({
                        value: user.id,
                        label: user.name,
                      }))}
                      type="select"
                    >
                      {document.responsable ? (
                        check("VIEW-USER") ? (
                          <NavLink
                            to={`/parametrage/user/${document.responsable.uid}`}
                          >
                            {document.responsable.name}
                          </NavLink>
                        ) : (
                          <span>{document.responsable.name}</span>
                        )
                      ) : (
                        "N/A"
                      )}
                    </InlineEditableInput>
                  </Descriptions.Item>
                  <Descriptions.Item label="Créé le">
                    {" "}
                    {document.created_at ? (
                      <Moment format="DD/MM/YYYY à HH:mm">{document.created_at}</Moment>
                    ) : (
                      'N/A'
                    )}
                    {" "}
                  </Descriptions.Item>
                  <Descriptions.Item label="Mis à jour le">
                    {document.updated_at ? (
                      <Moment format="DD/MM/YYYY à HH:mm">{document.updated_at}</Moment>
                    ) : (
                      'N/A'
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date Soumission">
                    {document.date_soumission ? (
                      <Moment format="DD/MM/YYYY à HH:mm">
                        {document.date_soumission}
                      </Moment>
                    ) : (
                      "N/A"
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Soumis par">
                    {document.submit_by?.name || "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Contact">
                    <InlineEditableInput
                      value={document.contact?.nom}
                      record={document}
                      fieldName="contact_id"
                      onChange={handleFieldChange}
                      canEdit={
                        (currentUser?.is_contact || currentUser?.is_admin) && document.statut_document.code !== 'CLOSED'
                      }
                      options={contacts.map((contact) => ({
                        value: contact.id,
                        label: contact.name,
                      }))}
                      type="select"
                    >
                      {document.contact ? (
                        check("VIEW-CONTACT") ? (
                          <NavLink to={`/contact/${document.contact.uid}`}>
                            {document.contact.name}
                          </NavLink>
                        ) : (
                          <span>{document.contact.name}</span>
                        )
                      ) : (
                        "N/A"
                      )}
                    </InlineEditableInput>
                  </Descriptions.Item>
                  <Descriptions.Item label="Description" span={3}>
                    {" "}
                    {document.description || "N/A"}{" "}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Row gutter={[5, 5]} className="mt-2">
                {document?.has_active_demande_correction && (
                  <Col span={24}>
                    <Card>
                      <DemandeCorrectionDocumentActive document={document} />
                    </Card>
                  </Col>
                )}
                <Col xs={24} md={16}>
                  <Card className="mt-2">
                    <Tabs items={tabItems} />
                  </Card>
                </Col>
                <Col xs={24} lg={8}>
                  <ActivityFeed target={"document"} parentId={document?.id} />
                </Col>
              </Row>
            </>
          )}
          <DocumentEdit
            onUpdate={handleDocumentUpdate}
            visible={editModalVisible}
            onCancel={() => setEditModalVisible(false)}
            uid={uid}
          />
          <DocumentClosingModal
            visible={closingModalOpened}
            onClose={handleClosingModalClose}
            document={document}
          />
        </>
      )}
    </Main>
  );
}
