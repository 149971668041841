import React from "react";
import Main from "../../components/layout/Main";
import { useUserContext } from "../../providers/UserProvider";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import ContactDetails from "../../components/contact/ContactDetails";
import { useParams } from "react-router-dom";

export default function ContactDetailsPage() {
    const { id } = useParams();
    const { check } = useUserContext();
    return (
        <Main>
            {
                check('VIEW-CONTACT') ?
                    <ContactDetails contactUid={id} />
                    : <UnauthorizedMessage />
            }
        </Main>
    );
} 