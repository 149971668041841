import React, { useState } from 'react';
import { Card, Typography, Avatar, Button, Popconfirm, Space } from 'antd';
import { MailOutlined, UserOutlined, FieldTimeOutlined, DeleteOutlined, EditFilled, CheckCircleOutlined } from '@ant-design/icons';
import styles from './DocumentEvaluationMemberListItem.module.scss';
import Moment from 'react-moment';
import { useUserContext } from '../../providers/UserProvider';
import DocumentEvaluationMemberService from '../../services/DocumentEvaluationMemberService';
import Toast from '../../helpers/Toast';
import { catchError } from '../../services/DaoService';

const { Text } = Typography;

const DocumentEvaluationMemberListItem = ({ item, onDelete, documentEvaluation, onSigned }) => {
    const { currentUser } = useUserContext();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 

    const removeMember = (member) => {
        setIsDeleting(true);
        DocumentEvaluationMemberService.getInstance()
            .remove(item.id)
            .then((response) => {
                Toast.success(response.message);
                onDelete(member);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    };

    const signEvaluation = (member) => {
        setIsLoading(true);
        const data = { signed: true };
        DocumentEvaluationMemberService.getInstance()
            .signEvaluation(member.id, data)
            .then((response) => {
                Toast.success(response.message);   
                onSigned(member);             
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsLoading(false); 
            });
    };

    return (
        <div className={styles.documentEvaluationMemberListItem}>
            {item ? (
                <Card hoverable bordered={false}>
                    <div className={styles.cardHeader}>
                        <Avatar size={48} icon={<UserOutlined />} className={styles.avatar} />
                        <div className={styles.cardContent}>
                            <Typography.Title level={5} className={styles.userName}>
                                {item.user.name}
                            </Typography.Title>
                            <Space direction="vertical" size={0} className={styles.documentEvaluationMemberListItemDetails}>
                                <Text className={styles.email}>
                                    <MailOutlined />  &nbsp; <a href={`mailto:${item.user.email}`}>{item.user.email}</a>
                                </Text>
                                <Text className={styles.createdAt}>
                                    <FieldTimeOutlined /> &nbsp; Créé le <Moment format="DD/MM/YYYY">{item.user?.created_at}</Moment>
                                </Text>
                                <Text className={styles.role}>
                                    <UserOutlined /> &nbsp; {item.role}
                                </Text>
                            </Space>
                        </div>
                        {currentUser?.signature && !item?.signed && currentUser.id === item?.user_id && (
                            <Popconfirm
                                title="Êtes-vous sûr de vouloir signer cette évaluation ?"
                                onConfirm={() => signEvaluation(item)}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button
                                    loading={isLoading} 
                                    type="success"
                                    icon={<EditFilled />}
                                    size="small"
                                    className={styles.buttonSigned} 
                                    title='Signer'
                                />
                            </Popconfirm>
                        )}
                        {item?.signed && currentUser.id === item?.user_id && (
                            <div style={{ display: 'flex', alignItems: 'center', color: 'green', fontWeight: 'bold' }}>
                                <CheckCircleOutlined style={{ fontSize: '18px', marginRight: '8px' }} />
                                <span>Signé</span>
                            </div>
                        )}
                        {!!(currentUser?.is_admin || (documentEvaluation?.document?.responsable_id === currentUser?.id && !documentEvaluation?.closed && !documentEvaluation.validated)) && (
                            <Popconfirm
                                okType='danger'
                                title="Êtes-vous sûr de vouloir supprimer ce membre d'évaluation ?"
                                onConfirm={() => removeMember(item)}
                                okText="Oui"
                                cancelText="Non"
                            >
                                <Button title='Supprimer'
                                    loading={isDeleting} 
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    size="small"
                                    className={styles.buttonDelete}
                                />
                            </Popconfirm>
                        )}
                    </div>
                </Card>
            ) : null}
        </div>
    );
};

export default DocumentEvaluationMemberListItem;
