import { DaoService } from "./DaoService";

export default class DocumentEvaluationGroupItemService extends DaoService {

    static getInstance() {
        return super.getInstance('documentevaluationgroupitem');
    }

    findNotAcceptedByDocumentEvaluation(documentEvaluationUid) {
        return DocumentEvaluationGroupItemService.get(`${this.prefix}/${documentEvaluationUid}/documentevaluation`);
    }

}