import { DaoService } from "./DaoService";

class UserService extends DaoService {

    static getInstance() {
        return super.getInstance('user');
    }

    login(data) {
        return UserService.post(`${this.prefix}/login`, data);
    }

    getCurrentUser() {
        return UserService.get(`${this.prefix}/current`);
    }

    verifyOneTimePassword(data) {
        return UserService.post(`${this.prefix}/verify`, data);
    }

    logout() {
        return UserService.post(`${this.prefix}/logout`);
    }

    verifyEmail(token) {
        return UserService.post(`${this.prefix}/verify-email/${token}`);
    }

    resetPassword(token, data) {
        return UserService.post(`${this.prefix}/reset-password/${token}`, data);
    }

    enable(idOrUid) {
        return UserService.post(`${this.prefix}/${idOrUid}/enable`);
    }

    disable(idOrUid, data) {
        return UserService.post(`${this.prefix}/${idOrUid}/disable`, data);
    }

    changePassword(data) {
        return UserService.post(`${this.prefix}/change-password`, data);
    }

    confirmChangePassword(data) {
        return UserService.post(`${this.prefix}/confirm-change-password`,data);
    }

    forgotPassword(data) {
        return UserService.post(`${this.prefix}/forgot-password`, data);
    }

    linkGroupes(uid, data) {
        return UserService.post(`${this.prefix}/${uid}/link-groupes`, data);
    }

    findLinkedGroupes(uid) {
        return UserService.get(`${this.prefix}/${uid}/linked-groupes`);
    }

    all() {
        return UserService.get(`${this.prefix}/all`);
    }

    getUserIdsAttachedToGroup(uid) {
        return UserService.get(`${this.prefix}/${uid}/ids-attached-groupe`);
    }

    search(data) {
        return UserService.post(`${this.prefix}/search`, data);
    }

    resendVerifyEmailToken(uid) {
        return UserService.post(`${this.prefix}/${uid}/resend-verify-email-token`);
    }

    getUserIdsAttachedToQueue(uid) {
        return UserService.get(`${this.prefix}/${uid}/ids-attached-queue`);
    }

    getUserIdsAttachedToDocumentEvaluation(uid) {
        return UserService.get(`${this.prefix}/${uid}/ids-attached-document-evaluation`);
    }

    getUserIdsAttachedToDocument(uid) {
        return UserService.get(`${this.prefix}/${uid}/ids-attached-document`);
    }

    findByOrganisation(organisationUid) {
        return UserService.get(`${this.prefix}/${organisationUid}/organisation`);
    }

    uploadSignature(uid, data, handleUploadProgress) {
        return UserService.upload(`${this.prefix}/${uid}/upload-signature`, data, handleUploadProgress);
    }

    deleteSignature(uid) {
        return UserService.delete(`${this.prefix}/${uid}/delete-signature`);
    }
}

export default UserService;