import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Spin, Typography, Row, Col } from 'antd';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';
import styles from './UsersContactsDocumentsStatsForExploitant.module.scss';

const { Title } = Typography;

const UsersContactsDocumentsStatsForExploitant = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DashboardService.getInstance()
            .countUsersAndContactsAndDocumentsForExploitant()
            .then(response => {
                if (response.error === 0) {
                    // Transformation des données pour le graphique
                    const totalUsers = response.data.users;
                    const enabledUsers = response.data.usersEnabled;
                    const totalContacts = response.data.contacts;
                    const totalDocuments = response.data.documents;

                    const chartData = [
                        { name: 'Utilisateurs Total', value: totalUsers },
                        { name: 'Utilisateurs Activés', value: enabledUsers },
                        { name: 'Contacts', value: totalContacts },
                        { name: 'Documents', value: totalDocuments }
                    ];
                    setData(chartData);
                } else {
                    catchError(response.message);
                }
            })
            .catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className={styles.chartContainer}>
            <Title level={4} className={styles.title}>Utilisateurs, Contacts et Documents</Title>
            <Row>
                <Col span={24}>
                    <ResponsiveContainer width="100%" height={400}>
                        <PieChart>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={100}
                                fill="#8884d8"
                                paddingAngle={5}
                                dataKey="value"
                                label={({ name, value }) => `${name}: ${value}`}
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip formatter={(value, name) => [`${value}`, `${name}`]} />
                            <Legend verticalAlign="top" height={36} />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        </div>
    );
};

export default UsersContactsDocumentsStatsForExploitant;
