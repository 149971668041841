import { DaoService } from "./DaoService";

export default class EcartDocumentService extends DaoService {

    static getInstance() {
        return super.getInstance('ecartdocument');
    }

    findByDemandeCorrectionDocument(demandeCorrectionDocumentUid) {
        return EcartDocumentService.get(`${this.prefix}/${demandeCorrectionDocumentUid}/demandecorrectiondocument`);
    }

}