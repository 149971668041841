import { DaoService } from "./DaoService";
export default class TypeDossierService extends DaoService {

  static getInstance() {
    return super.getInstance("typedossier");
  }

  all() {
    return TypeDossierService.get(`${this.prefix}/all`);
  }

  findByDomaineCategorie(domaineCategorieUid) {
    return TypeDossierService.get(`${this.prefix}/${domaineCategorieUid}/domainecategorie`);
  }

  getTypeDossiersWithChecklistModeles(domaineCategorieId) {
    return TypeDossierService.get(`${this.prefix}/${domaineCategorieId}/typedossierchecklists`);
  }
  
}