import { DaoService } from "./DaoService";

export default class QueueService extends DaoService {
    static getInstance() {
        return super.getInstance('queue');
    }

    search(data) {
        return QueueService.post(`${this.prefix}/search`, data);
    }

}