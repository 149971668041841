import React from "react";
import { Layout, Row, Col, Typography } from "antd";
import BecomePartnerForm from "../components/auth/BecomePartnerForm";
import styles from "./BecomePartnerPage.module.scss";
import logo from "../assets/images/logo.png"; // Assurez-vous que le chemin du logo est correct

const { Title, Paragraph } = Typography;

export default function BecomePartnerPage() {
  return (
    <Layout className={styles.layout}>
      <Row className={styles.fullHeight}>
        <Col xs={24} md={12} className={styles.leftSide}>
          <div className={styles.content}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <Title className={styles.title}>Commencez votre inscription chez ANACIM</Title>
            <Paragraph className={styles.paragraph}>
              Si vous êtes un exploitant non encore répertorié chez ANACIM, vous pouvez commencer le processus de soumission de dossier en remplissant le formulaire ci-contre.
            </Paragraph>
            <Paragraph className={styles.paragraph}>
              Une fois le formulaire rempli, un lien de confirmation vous sera envoyé par e-mail. Veuillez cliquer sur ce lien pour continuer le processus et finaliser votre inscription.
            </Paragraph>
          </div>
        </Col>
        <Col xs={24} md={12} className={styles.rightSide}>
          <BecomePartnerForm />
        </Col>
      </Row>
    </Layout>
  );
}
