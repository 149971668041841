import React, { useState, useRef } from "react";
import { Form, Modal, Space, Divider, Progress, List } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./PieceJointeMultipleForm.module.scss";
import Toast from "../../helpers/Toast";
import PieceJointeService from "../../services/PieceJointeService";
import { catchError } from "../../services/DaoService";

const PieceJointeMultipleForm = ({ visible, onClose, parentType, parentId, onUploadFinish }) => {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});

    const fileInputRef = useRef(null);

    React.useEffect(() => {
        if (!visible) {
            setFiles([]);
            setUploadProgress({});
        }
    }, [visible]);

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validFiles = selectedFiles.filter(file => file.size <= 1024 * 1024 * 1024);

        if (validFiles.length !== selectedFiles.length) {
            Toast.error("Certains fichiers dépassent la taille maximale de 1 Go.");
        }

        setFiles(validFiles);
    };

    const handleUpload = () => {
        if (files.length === 0) {
            Toast.error("Veuillez sélectionner des fichiers à télécharger.");
            return;
        }

        setUploading(true);

        files.forEach((file) => {
            const formData = new FormData();
            const nameWithoutExtension = file.name.split(".").slice(0, -1).join(" ").replace(/[-_]/g, " ");
            formData.append("piece", file);
            formData.append("nom", nameWithoutExtension);
            formData.append("type", parentType);
            formData.append("parent_id", parentId);

            PieceJointeService.getInstance()
                .create(formData, (progress) => {
                    setUploadProgress((prevProgress) => ({
                        ...prevProgress,
                        [file.name]: progress,
                    }));
                })
                .then((response) => {
                    Toast.success(`${file.name} téléchargé avec succès.`);
                    onUploadFinish(); // Rafraîchir la liste après le téléchargement
                })
                .catch((error) => {
                    Toast.error("Erreur lors du chargement des pièces jointes.");
                    catchError(error);
                })
                .finally(() => {
                    setUploading(false);
                    setFiles([]);
                    setUploadProgress({});
                    onClose(); // Ferme le modal après succès
                });
        });
    };

    return (
        <Modal
            title="Ajouter Pièces Jointes"
            open={visible}
            onCancel={onClose}
            onOk={handleUpload}
            confirmLoading={uploading}
        >
            <Form layout="vertical">
                <Divider orientation="left" />
                <Form.Item label="Fichiers">
                    <div
                        className={styles.uploadContainer}
                        onClick={() => fileInputRef.current.click()}
                    >
                        <Space>
                            <UploadOutlined className={styles.uploadIcon} />
                            <span>
                                {files.length > 0 ? `${files.length} fichier(s) sélectionné(s)` : "Cliquez pour sélectionner des fichiers"}
                            </span>
                        </Space>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.png,.jpg,.jpeg,.mp4,.mp3,.zip,.rar"
                            style={{ display: "none" }}
                            multiple
                        />
                    </div>
                </Form.Item>

                <List
                    dataSource={files}
                    renderItem={(file) => (
                        <List.Item>
                            <div className={styles.fileItem}>
                                <span>{file.name}</span>
                                <Progress
                                    percent={uploadProgress[file.name] || 0}
                                    size="small"
                                    status={uploading ? "active" : "normal"}
                                />
                            </div>
                        </List.Item>
                    )}
                />
            </Form>
        </Modal>
    );
};

export default PieceJointeMultipleForm;
