import { DaoService } from "./DaoService";
export default class CategorieDocumentService extends DaoService {

    static getInstance() {
        return super.getInstance('categoriedocument');
    }
    all() {
        return CategorieDocumentService.get(`${this.prefix}/all`);
    }

}