import React, { useState } from 'react';
import { catchError } from '../../services/DaoService';
import { Input, List, Avatar, Dropdown } from 'antd';
import { FileOutlined, HomeOutlined } from '@ant-design/icons';
import styles from './SearchBar.module.scss';
import SearchService from '../../services/SearchService';
import { NavLink } from 'react-router-dom';

const { Search } = Input;
const SearchBar = () => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownItems, setDropdownItems] = useState([]);

  React.useEffect(() => {
    if (!searchText) {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    SearchService.getInstance()
      .search(searchText)
      .then((response) => {
        if (!response) {
          return;
        }
        setSearchResults(response.data);
        setDropdownVisible(response.data);
      }).catch((error) => {
        catchError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchText]);
  
  const getLink = (item) => {
    if (item.type === 'document') {
      return `/document/${item.uid}`;
    }
    return `/parametrage/exploitant/${item.uid}`;
  }

  const getIcon = (item) => {
    if (item.type === 'document') {
      return <Avatar icon={<FileOutlined />} />;
    }
    return <Avatar icon={<HomeOutlined />} />;
  }

  React.useEffect(() => {
    setDropdownVisible(searchResults.length !== 0);
    setDropdownItems(searchResults.map(item => ({
      key: item.id,
      label: (
        <NavLink to={getLink(item)}>
          <List.Item>
            <List.Item.Meta
              title={
                item.name.length > 30 ? `${item.name?.substring(0, 30)}...` : item.name
              }
              description={item.description}
            />
          </List.Item>
        </NavLink>
      ),
      icon: getIcon(item),
    })));
  }, [searchResults]);

  return (
    <div className={styles.searchBar}>
      <Dropdown
        menu={{ items: dropdownItems, }}
        open={dropdownVisible}
        onOpenChange={visible => setDropdownVisible(visible)}
      >
        <Search loading={loading}
          placeholder="Rechercher des exploitants, ..."
          onSearch={value => setSearchText(value)}
          onInput={e => setSearchText(e.target.value)}
          onFocus={() => searchText && setDropdownVisible(true)}
          onBlur={() => setDropdownVisible(true)}
          enterButton size='large'
        />
      </Dropdown>
    </div>
  );
};

export default SearchBar;
