import React, { useState, useRef } from "react";
import { Button, Progress, Avatar, Typography, Card } from "antd";
import { UploadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./UserUploadSignatureForm.module.scss";
import Toast from "../../helpers/Toast";
import { catchError } from "../../services/DaoService";
import UserService from "../../services/UserService";
import { useUserContext } from "../../providers/UserProvider";

const { Text } = Typography;

const UserUploadSignatureForm = () => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { currentUser, getCurrentUser } = useUserContext();
    const [loading, setLoading] = useState(false);

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const maxSize = 2 * 1024 * 1024; // 2 Mo en octets
        if (selectedFile.size > maxSize) {
            Toast.error("La taille du fichier ne doit pas dépasser 2 Mo.");
            return;
        }
        setFile(selectedFile);
    };

    const handleUpload = () => {
        if (!file) {
            Toast.error("Veuillez sélectionner un fichier.");
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append("signature", file);

        UserService.getInstance()
            .uploadSignature(currentUser.uid, formData, setUploadProgress)
            .then((response) => {
                Toast.success(response.message);
                setFile(null);
                getCurrentUser();
            })
            .catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const deleteSignature = () => {
        setLoading(true);
        UserService.getInstance()
            .deleteSignature(currentUser.uid)
            .then((response) => {
                Toast.success(response.message);
                getCurrentUser();
            })
            .catch((error) => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <Card title="Ajouter ma signature">
            <div className={styles.uploadSignatureContainer}>
                <div className={styles.avatarContainer}>
                    {currentUser.signature_path ? (
                        <>
                            <Avatar
                                src={currentUser.signature_path}
                                size={120}
                                className={styles.avatar}
                            />
                            <div className={styles.avatarActions}>
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => fileInputRef.current.click()}
                                >
                                    Remplacer
                                </Button>
                                <Button loading={loading}
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={deleteSignature}
                                >
                                    Supprimer
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className={styles.placeholder} onClick={() => fileInputRef.current.click()}>
                            <UploadOutlined className={styles.uploadIcon} />
                            <Text type="secondary">Cliquez pour ajouter une signature</Text>
                        </div>
                    )}
                </div>

                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept=".png,.jpg,.jpeg"
                    style={{ display: "none" }}
                />

                {file && (
                    <div className={styles.uploadActions}>
                        <div className={styles.uploadPreview}>
                            <Text strong>Prévisualisation du fichier:</Text>
                            <div className={styles.fileName}>{file.name}</div>
                        </div>
                        <Button type="primary" onClick={handleUpload} loading={uploading}>
                            Télécharger
                        </Button>
                        <Progress percent={uploadProgress} />
                    </div>
                )}
            </div>
        </Card>
    );
};

export default UserUploadSignatureForm;
