import { Card, Spin } from "antd";
import React from "react";
import { catchError } from "../../services/DaoService";
import DomaineService from "../../services/DomaineService";
import styles from "./DomaineTypesConfigItem.module.scss"; // Import du fichier SCSS
import DomaineTypesConfigFormItem from "./DomaineTypesConfigFormItem";


export default function DomaineTypesConfigItem({ domaine, typeDossiers, typeDocuments }) {
    const [loading, setLoading] = React.useState(false);
    const [domaineCategorieData, setDomaineCategorieData] = React.useState([]);


    React.useEffect(() => {
        setLoading(true);
        DomaineService.getInstance().findTypesByUid(domaine.uid)
            .then(response => {
                setDomaineCategorieData(response.data);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [domaine]);

    return (
        <Card classNames={{ body: 'p-0' }} className={styles.domaineCard} loading={loading} title={domaine.name}>
            {loading ? (
                <Spin />
            ) : (
                <>
                    {
                        domaineCategorieData.map((domaineCategorieItem, index) => (
                            <Card className={styles.domaineCategorieCard} key={index} title={domaineCategorieItem.domaineCategorie.categorie_exploitant.nom}>
                                <DomaineTypesConfigFormItem domaineCategorieItem={domaineCategorieItem} typeDossiers={typeDossiers} typeDocuments={typeDocuments} />
                            </Card>
                        ))
                    }
                </>
            )}
        </Card>
    );
}
