import { DaoService } from "./DaoService";

export default class DocumentEvaluationGroupService extends DaoService {

    static getInstance() {
        return super.getInstance('documentevaluationgroup');
    }

    async findByDocumentEvaluation(uid) {
        return DocumentEvaluationGroupService.get(`${this.prefix}/${uid}/documentevaluation`);
    }

    async takeOwnership(uid) {
        return DocumentEvaluationGroupService.post(`${this.prefix}/${uid}/take-ownership`);
    }
    async releaseOwnership(uid) {
        return DocumentEvaluationGroupService.post(`${this.prefix}/${uid}/release-ownership`);
    }
}