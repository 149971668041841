import React from "react";
import { Layout, Row, Col, Typography } from "antd";
import styles from "./LoginPage.module.scss";
import LoginForm from "../components/auth/LoginForm";
import logo from "../assets/images/logo.png"; // Assurez-vous que le chemin du logo est correct

const { Title, Paragraph } = Typography;

export default function LoginPage() {

  // const handleBecomePartnerClick = () => {
  //   navigate('/become-partner');
  // };

  return (
    <Layout className={styles.layout}>
      <Row className={styles.fullHeight}>
        <Col xs={24} md={12} className={styles.leftSide}>
          <div className={styles.content}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <Title className={styles.title}>Bienvenue sur notre plateforme</Title>
            <Paragraph className={styles.paragraph}>
              Gérez vos documents, collaborez avec votre équipe, et accédez à une interface intuitive et sécurisée.
            </Paragraph>
            <Paragraph className={styles.paragraph}>
              Connectez-vous pour découvrir un monde de fonctionnalités conçues pour vous aider à travailler plus efficacement.
            </Paragraph>
          </div>
        </Col>
        <Col xs={24} md={12} className={styles.rightSide}>
          <LoginForm />
          <div className={styles.becomePartnerSection}>
           {/*  <Paragraph className={styles.becomePartnerText}>
              Vous n'êtes pas encore répertorié chez ANACIM ?
            </Paragraph>
            <Button icon={<ArrowRightOutlined />}
              type="default"
              className={styles.becomePartnerButton}
              onClick={handleBecomePartnerClick}
            >
              Soumettez votre document ici
            </Button> */}
          </div>
        </Col>
      </Row>
    </Layout>
  );
}
