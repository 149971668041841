import React, { useState, useEffect } from 'react';
import { Table, Spin, Typography } from 'antd';
import { catchError } from '../../services/DaoService';
import DashboardService from '../../services/DashboardService';
import styles from './DocumentStatsByStatutClientTable.module.scss';

const { Title } = Typography;

const DocumentStatsByStatutClientTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        DashboardService.getInstance()
            .countDocumentsByStatutClient()
            .then(response => {
                if (response.error === 0) {
                    setData(response.data);
                } else {
                    catchError(response.message);
                }
            })
            .catch(err => catchError(err))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const columns = [
        {
            title: 'Exploitant',
            dataIndex: 'organisation',
            key: 'organisation',
            fixed: 'left',
            width: 150,
        },
        {
            title: 'Total Documents',
            dataIndex: 'documents',
            key: 'documents',
            align: 'center',
            width: 100,
        },
        {
            title: 'Brouillons',
            dataIndex: 'documentDrafts',
            key: 'documentDrafts',
            align: 'center',
            width: 100,
        },
        {
            title: 'Envoyés',
            dataIndex: 'documentSents',
            key: 'documentSents',
            align: 'center',
            width: 100,
        },
        {
            title: 'En Attente',
            dataIndex: 'documentPendings',
            key: 'documentPendings',
            align: 'center',
            width: 100,
        },
        {
            title: 'En Cours',
            dataIndex: 'documentInProgress',
            key: 'documentInProgress',
            align: 'center',
            width: 100,
        },
        {
            title: 'Clôturés',
            dataIndex: 'documentCloseds',
            key: 'documentCloseds',
            align: 'center',
            width: 100,
        },
    ];

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div className={styles.documentStatsTable}>
            <Title level={4} className={styles.title}>Statistiques des Documents par Statut Client</Title>
            <Table
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.organisation}
                scroll={{ x: 800, y: 300 }}
                pagination={false}
                bordered
            />
        </div>
    );
};

export default DocumentStatsByStatutClientTable;
