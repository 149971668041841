import React from 'react';
import { Card, Button, Row, Col, Space, Spin } from 'antd';
import ContactService from '../../services/ContactService';
import { catchError } from '../../services/DaoService';
import { useUserContext } from '../../providers/UserProvider';
import { ArrowDownOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import ContactNew from './ContactNew';
import ContactCard from './ContactCard';

export default function ContactList({ organisation }) {

  const [contacts, setContacts] = React.useState([]);
  const [paginationData, setPaginationData] = React.useState(null);
  const { check } = useUserContext();
  const [newModalVisible, setNewModalVisible] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const init = React.useCallback(() => {
    if (organisation) {
      setLoading(true);
      setContacts([]);
      ContactService.getInstance()
        .findByOrganisation(organisation.uid)
        .then((response) => {
          if (!response?.data) return;
          setContacts(response.data.data);
          setPaginationData(response.data);
        }).catch((error) => {
          catchError(error);
        }).finally(() => setLoading(false));
    }
  }, [organisation]);


  React.useEffect(() => {
    init();
  }, [init]);

  React.useEffect(() => {
    if (paginationData) {
      setContacts(contacts => {
        const contactsCopy = [...contacts, ...paginationData.data];
        // supprimer les doublons
        return contactsCopy.filter((contact, index, self) =>
          index === self.findIndex((t) => (
            t.uid === contact.uid
          ))
        );

      })
      setHasMore(paginationData.next_page_url !== null);
    } else {
      setContacts([]);
      setHasMore(false);
    }
  }, [paginationData]);

  const handleNewContact = (contact) => {
    setContacts([...contacts, contact]);
  }

  const openNewModal = () => {
    setNewModalVisible(true)
  }

  const handleDelete = (contact) => {
    setContacts(contacts.filter(item => item.uid !== contact.uid));
  }

  const loadMore = () => {
    if (hasMore) {
      setLoading(true);
      ContactService.get(paginationData.next_page_url)
        .then((response) => {
          if (!response?.data) return;
          setPaginationData(response.data);
        }).catch((error) => {
          catchError(error);
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <div style={{ padding: '20px' }}>
      {!!organisation.accepted && (
        <ContactNew organisationId={organisation?.id} onCreate={handleNewContact} visible={newModalVisible} onCancel={() => setNewModalVisible(false)} />
      )}
      <Spin spinning={loading}>
        <Card extra={
          <>
            <Space>
              <Button onClick={init} icon={<ReloadOutlined />}>
                Rafraîchir
              </Button>
              {check('ADD-CONTACT') && !!organisation.accepted ?
                <Button onClick={openNewModal} icon={<PlusCircleOutlined />} type="primary">Ajouter des contacts</Button>
                : null}
            </Space>
          </>
        }
        >
          <Row gutter={16}>
            {contacts.map((item, index) => (
              <Col xs={24} md={12} key={index} style={{ paddingTop: '20px' }}>
                <ContactCard organisation={organisation} item={item} onDelete={handleDelete} onAssignManager={init} />
              </Col>
            ))}
            <Col span={8} style={{ paddingTop: '20px' }}>
              <Spin spinning={loading} />
            </Col>
          </Row>
        </Card>
      </Spin>
      <div className='d-flex justify-content-center'>
        <Button icon={<ArrowDownOutlined />} disabled={!hasMore} loading={loading} type="primary" onClick={loadMore} style={{ marginTop: '20px' }}>
          Voir plus
        </Button>
      </div>
    </div>
  );
}