// OrganisationDocumentList.jsx

import React, { useState, useEffect } from "react";
import { List, Button } from "antd";
import { NavLink } from "react-router-dom";
import { catchError } from "../../services/DaoService";
import styles from "./OrganisationDocumentList.module.scss";
import { DocumentService } from "../../services/DocumentService";

export default function OrganisationDocumentList({ organisation }) {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationData, setPaginationData] = useState(null);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setDocuments([]);
        setLoading(true);
        DocumentService.getInstance()
            .findByOrganisation(organisation.uid)
            .then(response => {
                setPaginationData(response.data);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [organisation]);

    useEffect(() => {
        if (!paginationData) return;
        setHasMore(paginationData.next_page_url !== null);
        setDocuments(documents => {
            const uniqueDocuments = paginationData.data.filter(doc => !documents.some(d => d.id === doc.id));
            return [...documents, ...uniqueDocuments];
        });
    }, [paginationData]);

    const loadMore = () => {
        if (!hasMore) return;
        setLoading(true);
        DocumentService.get(paginationData.next_page_url)
            .then(response => {
                setPaginationData(response.data);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.organisationDocumentList}>
            <List
                loading={loading}
                itemLayout="vertical"
                size="large"
                dataSource={documents}
                renderItem={(item, index) => (
                    <List.Item key={item.id} className={styles.listItem}>
                        <List.Item.Meta
                            title={<NavLink to={`/document/${item.uid}`} className={styles.title}>{index+1}. {item.nom}</NavLink>}
                            description={`Statut: ${item.statut_document?.nom}`}
                        />
                        <div className={styles.description}>
                           Numéro document :  {item.document_number || "Pas de description disponible"}
                        </div>
                    </List.Item>
                )}
            />
            {hasMore && (
                <div className={styles.loadMore}>
                    <Button onClick={loadMore} loading={loading}>
                        Charger plus
                    </Button>
                </div>
            )}
        </div>
    );
}
