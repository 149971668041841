import { Button, Col, Collapse, Divider, Empty, Form, Input, Modal, Popconfirm, Row } from "antd";
import React, { useCallback } from "react";
import { catchError } from "../../services/DaoService";
import { DeleteFilled, SaveFilled } from "@ant-design/icons";
import Toast from "../../helpers/Toast";
import ChecklistModeleGroupItemList from "./ChecklistModeleGroupItemList";
import ChecklistModeleGroupService from "../../services/ChecklistModeleGroupService";
import { InlineEditableInput } from '../utils/InlineEditableInput';

export default function ChecklistModeleGroupModal({ checklistModele, visible, onClose }) {
    const [groups, setGroups] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [accordionItems, setAccordionItems] = React.useState([]);
    const [defaultActiveKey, setDefaultActiveKey] = React.useState([]);

    const loadData = useCallback(() => {
        if (!checklistModele || !visible) return;
        setLoading(true);
        ChecklistModeleGroupService.getInstance().findByChecklistModele(checklistModele.uid)
            .then(response => setGroups(response.data))
            .catch(catchError)
            .finally(() => setLoading(false));
    }, [checklistModele, visible]);

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const handleAddGroup = (values) => {
        setLoading(true);
        values.checklist_modele_id = checklistModele.id;
        ChecklistModeleGroupService.getInstance().create(values)
            .then(response => {
                form.resetFields();
                setGroups(groups => [...groups, response.data]);
                Toast.success(response.message);
            })
            .catch(catchError)
            .finally(() => setLoading(false));
    };

    const handleDeleteGroup = useCallback(($event, uid) => {
        $event.stopPropagation();
        setLoading(true);
        ChecklistModeleGroupService.getInstance().remove(uid)
            .then(response => {
                setGroups(groups => groups.filter(group => group.uid !== uid));
                Toast.success(response.message);
            })
            .catch(catchError)
            .finally(() => setLoading(false));
    }, []);

    const handleFieldChange = useCallback((value, fieldName, record) => {
        const updatedGroup = { ...record, [fieldName]: value };
        ChecklistModeleGroupService.getInstance().update(updatedGroup.uid, updatedGroup)
            .then(response => {
                Toast.success(response.message);
                setGroups(groups => groups.map(group => group.uid === updatedGroup.uid ? response.data : group));
            })
            .catch(catchError);
    }, []);

    React.useEffect(() => {
        setAccordionItems(groups.map((group) => ({
            key: group.uid,
            label: (
                <InlineEditableInput
                    canEdit={true}
                    value={group.nom}
                    record={group}
                    onChange={handleFieldChange}
                    fieldName="nom"
                    type="input"  // Utiliser un textarea ici
                >
                    {group.nom}
                </InlineEditableInput>
            ),
            children: defaultActiveKey.includes(group.uid) && (
                <ChecklistModeleGroupItemList groupe={group} />
            ),
            extra: (
                <Popconfirm
                    title="Voulez-vous vraiment supprimer ce groupe?"
                    onConfirm={($e) => handleDeleteGroup($e, group.uid)}
                    okText="Supprimer"
                    okType="danger"
                    cancelText="Non"
                >
                    <DeleteFilled style={{ color: "red" }} />
                </Popconfirm>
            ),
        })));
    }, [groups, defaultActiveKey, loading, handleDeleteGroup, handleFieldChange]);

    const handleAccordionTabChange = (key) => {
        setDefaultActiveKey(key);
    };

    return (
        <Modal width={"80%"} title="Configuration du modèle de checklist" open={visible} onCancel={onClose} footer={null}>
            <Divider />
            {accordionItems.length ? (
                <>
                    <Collapse
                        size="small"
                        accordion
                        items={accordionItems}
                        defaultActiveKey={defaultActiveKey}
                        onChange={handleAccordionTabChange}
                    />
                    <Divider />
                </>
            ) : (
                <Empty description="Aucun groupe de checklist n'a été configuré" />
            )}
            <Form form={form} layout="vertical" onFinish={handleAddGroup}>
                <Row gutter={[16, 16]}>
                    <Col span={24} lg={16}>
                        <Form.Item
                            name="nom"
                            rules={[{ required: true, message: "Le nom du groupe est obligatoire" }]}
                        >
                            <Input placeholder="Saisir un nom de section des points d'évaluation" />
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={8}>
                        <Form.Item>
                            <Button
                                icon={<SaveFilled />}
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Ajouter
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
