

import ContactService from "../../services/ContactService";
import Main from "../../components/layout/Main";
import InfiniteTable from "../../components/utils/InfiniteTable";
import UnauthorizedMessage from "../../components/utils/UnauthorizedMessage";
import ContactEdit from "../../components/contact/ContactEdit";
import PageTitle from "../../components/utils/PageTitle";
import React, { useCallback } from "react";
import { useUserContext } from "../../providers/UserProvider";
import { NavLink, useNavigate } from "react-router-dom";
import { catchError } from "../../services/DaoService";
import OrganisationService from "../../services/OrganisationService";
import { Button, Col, Divider, Input, Popconfirm, Row, Select, Space, Spin, Typography } from "antd";
import { BuildOutlined, DeleteFilled, EditFilled, EyeOutlined, ReloadOutlined, UserAddOutlined } from "@ant-design/icons";
import UserService from "../../services/UserService";
import Toast from "../../helpers/Toast";
import styles from './ContactListPage.module.scss';
const { Text } = Typography;

export default function ContactListPage() {
    const [contacts, setContacts] = React.useState([]);
    const [selectedUid, setSelectedUid] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [organisations, setOrganisations] = React.useState([]);

    const [hasMore, setHasMore] = React.useState(true);
    const { check, currentUser } = useUserContext();

    const [searchText, setSearchText] = React.useState(null);
    const [organisationIds, setOrganisationIds] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [paginationData, setPaginationData] = React.useState(null);
    const [creatingAccount, setCreatingAccount] = React.useState(false);

    const navigate = useNavigate();

    const setItemToEdit = (record) => {
        setSelectedUid(record.uid);
        setEditModalVisible(true);
    }
    const setItemToView = (record) => {
        setSelectedUid(record.uid);
        navigate(`/contact/${record.uid}`);
    }


    const handleContactUpdate = (contact) => {
        setContacts(contacts.map(c => c.uid === contact.uid ? contact : c));
        setEditModalVisible(false);
    }

    const init = useCallback(() => {
        setLoading(true);
        ContactService.getInstance()
            .search({ searchText, organisationIds })
            .then((response) => {
                if (!response) return;
                setPaginationData(response.data);
                setContacts(response.data.data);
            }).catch((error) => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [searchText, organisationIds, setLoading, setPaginationData]);

    React.useEffect(() => {
        init();
    }, [init]);

    React.useEffect(() => {
        if (paginationData) {
            setContacts(contacts => [...new Map([...contacts, ...paginationData.data].map(item => [item['id'], item])).values()]);

            setHasMore(paginationData.next_page_url != null);
        } else {
            setHasMore(false);
        }
    }, [paginationData]);

    const removeContact = (contact) => {
        setIsDeleting(true);
        ContactService.getInstance()
            .remove(contact.uid)
            .then(() => {
                setContacts(contacts.filter(c => c.id !== contact.id));
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setIsDeleting(false);
            });
    }

    React.useEffect(() => {
        if (currentUser.is_contact) return;
        OrganisationService.getInstance()
            .all()
            .then((response) => {
                if (!response?.data) return;
                setOrganisations(response.data);
            }).catch((error) => {
                catchError(error);
            });
    }, [currentUser]);





    const columns = [
        {
            title: 'Prénom',
            dataIndex: 'prenoms',
            key: 'prenoms',
            sorter: (a, b) => a.prenoms.localeCompare(b.prenoms),
            width: 300,
            render: (text) => <div style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                maxWidth: 300
            }}>{text}</div>,
        },
        {
            title: "Nom",
            dataIndex: 'nom',
            key: 'nom',
            sorter: (a, b) => a.nom.localeCompare(b.nom),
            width: 100,
            ellipsis: true
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            width: 150,
            ellipsis: true,
            render: (text) => (
                <NavLink to={`mailto:${text}`} style={{ textDecoration: 'none' }}>
                    {text}
                </NavLink>
            )
        },
        {
            title: "Téléphone",
            dataIndex: 'telephone',
            key: 'telephone',
            sorter: (a, b) => a.telephone.localeCompare(b.telephone),
            width: 150,
            render: (text) => (
                <NavLink to={`tel:${text}`} style={{ textDecoration: 'none' }}>
                    {text}
                </NavLink>
            ),
            ellipsis: true
        },
        {
            title: "Fonction",
            dataIndex: 'fonction',
            key: 'fonction',
            sorter: (a, b) => a.fonction.localeCompare(b.fonction),
            width: 120,
            ellipsis: true
        },
        !currentUser.is_contact ? {
            title: "Exploitant",
            dataIndex: 'organisation',
            key: 'organisation',
            render: (organisation) => (
                check('VIEW-ORGANIZATION') ?
                    <NavLink to={`/parametrage/exploitant/${organisation?.uid}`} style={{ textDecoration: 'none' }}>
                        {organisation?.nom}
                    </NavLink>
                    : <Text>{organisation?.nom}</Text>
            ),
            sorter: (a, b) => a.organisation.nom.localeCompare(b.organisation?.nom),
            width: 200,
            ellipsis: true
        } : null,
        {
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (record) => (
                <Space>
                    {check('VIEW-CONTACT') ? <Button onClick={() => setItemToView(record)} icon={<EyeOutlined />} className="btn btn-primary"></Button> : null}
                    {check('EDIT-CONTACT') ? <Button onClick={() => setItemToEdit(record)} icon={<EditFilled />} className="btn btn-warning"></Button> : null}
                    {check('DELETE-CONTACT') ?
                        <Popconfirm okType='danger' title="Voulez-vous vraiment supprimer ce contact?" onConfirm={() => removeContact(record)} okText="Confirmer" cancelText="Annuler">
                            <Button loading={isDeleting} icon={<DeleteFilled />} className="btn btn-danger"></Button>
                        </Popconfirm>
                        : null}
                    {(check('ADD-USER') && !record?.user_id) ?
                        <Button loading={creatingAccount} title='Créer un compte utilisateur' onClick={() => createAccount(record)} icon={<UserAddOutlined />} className={styles.createAccountButton}>
                        </Button>
                        : null}
                </Space>
            )
        }
    ].filter(column => column !== null);

    const createAccount = (contact) => {
        let user = {
            name: contact.name,
            email: contact.email,
            contact_id: contact.id
        }
        setCreatingAccount(true);
        UserService.getInstance()
            .create(user)
            .then((response) => {
                Toast.success(response.message);
                setContacts(contacts =>
                    contacts.map(c =>
                        c.id === contact.id ? { ...c, user_id: response.data.id } : c
                    )
                );
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setCreatingAccount(false);
            });
    }

    const labelStyle = {
        marginRight: '10px', // espace entre le label et le select
        display: 'flex',
        alignItems: 'center',
    };

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    };

    const loadMore = () => {
        if (hasMore && !loading) {
            setLoading(true);
            ContactService.post(paginationData.next_page_url, { searchText, organisationIds })
                .then((response) => {
                    if (!response) return;
                    setPaginationData(response.data);
                }).catch((error) => {
                    catchError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }


    return (
        <Main>
            {check('VIEW-CONTACTS') ? (<>
                <ContactEdit onUpdate={handleContactUpdate} visible={editModalVisible} onCancel={() => setEditModalVisible(false)} uid={selectedUid} />
                <PageTitle title="Liste des contacts" icon={<BuildOutlined />} subTitle="Accéder et configurer la liste des contacts...">
                    <Button onClick={init} icon={<ReloadOutlined />} type="default">Rafraîchir</Button>
                </PageTitle>
                <Spin spinning={loading}>
                    <InfiniteTable title={
                        <Row gutter={[16, 16]} justify={'center'}>
                            <Col span={24}>
                                <Divider>Filtres rapides</Divider>
                            </Col>
                            <Col xs={24} md={8} lg={6}>
                                <Input.Search size="large" value={searchText} onInput={
                                    (e) => {
                                        setSearchText(e.target.value);
                                    }} placeholder="Rechercher un contact" />
                            </Col>
                            {!currentUser.is_contact ?
                                <Col xs={24} md={8} lg={6}>
                                    <div style={containerStyle}>
                                        <div style={labelStyle}>Organisation :</div>
                                        <Select size="large" value={organisationIds} onChange={
                                            (value) => {
                                                setOrganisationIds(value);
                                            }
                                        } optionFilterProp="children"
                                            allowClear mode='multiple' placeholder="Filtrer par organisation" style={{ width: '100%' }}>
                                            {organisations.map((organisation) => (
                                                <Select.Option key={organisation.id} value={organisation.id}>{organisation.nom}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </Col> : null}
                        </Row>
                    }
                        dataSource={contacts} loading={loading} columns={columns} scroll={{ x: 1300, y: 500 }}
                        paginationData={paginationData} loadMore={loadMore}
                    />
                </Spin>
            </>) : <UnauthorizedMessage />}
        </Main>
    )
} 