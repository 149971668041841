import React from "react";
import { Form, Input, Card, Button, Select, Result, Divider } from "antd";
import DomaineService from "../../services/DomaineService";
import OrganisationService from "../../services/OrganisationService";
import DomaineCategorieService from "../../services/DomaineCategorieService";
import { ArrowRightOutlined } from "@ant-design/icons";
import { catchError } from "../../services/DaoService";
import Toast from "../../helpers/Toast";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";
const { TextArea } = Input;

export default function BecomePartnerForm() {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [domaines, setDomaines] = React.useState([]);
    const [domaineCategories, setDomaineCategories] = React.useState([]);
    const [submissionSuccess, setSubmissionSuccess] = React.useState(false);

    React.useEffect(() => {
        DomaineService.getInstance()
            .all()
            .then(response => {
                setDomaines(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const onFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleDomaineChange = async (domaineId) => {
        const domaineSelected = domaines.find(item => item.id === domaineId);
        const domainCategorieExploitants = await DomaineCategorieService.getInstance().findByDomaine(domaineSelected.uid);
        setDomaineCategories(domainCategorieExploitants.data);
    };

    const save = (value) => {
        setLoading(true);
        const newExploitant = {
            nom_organisation: value.nom_exploitant,
            prenoms: value.prenom_contact,
            nom: value.nom_contact,
            email: value.email_contact,
            telephone: value.telephone_contact,
            domaine_id: value.domaine_id,
            domaine_categorie_id: value.domaine_categorie_id,
            description: value.description,
        };

        OrganisationService.getInstance()
            .createPublicExploitant(newExploitant)
            .then((response) => {
                setSubmissionSuccess(true);
                Toast.success(response.message);
            })
            .catch(error => {
                catchError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {submissionSuccess ? (
                <Card>
                    <Result
                        status="success"
                        title="Vos informations ont été enregistrées avec succès!"
                        subTitle="Un compte a été créé pour vous afin de soumettre vos documents. Un e-mail vous a été envoyé contenant un lien pour définir votre mot de passe. Veuillez cliquer sur ce lien pour choisir un mot de passe et accéder à votre compte."
                        extra={[
                            <Button type="primary" key="checkEmail" href="/login">
                                Aller à la page de connexion
                            </Button>
                        ]}
                    />
                </Card>

            ) : (
                <Card bordered={false} className="card" style={{ maxWidth: '600px', marginBottom: '15px', padding: '30px' }}>
                    <Form
                        onFinish={save}
                        form={form}
                        name="newExploitantForm"
                        layout="vertical"
                        onFinishFailed={onFailed}
                        initialValues={{
                            enabled: true,
                        }}
                    >
                        <Divider orientation="center">Informations de l'exploitant</Divider>
                        <Form.Item
                            label="Nom de l'exploitant"
                            name="nom_exploitant"
                            rules={[
                                { required: true, message: 'Ce champ est obligatoire!' },
                                { min: 3, message: 'Le nom doit contenir au moins 3 caractères!' }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Domaine"
                            name="domaine_id"
                            rules={[
                                { required: true, message: 'Ce champ est obligatoire!' }
                            ]}
                        >
                            <Select placeholder="Sélectionner un domaine" onChange={handleDomaineChange}>
                                {domaines?.map(domaine => (
                                    <Select.Option key={domaine.id} value={domaine.id}>
                                        {domaine.nom}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Catégorie exploitant"
                            name="domaine_categorie_id"
                            rules={[
                                { required: true, message: 'Ce champ est obligatoire!' }
                            ]}
                        >
                            <Select disabled={!domaineCategories.length} placeholder="Sélectionner une catégorie exploitant">
                                {domaineCategories?.map(domaineCategorie => (
                                    <Select.Option key={domaineCategorie.id} value={domaineCategorie.id}>
                                        {domaineCategorie.categorie_exploitant.nom}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Présentation de l'exploitant"
                            name="description"
                        >
                            <TextArea rows={4} />
                        </Form.Item>

                        <Divider orientation="center">Informations du contact</Divider>

                        <Form.Item>
                            <div style={{
                                fontSize: '14px',
                                color: '#333',
                                lineHeight: '1.6',
                                marginTop: '15px',
                                padding: '10px',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '5px',
                                fontWeight: 'bold',
                                border: '1px solid #ccc'
                            }}>
                                Les informations du contact seront utilisées pour vous créer un compte utilisateur afin de soumettre vos documents. <br />
                                Elles permettront également à l'ANACIM de vous contacter pour toute information complémentaire.
                            </div>
                        </Form.Item>


                        <Form.Item
                            label="Prenom du contact"
                            name="prenom_contact"
                            rules={[
                                { required: true, message: 'Ce champ est obligatoire!' },
                                { min: 3, message: 'Le prenom du contact doit contenir au moins 3 caractères!' }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Nom du contact"
                            name="nom_contact"
                            rules={[
                                { required: true, message: 'Ce champ est obligatoire!' },
                                { min: 3, message: 'Le nom du contact doit contenir au moins 3 caractères!' }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email du contact"
                            name="email_contact"
                            rules={[
                                { required: true, message: 'Veuillez saisir l\'adresse email!' },
                                { type: 'email', message: 'Veuillez saisir une adresse email valide!' }
                            ]}
                        >
                            <Input type="email" />
                        </Form.Item>

                        <Form.Item
                            label="Téléphone du contact"
                            name="telephone_contact"
                            rules={[
                                { required: true, message: 'Veuillez saisir le numéro de téléphone!' },
                            ]}>
                            <PhoneInput defaultCountry="SN"
                                placeholder="Saisir le numéro de téléphone" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" icon={<ArrowRightOutlined />}
                                iconPosition="end" loading={loading}>Continuer</Button>
                        </Form.Item>
                    </Form>
                </Card>)}
        </>
    )
}
