import React from "react";
import ChecklistModeleGroupItemService from "../../services/ChecklistModeleGroupItemService";
import { catchError } from "../../services/DaoService";
import { Button, Card, Divider, Empty, Form, Input, List, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Toast from "../../helpers/Toast";
import { InlineEditableInput } from '../utils/InlineEditableInput'; // Import du composant

export default function ChecklistModeleGroupItemList({ groupe }) {
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [form] = Form.useForm();

    const loadData = React.useCallback(() => {
        if (!groupe) return;
        setLoading(true);
        ChecklistModeleGroupItemService.getInstance().findByChecklistModeleGroupe(groupe.uid)
            .then(response => {
                setItems(response.data);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }, [groupe]);

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const handleAddGroupItem = (values) => {
        setLoading(true);
        values.checklist_modele_group_id = groupe.id;
        ChecklistModeleGroupItemService.getInstance().create(values)
            .then((response) => {
                form.resetFields();
                setItems(items => [...items, response.data]);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    const onDelete = (uid) => {
        setLoading(true);
        ChecklistModeleGroupItemService.getInstance().remove(uid)
            .then((response) => {
                setItems(items.filter(item => item.uid !== uid));
                Toast.success(response.message);
            }).catch(error => {
                catchError(error);
            }).finally(() => {
                setLoading(false);
            });
    }

    const handleFieldChange = (value, fieldName, record) => {
        const updatedItem = { ...record, [fieldName]: value };
        ChecklistModeleGroupItemService.getInstance().update(updatedItem.uid, updatedItem)
            .then((response) => {
                setItems(items => items.map(item => item.uid === updatedItem.uid ? response.data : item));
                Toast.success(response.message);
            }).catch(error => {
                catchError(error);
            });
    }

    return (
        <>
            <Card classNames={{ body: "p-2" }}>
                <Card.Meta title={"Liste des points d'évaluation"} />
                <Divider />
                {items.length ? <List
                    dataSource={items}
                    renderItem={item => (
                        <List.Item
                            key={item.id}
                            actions={[
                                <Popconfirm key={"delete" + item.id} okType="danger" okText="Supprimer" title="Voulez-vous vraiment supprimer cet item?" onConfirm={() => onDelete(item.uid)}>
                                    <Button
                                        icon={<DeleteOutlined />}
                                        type="link"
                                        danger
                                    >
                                    </Button>
                                </Popconfirm>
                            ]}
                        >
                            <InlineEditableInput 
                                canEdit={true} 
                                value={item.code} 
                                record={item} 
                                onChange={handleFieldChange} 
                                fieldName="code"
                            >
                                {item.code}
                            </InlineEditableInput>
                            &nbsp;
                            <InlineEditableInput 
                                canEdit={true}  type="textarea"
                                value={item.nom} 
                                record={item} 
                                onChange={handleFieldChange} 
                                fieldName="nom"
                            >
                                {item.nom}
                            </InlineEditableInput>
                        </List.Item>
                    )}
                /> : <Empty />}
                <Divider />
                <Card.Meta title={"Formulaire d'ajout d'item"} />
                <hr />
                <Form form={form} onFinish={handleAddGroupItem}>
                    <Form.Item name="code" label="Code" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="nom" label="Nom" rules={[{ required: true }]}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>Ajouter</Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
}